//@ts-check
import React from 'react'

import { Select, SelectOption, SelectVariant } from '@patternfly/react-core'
import EditableField from './common'
import { useDropdownState } from '../utils/dropdown'

const EditableSelectValue = ({ value, onSave, nextValue, setNextValue, isPending }) => {
    const [isOpen, onToggle, onSelectDropdown] = useDropdownState()

    const onSelect = (event, selection, isPlaceholder) => {
        if (isPlaceholder) {
            clearSelection()
        } else {
            setNextValue(selection)
            onSelectDropdown()
        }
    }

    const clearSelection = () => {
        onSelectDropdown()
    }

    return (
        <EditableField
            value={value}
            isPending={isPending}
            isValueChanged={value != nextValue}
            onSave={onSave}>
            <Select
                variant={SelectVariant.single}
                onToggle={onToggle}
                onSelect={onSelect}
                selections={nextValue}
                isOpen={isOpen}>
                <SelectOption key={1} value="Production" />
                <SelectOption key={2} value="Test" />
            </Select>
        </EditableField>
    )
}

export default EditableSelectValue
