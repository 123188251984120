//@ts-check
import React from 'react'
import { FormattedMessage } from 'react-intl'

import { PageSectionVariants, PageSection, TextContent, Text, Skeleton } from '@patternfly/react-core'

import { useRoute, useRouteEntity } from '../PageBreadcrumb'

const PageSectionHeader = () => {
    const [route, pathParts, match] = useRoute()

    if (!route) {
        console.warn('[PageSectionHeader] Route not found')
        return null
    }

    const messageId = (pathParts.length == 1 && pathParts[0] === '')
        ? 'index'
        : (pathParts.length == 1)
            ? pathParts[0]
            : pathParts.join('.')

    return (
        <PageSection variant={PageSectionVariants.light}>
            <TextContent>
                <Text component="h1">
                    {(route.apiMethod != null)
                        ? <FormattedMessageEntity
                            route={route}
                            uid={match.params.uid}
                            messageId={`page.${messageId}.title`} />
                        : <FormattedMessage id={`page.${messageId}.title`} />}
                </Text>
                {(route.apiMethod != null)
                    ? <FormattedMessageEntity
                        route={route}
                        uid={match.params.uid}
                        messageId={`page.${messageId}.description`} />
                    : <Text component="p"><FormattedMessage id={`page.${messageId}.description`} /></Text>}
            </TextContent>
        </PageSection>
    )
}

export default PageSectionHeader

const FormattedMessageEntity = ({ route, uid, messageId }) => {
    const [entity, isLoading] = useRouteEntity(route, uid)

    return isLoading
        ? <Skeleton />
        : <Text component="p"><FormattedMessage id={messageId} values={entity} /></Text>
}