//@ts-check
import React, { useState } from 'react'

import { Form, TextInput } from '@patternfly/react-core'

import ModalServiceRequest from './modalServiceRequest'
import FormFields from '../../Form/fields'

import useRestApiClient from './apiClient'
import useEntityState from '../../Form/state'

const useModalServiceRequestPOST = ({ endpoint, apiOperation }) => {
    const [state, setState] = useState({ isOpen: false })
    const onClose = () => setState({ isOpen: false })

    const [serviceRequest, { isLoading: isPending }] = useRestApiClient(endpoint, apiOperation)

    const paramsFields = apiOperation.pathParams.map(({ name, isRequired }) => ({
        name: name,
        component: TextInput,
        type: 'text',
        isRequired: isRequired
    }))
    const bodyFields = apiOperation.requestBody.schema.properties.map(({ name, isRequired }) => ({
        name: name,
        component: TextInput,
        type: 'text',
        isRequired: isRequired
    }))

    const { entity: paramsEntity, onChange: onChangeParamsEntity } = useEntityState(paramsFields)
    const { entity: bodyEntity, onChange: onChangeBodyEntity } = useEntityState(bodyFields)

    const allRequiredPathParamsSet = apiOperation.pathParams
        .filter(({ isRequired }) => isRequired)
        .map(({ name }) => (name in paramsEntity)
            && (paramsEntity[name] != null)
            && (paramsEntity[name] != ''))
        .filter(isSet => !isSet)
        .length == 0

    const allRequiredBodyFieldsSet = apiOperation.requestBody.schema.properties
        .filter(({ isRequired }) => isRequired)
        .map(({ name }) => (name in bodyEntity)
            && (bodyEntity[name] != null)
            && (bodyEntity[name] != ''))
        .filter(isSet => !isSet)
        .length == 0

    const isReadyToSubmit = !isPending && allRequiredPathParamsSet && allRequiredBodyFieldsSet

    const handleConfirm = () => {
        serviceRequest({ bodyEntity, paramsEntity }).then(() => {
            onClose()
        })
    }

    const modal = (
        <ModalServiceRequest
            isOpen={state.isOpen}
            apiOperation={apiOperation}
            isServiceRequestPending={isPending}
            isReadyToSubmit={isReadyToSubmit}
            onConfirm={handleConfirm}
            onClose={onClose}>
            <Form>
                <FormFields
                    messageId={apiOperation.operationId}
                    fields={paramsFields}
                    entity={bodyEntity}
                    onChange={onChangeParamsEntity}
                    isDisabled={isPending} />
                <FormFields
                    messageId={apiOperation.operationId}
                    fields={bodyFields}
                    entity={paramsEntity}
                    onChange={onChangeBodyEntity}
                    isDisabled={isPending} />
            </Form>
        </ModalServiceRequest>
    )

    return {
        modal: modal,
        isOpen: state.isOpen,
        openModal: () => setState({ isOpen: true })
    }
}

export default useModalServiceRequestPOST
