//@ts-check
import { useState } from 'react'

const useEntityState = (fields) => {
    const [entity, setEntity] = useState(fields.reduce((accu, { name }) => {
        accu[name] = ''
        return accu
    }, {}))

    const onChange = (value, e) => {
        setEntity({
            ...entity,
            [e.target.name]: value
        })
    }

    return { entity, onChange }
}

export default useEntityState
