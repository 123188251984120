//@ts-check
import React from 'react'
import { Link } from 'react-router-dom'
import { Button, Card, CardActions, CardBody, CardFooter, CardHeader, CardHeaderMain, CardTitle, Dropdown, EmptyState, Gallery, GalleryItem, KebabToggle, PageSection, Skeleton, Split, SplitItem, Stack, StackItem } from '@patternfly/react-core'

import EmptyStateLoading from '../EmptyState/loading'
import EmptyStateForbidden from '../EmptyState/forbidden'

import CircleIcon from '@patternfly/react-icons/dist/js/icons/circle-icon'
import ExternalLinkIcon from '@patternfly/react-icons/dist/js/icons/external-link-alt-icon'

import { useReadAllQuery } from '../Api/services'
import { useReadByUidQuery } from '../Api/cloudAppStage'

const Dashboard = () => {
    const { data: services, isLoading, isError, error } = useReadAllQuery()
    return (
        <PageSection isFilled>
            {isLoading
                ? <EmptyStateLoading />
                : (isError && error.originalStatus == 403)
                    ? <EmptyStateForbidden />
                    : <CloudAppGallery services={services} />}
        </PageSection>
    )
}

export default Dashboard

const CloudAppGallery = ({ services }) => (
    <Gallery
        hasGutter
        minWidths={{
            md: '275px',
            lg: '275px',
            xl: '300px',
            '2xl': '350px'
        }}>
        {services.map((service, i) => (
            <GalleryItem key={`${service.cloudApp.substring(0, 8)}-${service.stage.substring(0, 8)}`}>
                <CloudAppCard service={service} />
            </GalleryItem>))}
    </Gallery>
)
// Dropdown-Actions
// Hide card
// Mark favorite
// Install desktop app
// Edit
// Seperator
// Delete
const CloudAppCard = ({ service: { cloudApp, identifier, description, icon, links, stage } }) => (
    <Card isLarge isFullHeight isRounded={true}>
        <CardHeader>
            <CardHeaderMain>
                {(icon && icon != '')
                    ? <Split hasGutter>
                        <SplitItem>
                            <i className={icon}></i>
                        </SplitItem>
                        <SplitItem>
                            <Link to={`/cloud-app/${cloudApp}/update`}>
                                {identifier}
                            </Link>
                        </SplitItem>
                    </Split>
                    : identifier}
            </CardHeaderMain>
            <CardActions>
                <Dropdown
                    onSelect={() => { }}
                    toggle={<KebabToggle onToggle={() => { }} />}
                    isOpen={false}
                    isPlain
                    dropdownItems={[]}
                    position={'right'} />
            </CardActions>
        </CardHeader>
        <CardTitle>
            {description}
        </CardTitle>
        <CardBody isFilled>
            <Stack hasGutter>
                {Object.keys(links).map((link, index) => (
                    <StackItem key={index}>
                        <Button
                            isBlock
                            icon={<ExternalLinkIcon />}
                            iconPosition="right"
                            component="a"
                            href={links[link]}
                            target="_blank">
                            Go to {link}
                        </Button>
                    </StackItem>))}
            </Stack>
        </CardBody>
        <CardFooter>
            <Stack hasGutter>
                <StackItem>
                    <Split hasGutter>
                        <SplitItem>
                            <CircleIcon color='green' />
                        </SplitItem>
                        <SplitItem>
                            Online
                        </SplitItem>
                    </Split>
                </StackItem>
                <StackItem>
                    <CloudAppStage uid={stage} />
                </StackItem>
            </Stack>
        </CardFooter>
    </Card>
)

const CloudAppStage = ({ uid }) => {
    const { data: cloudAppStage, isLoading } = useReadByUidQuery(uid)

    return isLoading
        ? <Skeleton />
        : <small>{cloudAppStage.name}</small>
}