//@ts-check
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { NotificationDrawer, NotificationDrawerHeader, NotificationDrawerBody } from '@patternfly/react-core'

import { selectNotificationsUnread } from '../state/notification'
import { closeNotificationDrawer, selectIsNotificationDrawerOpen } from '../state/ui'

import DropdownHeaderMenu from './dropdownHeaderMenu'
import NotificationList from './notificationList'
import EmptyStateNoNotifications from './emptyStateNoNotifications'

const AppNotificationDrawer = ({ drawerRef }) => {
    const dispatch = useDispatch()
    const isNotificationDrawerOpen = useSelector(selectIsNotificationDrawerOpen)
    const notificationsUnread = useSelector(selectNotificationsUnread)

    const onCloseNotificationDrawer = () => dispatch(closeNotificationDrawer())

    return (
        <NotificationDrawer ref={drawerRef}>
            <NotificationDrawerHeader
                count={notificationsUnread.length}
                onClose={onCloseNotificationDrawer}>
                <DropdownHeaderMenu />
            </NotificationDrawerHeader>
            <NotificationDrawerBody style={{ height: '100%' }}>
                {isNotificationDrawerOpen
                    ? <NotificationList />
                    : <EmptyStateNoNotifications />}
            </NotificationDrawerBody>
        </NotificationDrawer>
    )
}

export default AppNotificationDrawer
