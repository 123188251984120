//@ts-check
import { createApi } from '@reduxjs/toolkit/query/react'
import { dynamicBaseQuery } from "./common"

export const cloudAppApi = createApi({
    reducerPath: 'cloudAppApi',
    baseQuery: dynamicBaseQuery('cloudAppApi'),
    endpoints: (builder) => ({
        create: builder.mutation({
            query: ({ cloudApp }) => ({
                url: `/cloud-app`,
                method: 'POST',
                body: cloudApp
            }),
            invalidatesTags: [{ type: 'CloudApp', id: 'LIST' }]
        }),
        putComposeFile: builder.mutation({
            query: ({ uid, value }) => ({
                url: `/cloud-app/${uid}/compose-file`,
                method: 'POST',
                body: { value: value }
            })
        }),
        build: builder.mutation({
            query: ({ cloudAppUid, branch }) => ({
                url: `/cloud-app/${cloudAppUid}/build/${branch}`,
                method: 'POST'
            })
        }),
        deploy: builder.mutation({
            query: ({ cloudAppUid, environment }) => ({
                url: `/cloud-app/${cloudAppUid}/deploy/${environment}`,
                method: 'POST'
            })
        }),
        readAll: builder.query({
            query: () => ({
                url: `/cloud-app`
            }),
            providesTags: (result) => result
                ? [...result.map(({ uid }) => ({ type: 'CloudApp', id: uid })), { type: 'CloudApp', id: 'LIST' },]
                : [{ type: 'CloudApp', id: 'LIST' }],
        }),
        readByUid: builder.query({
            query: (uid) => ({
                url: `/cloud-app/${uid}`
            }),
            providesTags: (result, error, id) => [{ type: 'CloudApp', id }],
        }),
        readBuildStatus: builder.query({
            query: ({ cloudAppUid, branch }) => ({
                url: `/cloud-app/${cloudAppUid}/build/${branch}`
            }),
        }),
        updateByUid: builder.mutation({
            query: ({ uid, fieldName, value }) => ({
                url: `/cloud-app/${uid}/${fieldName}`,
                method: 'PUT',
                body: { value: value }
            }),
            async onQueryStarted({ uid, ...patch }, { dispatch, queryFulfilled }) {
                try {
                    const { data: cloudApp } = await queryFulfilled
                    dispatch(cloudAppApi.util.updateQueryData('readByUid', uid, (draft) => {
                        return cloudApp
                    }))
                } catch { }
            },
        }),
        setEnvironmentStage: builder.mutation({
            query: ({ cloudAppUid, environment, stageUid }) => ({
                url: `/cloud-app/${cloudAppUid}/app_stages/${environment}/${stageUid}`,
                method: 'POST'
            }),
            invalidatesTags: (result, error, arg) => [{ type: 'CloudApp', id: arg.cloudAppUid }]
        }),
        assignEnvironmentStageNode: builder.mutation({
            query: ({ cloudAppUid, environment, nodeUid }) => ({
                url: `/cloud-app/${cloudAppUid}/app_stages_nodes/${environment}/${nodeUid}`,
                method: 'PUT'
            }),
            invalidatesTags: (result, error, arg) => [{ type: 'CloudApp', id: arg.cloudAppUid }]
        }),
        removeEnvironmentStageNode: builder.mutation({
            query: ({ cloudAppUid, environment, nodeUid }) => ({
                url: `/cloud-app/${cloudAppUid}/app_stages_nodes/${environment}/${nodeUid}`,
                method: 'DELETE'
            }),
            invalidatesTags: (result, error, arg) => [{ type: 'CloudApp', id: arg.cloudAppUid }]
        }),
        deleteByUid: builder.mutation({
            query: (uid) => ({
                url: `/cloud-app/${uid}`,
                method: 'DELETE'
            }),
            invalidatesTags: (result, error, { uid }) => [{ type: 'CloudApp', uid }],
        }),
    })
})

export const {
    useCreateMutation,
    usePutComposeFileMutation,
    useBuildMutation,
    useDeployMutation,
    useReadAllQuery,
    useLazyReadAllQuery,
    useReadByUidQuery,
    useReadBuildStatusQuery,
    useUpdateByUidMutation,
    useSetEnvironmentStageMutation,
    useAssignEnvironmentStageNodeMutation,
    useRemoveEnvironmentStageNodeMutation,
    useDeleteByUidMutation,
    endpoints
} = cloudAppApi
