//@ts-check
import React, { useEffect, useState } from 'react'

import ModalServiceRequest from './modalServiceRequest'
import EmptyStateLoading from '../../EmptyState/loading'
import StringList from './stringList'

import useRestApiClient from './apiClient'

const useModalServiceRequestGET = (props) => {
    const [state, setState] = useState({ isOpen: false })
    const onClose = () => setState({ isOpen: false })

    return {
        modal: state.isOpen &&
            <ModalComponent
                onClose={onClose}
                {...props} />,
        isOpen: state.isOpen,
        openModal: () => setState({ isOpen: true })
    }
}

export default useModalServiceRequestGET

const ModalComponent = ({ endpoint, apiOperation, onClose }) => {
    const [serviceRequest, { data, isLoading }] = useRestApiClient(endpoint, apiOperation)

    useEffect(() => {
        serviceRequest()
    }, [])

    return (
        <ModalServiceRequest
            isOpen={true}
            apiOperation={apiOperation}
            isServiceRequestPending={undefined}
            isReadyToSubmit={undefined}
            onConfirm={undefined}
            onClose={onClose}>
            {isLoading
                ? <EmptyStateLoading />
                : <StringList value={data} />}
        </ModalServiceRequest>
    )
}
