//@ts-check
import React from 'react'
import { FormattedMessage } from 'react-intl'

import { Skeleton } from '@patternfly/react-core'

import EntityEditView from '../../Views/entityUpdate'
import EditableTextValue from '../../FormEditableField/input'
import EditableSelectValue from '../../FormEditableField/select'
import FieldNodes from './fieldNodes'

import useModalDeleteCloudAppStage from '../modalDeleteCloudAppStage'

import { useReadByUidQuery as useReadCloudAppStageByUidQuery, useUpdateByUidMutation } from '../../Api/cloudAppStage'
import { useReadMetaByUidQuery as useReadConnectorMetaByUidQuery } from '../../Api/connector'

import PlusIcon from '@patternfly/react-icons/dist/esm/icons/plus-icon'
import TrashIcon from '@patternfly/react-icons/dist/esm/icons/trash-icon'

const ConnectorIdentifier = ({ value }) => {
    const { data: meta, isLoading } = useReadConnectorMetaByUidQuery(value)

    return (isLoading || meta == null)
        ? <Skeleton />
        : <span>{meta.name}</span>
}

const CloudAppStageEdit = () => {
    const { modal: ModalDeleteCloudAppStage, openModal: onOpenModalDeleteCloudAppStage } = useModalDeleteCloudAppStage()

    const toolbarItems = (cloudAppStage) => [{
        title: <FormattedMessage id="toolbar.cloudAppStage.btnCreate" />,
        icon: <PlusIcon />,
        variant: 'secondary',
        to: '/cloud-app-stage/create'
    }, {
        isSeparator: true
    }, {
        title: <FormattedMessage id="toolbar.cloudAppStage.btnAddNode" />,
        icon: <PlusIcon />,
        onClick: () => { },
        isDisabled: true,
    }, {
        title: <FormattedMessage id="toolbar.cloudAppStage.btnDelete" />,
        icon: <TrashIcon />,
        variant: 'secondary',
        isDanger: true,
        onClick: () => onOpenModalDeleteCloudAppStage(cloudAppStage)
    }]

    const entityFields = (cloudAppStage) => [
        { fieldName: 'name', component: EditableTextValue },
        { fieldName: 'description', component: EditableTextValue },
        { fieldName: 'environment', component: EditableSelectValue },
        { fieldName: 'urn', component: EditableTextValue },
        { fieldName: 'provisioning_connector', component: ConnectorIdentifier },
        { fieldName: 'nodes', component: FieldNodes, cloudProviderUid: cloudAppStage.cloud_provider }
    ]

    return (
        <React.Fragment>
            <EntityEditView
                route="/cloud-app-stage/:uid/update"
                messageId="cloudAppStage"
                toolbarItems={toolbarItems}
                entityFields={entityFields}
                readEntityQuery={useReadCloudAppStageByUidQuery}
                updateByUidMutation={useUpdateByUidMutation} />
            {ModalDeleteCloudAppStage}
        </React.Fragment>
    )
}

export default CloudAppStageEdit
