//@ts-check
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormattedList, FormattedMessage, FormattedRelativeTime, useIntl } from 'react-intl'

import { Alert, AlertActionCloseButton, AlertGroup, Stack, StackItem } from '@patternfly/react-core'

import { dismissToast, selectToasts } from '../state/notification'

const Toasts = () => {
    const toasts = useSelector(selectToasts)
    return (
        <AlertGroup isToast isLiveRegion>
            {toasts.map((toast) => (
                <Toast
                    key={toast.uid}
                    toast={toast} />
            ))}
        </AlertGroup>
    )
}

export default Toasts

const Toast = ({ toast }) => {
    const timeout = 8000
    const dispatch = useDispatch()
    const onDismiss = () => dispatch(dismissToast(toast.uid, true))
    const onTimeout = () => dispatch(dismissToast(toast.uid))

    return (
        <Alert
            variant={toast.variant}
            title={<FormattedMessage
                id={`notification.${toast.messageId}.title`}
                values={toast.entity} />}
            actionClose={<AlertActionCloseButton onClose={onDismiss} />}
            onTimeout={onTimeout}
            timeout={timeout}>
            <Stack hasGutter>
                <StackItem>
                    <NotificationMessage notification={toast} />
                </StackItem>
                <StackItem>
                    closes <FormattedRelativeTime
                        value={timeout / 1000}
                        numeric="auto"
                        updateIntervalInSeconds={1} />
                </StackItem>
            </Stack>
        </Alert>
    )
}

export const NotificationMessage = ({ notification }) => {
    const { messages } = useIntl()
    const messageId = `notification.${notification.messageId}.message`

    const message = (messageId in messages)
        ? <FormattedMessage
            id={messageId}
            values={notification.entity} />
        : null

    return (
        <React.Fragment>
            {message}
            {notification.allowedRoles &&
                <p>You require any of these roles <FormattedList
                    value={notification.allowedRoles.map(x => <u>{x}</u>)}
                    type="disjunction" />.</p>}
        </React.Fragment>
    )
}