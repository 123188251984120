//@ts-check
import React from 'react'
import { Link, matchPath, useLocation } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

import { Nav, NavList, NavItem } from '@patternfly/react-core'

import { useHasRole } from '../Security/requiresRole'

const TertiaryNav = ({ navItems }) => {
    const location = useLocation()
    const hasRole = useHasRole()

    const allowedNavItems = navItems.filter(({ requiresRole }) => hasRole(requiresRole))

    if (allowedNavItems.length == 0) {
        return null
    }

    return (
        <Nav variant="tertiary">
            <NavList>
                {allowedNavItems.map(({ messageId, isActive, to }) => (
                    <NavItem
                        key={messageId}
                        itemId={messageId}
                        component="div"
                        isActive={isActive.some(route => matchPath(route, location.pathname) != null)}>
                        <Link to={to}>
                            <FormattedMessage id={`tertiaryNav.${messageId}.label`} />
                        </Link>
                    </NavItem>))}
            </NavList>
        </Nav>
    )
}

export default TertiaryNav
