//@ts-check
import React from 'react'
import { FormattedNumber } from 'react-intl'

import { Card, CardActions, CardBody, CardFooter, CardHeader, CardTitle, Checkbox, Form, Gallery, Radio, Split, SplitItem } from "@patternfly/react-core"

import EmptyStateLoading from '../EmptyState/loading'
import FormFields from '../Form/fields'
import ServerInfo from './Create/serverInfo'
import LabelLocation from './labelLocation'

import { useReadAllLocationsQuery, useReadAllOsImagesQuery, useReadAllServersQuery } from '../Api/cloudProvider'

import EuroSignIcon from '@patternfly/react-icons/dist/js/icons/euro-sign-icon'

const PaneCloudServer = ({ cloudProviderUid, cloudAppStageNode, onChange }) => {
    const { data: locations, isLoading: isLoadingLocations } = useReadAllLocationsQuery(cloudProviderUid)
    const { data: servers, isLoading: isLoadingServers } = useReadAllServersQuery(cloudProviderUid)
    const { data: osImages, isLoading: isLoadingOsImages } = useReadAllOsImagesQuery(cloudProviderUid)

    const fields = [{
        name: 'location',
        component: RadioGroupLocation,
        isRequired: true,
        locations: locations
    }, {
        name: 'server',
        component: ServerSelection,
        isRequired: true,
        servers: servers
    }, {
        name: 'os_image',
        component: RadioGroupOsImage,
        isRequired: true,
        osImages: osImages
    }]

    return (isLoadingLocations || isLoadingServers || isLoadingOsImages)
        ? <EmptyStateLoading />
        : <Form>
            <FormFields
                fields={fields}
                messageId="cloudAppStageNode"
                entity={cloudAppStageNode}
                onChange={onChange} />
        </Form>

}

export default PaneCloudServer

const RadioGroupLocation = ({ value, locations, onChange }) => locations.map((location) => (
    <Radio
        key={location.uid}
        id={location.uid}
        name="location"
        label={<LabelLocation location={location} />}
        isChecked={location.uid == value}
        value={location.uid}
        onChange={(checked, e) => onChange(checked ? location.uid : null, e)} />))

const ServerSelection = ({ value, servers, onChange }) => (
    <Gallery hasGutter>
        {servers.map((server) => (
            <Card
                isSelectableRaised
                hasSelectableInput
                isSelected={value == server.uid}
                onClick={() => onChange((value == server.uid) ? null : server.uid, { target: { name: "server" } })}>
                <CardHeader>
                    <CardActions>
                        <Checkbox
                            isChecked={value == server.uid}
                            onChange={() => onChange((value == server.uid) ? null : server.uid, { target: { name: "server" } })}
                            id={`check-${server.uid}`}
                            name={`check-${server.uid}`} />
                    </CardActions>
                </CardHeader>
                <CardTitle>{server.name}</CardTitle>
                <CardBody>
                    <ServerInfo server={server} />
                </CardBody>
                <CardFooter>
                    <Split hasGutter>
                        <SplitItem><EuroSignIcon /></SplitItem>
                        <SplitItem><FormattedNumber value={server.price.price_monthly} style="currency" currency="EUR" /> / Monat <small>({server.price.price_hourly} / Stunde)</small></SplitItem>
                    </Split>
                </CardFooter>
            </Card>))}
    </Gallery>
)

const RadioGroupOsImage = ({ value, osImages, onChange }) => osImages.map((osImage) => (
    <Radio
        key={osImage.uid}
        id={osImage.uid}
        name="os_image"
        label={osImage.name}
        isChecked={osImage.uid == value}
        value={osImage.uid}
        onChange={(checked, e) => onChange(checked ? osImage.uid : null, e)} />))