//@ts-check
import React from 'react'
import { FormattedNumber } from 'react-intl'

import { Checkbox, Divider, Form, Stack, StackItem, Text, TextContent } from "@patternfly/react-core"

import EmptyStateLoading from '../../EmptyState/loading'
import ServerInfo from './serverInfo'
import StaticTextValue from '../../FormEditableField/static'
import FormFields from '../../Form/fields'
import LabelLocation from '../labelLocation'

import EuroSignIcon from '@patternfly/react-icons/dist/js/icons/euro-sign-icon'

import { useReadAllLocationsQuery, useReadAllOsImagesQuery, useReadAllQuery, useReadAllServersQuery } from '../../Api/cloudProvider'

const WizardStepReview = ({ cloudProviderUid, cloudAppStageNode, acceptTermsAndConditions, onToggleTermsAndConditions }) => {
    const { data: cloudProviders, isLoading: isLoadingCloudProviders } = useReadAllQuery()
    const { data: locations, isLoading: isLoadingLocations } = useReadAllLocationsQuery(cloudProviderUid)
    const { data: servers, isLoading: isLoadingServers } = useReadAllServersQuery(cloudProviderUid)
    const { data: osImages, isLoading: isLoadingOsImages } = useReadAllOsImagesQuery(cloudProviderUid)

    if (isLoadingCloudProviders || isLoadingLocations || isLoadingServers || isLoadingOsImages) {
        return <EmptyStateLoading />
    }

    const cloudProvider = cloudProviders.filter(x => x.uid)[0]
    const location = locations.filter(x => x.uid === cloudAppStageNode.location)[0]
    const server = servers.filter(x => x.uid === cloudAppStageNode.server)[0]
    const osImage = osImages.filter(x => x.uid === cloudAppStageNode.os_image)[0]

    const fieldsCloudProvider = [{
        name: 'cloudProviderUid',
        component: StaticTextValue,
        value: cloudProvider.name
    }]

    const fieldsCloudAppStageNode = [{
        name: 'location',
        component: StaticTextValue,
        value: <LabelLocation location={location} />
    }, {
        name: 'server',
        component: StaticTextValue,
        value: <ServerInfo
            server={server}
            additionalLabels={[{
                icon: <EuroSignIcon />,
                label: "Kosten",
                value: <React.Fragment>
                    <FormattedNumber value={server.price.price_monthly} style="currency" currency="EUR" /> / Monat <small>({server.price.price_hourly} / Stunde)</small>
                </React.Fragment>
            }]} />
    }, {
        name: 'os_image',
        component: StaticTextValue,
        value: osImage.name
    }]

    return (
        <Stack hasGutter>
            <StackItem>
                <TextContent>
                    <Text component='h2'>Final review</Text>
                    <Text component='p'>Please review your selection thoroughly.</Text>
                </TextContent>
            </StackItem>
            <StackItem>
                <Divider />
            </StackItem>
            <StackItem>
                <Form>
                    <FormFields
                        fields={fieldsCloudProvider}
                        messageId="cloudProvider"
                        entity={{ cloudProviderUid: cloudProviderUid }} />
                    <FormFields
                        fields={fieldsCloudAppStageNode}
                        messageId="cloudAppStageNode"
                        entity={cloudAppStageNode} />
                </Form>
            </StackItem>
            <StackItem>
                <Form>
                    <Checkbox
                        id="terms-and-conditions"
                        label="I accept the Terms and Conditions"
                        isChecked={acceptTermsAndConditions}
                        onChange={onToggleTermsAndConditions} />
                </Form>
            </StackItem>
        </Stack>
    )
}

export default WizardStepReview
