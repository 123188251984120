//@ts-check
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const configApi = createApi({
    reducerPath: 'configApi',
    baseQuery: fetchBaseQuery({
        baseUrl: '/',
    }),
    tagTypes: ['Config'],
    endpoints: (builder) => ({
        read: builder.query({
            query: () => ({
                url: `/config.json`
            }),
        })
    })
})

export const {
    useReadQuery,
    endpoints
} = configApi
