//@ts-check
import { createApi } from '@reduxjs/toolkit/query/react'
import { dynamicBaseQuery } from "./common"

export const cloudAppStageApi = createApi({
    reducerPath: 'cloudAppStageApi',
    baseQuery: dynamicBaseQuery('cloudAppStageApi'),
    endpoints: (builder) => ({
        create: builder.mutation({
            query: ({ name, description, urn, environment, cloudProviderUid }) => ({
                url: `/cloud-app-stage`,
                method: 'POST',
                body: { name, description, urn, environment, provisioning_connector: cloudProviderUid }
            }),
            invalidatesTags: [{ type: 'CloudAppStage', id: 'LIST' }]
        }),
        readAll: builder.query({
            query: () => ({
                url: `/cloud-app-stage`
            }),
            providesTags: (result) => result
                ? [...result.map(({ uid }) => ({ type: 'CloudAppStage', id: uid })), { type: 'CloudAppStage', id: 'LIST' },]
                : [{ type: 'CloudAppStage', id: 'LIST' }],
        }),
        readByUid: builder.query({
            query: (uid) => ({
                url: `/cloud-app-stage/${uid}`
            }),
            providesTags: (result, error, id) => [{ type: 'CloudAppStage', id }],
        }),
        updateByUid: builder.mutation({
            query: ({ uid, fieldName, value }) => ({
                url: `/cloud-app-stage/${uid}/${fieldName}`,
                method: 'PUT',
                body: { value: value }
            }),
            async onQueryStarted({ uid, ...patch }, { dispatch, queryFulfilled }) {
                try {
                    const { data: cloudAppStage } = await queryFulfilled
                    dispatch(cloudAppStageApi.util.updateQueryData('readByUid', uid, (draft) => {
                        return cloudAppStage
                    }))
                } catch { }
            },
        }),
        deleteByUid: builder.mutation({
            query: (uid) => ({
                url: `/cloud-app-stage/${uid}`,
                method: 'DELETE'
            }),
            invalidatesTags: (result, error, { uid }) => [{ type: 'CloudAppStage', uid }],
        }),
    })
})

export const {
    useCreateMutation,
    useReadAllQuery,
    useLazyReadAllQuery,
    useReadByUidQuery,
    useUpdateByUidMutation,
    useDeleteByUidMutation,
    endpoints
} = cloudAppStageApi
