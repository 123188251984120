//@ts-check
import React from 'react'
import { Link } from 'react-router-dom'

import { Button, Dropdown, DropdownItem, DropdownToggle, DropdownToggleCheckbox, Pagination, SearchInput, Select, SelectVariant, Skeleton, Toolbar, ToolbarContent, ToolbarFilter, ToolbarGroup, ToolbarItem, ToolbarToggleGroup } from '@patternfly/react-core';

import FilterIcon from '@patternfly/react-icons/dist/esm/icons/filter-icon'
import AddIcon from '@patternfly/react-icons/dist/esm/icons/add-circle-o-icon'
import EditIcon from '@patternfly/react-icons/dist/esm/icons/edit-icon'
import TrashIcon from '@patternfly/react-icons/dist/esm/icons/trash-icon'
import CheckIcon from '@patternfly/react-icons/dist/esm/icons/check-icon'

import { useDropdownState } from '../../utils/dropdown'
import { endpoints } from '../../Api/cloudUsers'

const ListToolbar = ({ isLoading, selectedItems}) => (
    <Toolbar id="inline-modifier-data-toolbar" usePageInsets>
        {isLoading
            ? <Skeleton fontSize="3xl" />
            : <ToolbarContent>
                <ToolbarGroup variant="button-group">
                    <ToolbarItem>
                        <Link to="/cloud-user/create">
                            <Button variant="primary" icon={<AddIcon />}>
                                New CloudUser
                            </Button>
                        </Link>
                    </ToolbarItem>
                </ToolbarGroup>
                {selectedItems.length > 0 && <ToolbarItem variant="separator" />}
                {selectedItems.length > 0 &&
                    <ToolbarGroup variant="icon-button-group">
                        {selectedItems.length == 1 && <ToolbarItem>
                            <Link to={`/cloud-user/${selectedItems[0]}/update`}>
                                <Button variant="plain" aria-label="edit">
                                    <EditIcon />
                                </Button>
                            </Link>
                        </ToolbarItem>}
                        <ToolbarItem>
                            <ButtonDeleteCloudApp cloudApp={selectedItems[0]} />
                        </ToolbarItem>
                    </ToolbarGroup>}
            </ToolbarContent>}
    </Toolbar>
)

export default ListToolbar

const ButtonDeleteCloudApp = ({ cloudApp }) => {
    // const { modal, openModal } = useModalDeleteCloudApp(cloudApp)
    const { modal, openModal } = { modal: null, openModal: null }

    return (
        <Button
            variant="plain"
            onClick={openModal}>
            <TrashIcon />
            {modal}
        </Button>
    )
}