//@ts-check
import React, { useState } from 'react'

import FieldGroupPane from '../../Form/fieldGroupPane'
import DescriptionListEntityFields from '../../DescriptionList/entity'
import EditableTextValue from '../../FormEditableField/input'
import { useUpdateByUidMutation } from '../../Api/cloudApp'

const entityFieldsDetails = [
    { fieldName: 'name', component: EditableTextValue },
    { fieldName: 'description', component: EditableTextValue },
    { fieldName: 'icon', component: EditableTextValue },
    { fieldName: 'urn', component: EditableTextValue }
]

const PaneDetails = ({ cloudApp }) => (
    <FieldGroupPane messageId="cloudApp.details">
        <DescriptionListEntityFields
            fields={entityFieldsDetails}
            messageId="cloudApp"
            entity={cloudApp}>
            {(props) => <FieldWrapper {...props} />}
        </DescriptionListEntityFields>
    </FieldGroupPane>
)

export default PaneDetails

const FieldWrapper = ({ component, uid, fieldName, value, ...args }) => {
    const [nextValue, setNextValue] = useState(value)
    const [updateField, { isLoading: isPending }] = useUpdateByUidMutation()

    const onSave = () => {
        return updateField({ uid, fieldName, value: nextValue })
    }

    return React.createElement(component, {
        uid,
        fieldName,
        value,
        nextValue,
        setNextValue,
        onSave,
        isPending,
        ...args
    })
}