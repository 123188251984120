//@ts-check
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

import { Accordion, AccordionContent, AccordionItem, AccordionToggle, Divider, Dropdown, DropdownItem, DropdownPosition, KebabToggle, PageSection, PageSectionVariants, Panel, PanelMain, PanelMainBody, SimpleList, SimpleListItem, Split, SplitItem, Text, TextContent } from '@patternfly/react-core'

import EmptyStateLoading from '../../../EmptyState/loading'
import EmptyStateNoData from '../../../EmptyState/noData'
import EmptyStateError from '../../../EmptyState/error'
import EmptyStateForbidden from '../../../EmptyState/forbidden'

import { endpoints, useReadAllQuery } from '../../../Api/connector'

import { useDropdownState } from '../../../utils/dropdown'
import { useHasRole } from '../../../Security/requiresRole'

import EditIcon from '@patternfly/react-icons/dist/esm/icons/edit-icon'

const ConnectorsList = () => {
    const { data: connectors, isLoading, isError, error } = useReadAllQuery()

    return (
        <PageSection
            variant={PageSectionVariants.light}
            isFilled>
            {isLoading
                ? <EmptyStateLoading />
                : isError
                    ? (error.originalStatus == 403)
                        ? <EmptyStateForbidden />
                        : <EmptyStateError />
                    : (connectors.length == 0)
                        ? <EmptyStateNoData createRoute="/settings/connector/create" />
                        : <Connectors />}
        </PageSection>
    )
}

export default ConnectorsList

const Connectors = ({ }) => {
    const { data: connectors } = endpoints.readAll.useQueryState()

    return (
        <Accordion displaySize="large">
            {Object.entries(connectors).map(([connectorType, connectors]) => (
                <ConnectorGroup
                    key={connectorType}
                    connectorType={connectorType}
                    connectors={connectors}
                />))}
        </Accordion>
    )
}

const connectorTypeIcons = {
    'source-code-repository': 'fas fa-code-branch',
    'build': 'fas fa-cogs',
    'docker-registry': 'fas fa-boxes',
    'deployment': 'fas fa-cloud-upload-alt',
    'provisioning': 'fas fa-server',
    'monitoring': 'fas fa-monitor-hear-rate',
    'identity': 'fas fa-users',
    'dns': 'fas fa-globe'
}

const ConnectorGroup = ({ connectorType, connectors }) => {
    const [isExpanded, setExpanded] = useState(false)

    const onToggleExpand = () => setExpanded(!isExpanded)

    return (
        <AccordionItem>
            <AccordionToggle
                id="ex-toggle1"
                onClick={onToggleExpand}
                isExpanded={isExpanded}>
                <Split hasGutter>
                    <SplitItem>
                        <i className={`${connectorTypeIcons[connectorType]} fa-2x`}></i>
                    </SplitItem>
                    <SplitItem>
                        <TextContent>
                            <Text component="h2">
                                <FormattedMessage id={`connectorType.${connectorType}`} />
                            </Text>
                        </TextContent>
                    </SplitItem>
                </Split>
            </AccordionToggle>
            <AccordionContent id="ex-expand1" isHidden={!isExpanded}>
                <Panel>
                    <PanelMain>
                        <PanelMainBody>
                            <SimpleList>
                                {connectors.map((connector, index) => (
                                    <React.Fragment key={connector.namespace}>
                                        <ConnectorItem connector={connector} />
                                        {index + 1 < connectors.length &&
                                            <React.Fragment>
                                                <br />
                                                <Divider />
                                                <br />
                                            </React.Fragment>}
                                    </React.Fragment>))}
                            </SimpleList>
                        </PanelMainBody>
                    </PanelMain>
                </Panel>
            </AccordionContent>
        </AccordionItem>
    )
}

const ConnectorItem = ({ connector }) => {
    const navigate = useNavigate()
    const [isOpen, onToggle, onSelect] = useDropdownState()

    const onClickEdit = (e) => {
        e.preventDefault()
        navigate(`/settings/connectors/${connector.uid}/update`)
    }

    const hasConfig = (connector.namespace != 'itbox365' && Object.keys(connector.config.options).length !== 0)

    const isConnectorUpdateAllowed = useHasRole('connector-update')
    const isEditable = hasConfig && isConnectorUpdateAllowed

    return (
        <SimpleListItem
            component={isEditable ? "a" : "div"}
            href={isEditable
                ? `/settings/connectors/${connector.uid}/update`
                : undefined}
            onClick={isEditable ? onClickEdit : undefined}>
            <Split hasGutter style={{ width: '100%' }}>
                <SplitItem>
                    <i className={`${connector.icon} fa-2x`} />
                </SplitItem>
                <SplitItem isFilled>
                    <TextContent>
                        <Text component="h3">
                            {connector.identifier}
                        </Text>
                        <Text component="p">{connector.description}</Text>
                    </TextContent>
                </SplitItem>
                <SplitItem>
                    {isEditable &&
                        <Dropdown
                            isPlain
                            position={DropdownPosition.right}
                            isOpen={isOpen}
                            onSelect={onSelect}
                            toggle={<KebabToggle onToggle={onToggle} />}
                            dropdownItems={[<DropdownItem
                                key="edit"
                                component="button"
                                icon={<EditIcon />}
                                onClick={onClickEdit}>
                                Edit
                            </DropdownItem>]} />}
                </SplitItem>
            </Split>
        </SimpleListItem>
    )
}
