//@ts-check
import React from 'react'

import { Button, EmptyState, EmptyStateVariant, EmptyStateIcon, Title, EmptyStateBody, EmptyStatePrimary } from '@patternfly/react-core'

import SearchIcon from '@patternfly/react-icons/dist/esm/icons/search-icon'

const EmptyStateNoNotifications = () => (
    <EmptyState variant={EmptyStateVariant.full}>
        <EmptyStateIcon icon={SearchIcon} />
        <Title headingLevel="h2" size="lg">
            No alerts found
        </Title>
        <EmptyStateBody>
            There are currently no alerts. There may be silenced critical alerts however.
        </EmptyStateBody>
        <EmptyStatePrimary>
            <Button variant="link">Action</Button>
        </EmptyStatePrimary>
    </EmptyState>
)

export default EmptyStateNoNotifications