//@ts-check
import React from 'react'

import { Form, TextInput } from "@patternfly/react-core"

import FormFields from '../../Form/fields'
import TitledPane from '../../Wizard/titledPane'

const fields = [{
    name: 'name',
    component: TextInput,
    type: 'text',
    isRequired: true
}, {
    name: 'description',
    component: TextInput,
    type: 'text'
}, {
    name: 'urn',
    component: TextInput,
    type: 'text',
    isRequired: true
}]

const WizardStepCloudApp = ({ cloudApp, onChange }) => (
    <TitledPane messageId="cloudAppCreate.stage">
        <Form>
            <FormFields
                fields={fields}
                messageId="cloudApp"
                entity={cloudApp}
                onChange={onChange} />
        </Form>
    </TitledPane>
)

export default WizardStepCloudApp