//@ts-check
import React from 'react'
import ReactDOM from 'react-dom'

import '@patternfly/react-core/dist/styles/base.css'

import App from './App'
import Settings from './Settings'

import ConnectorsList from './Settings/Connectors/List'
import ConnectorEdit from './Settings/Connectors/Edit'

import CloudUserCreate from './CloudUsers/Create'
import CloudUserList from './CloudUsers/List'
import CloudUserEdit from './CloudUsers/Edit'

import CloudAppCreate from './CloudApp/Create'
import CloudAppList from './CloudApp/List'
import CloudAppEdit from './CloudApp/Edit'

import WizardCloudAppStageCreate from './CloudAppStage/Create'
import CloudAppStageList from './CloudAppStage/List'
import CloudAppStageEdit from './CloudAppStage/Edit'

import Dashboard from './Dashboard'

import { cloudUsersApi } from './Api/cloudUsers'
import { cloudAppApi } from './Api/cloudApp'
import { cloudAppStageApi } from './Api/cloudAppStage'

import { CLOUDAPP_STAGE_READ, CLOUDAPP_STAGE_UPDATE, CLOUDUSER_READ } from './App/securityRoles'

const sidebarNav = [{

}]

const settingsTertiaryNav = [{
    to: '/settings',
    isActive: ['/settings'],
    messageId: 'settingsOverview',
    requiresRole: 'connector-read'
}, {
    to: '/settings/connectors',
    isActive: ['/settings/connectors', '/settings/connectors/:uid/update'],
    messageId: 'settingsConnectors',
    requiresRole: 'connector-read'
}]

export const routes = [{
    path: '/',
    element: <Dashboard />
}, {
    path: '/settings',
    element: <Settings />,
    tertiaryNav: settingsTertiaryNav
}, {
    path: '/settings/connectors',
    element: <ConnectorsList />,
    tertiaryNav: settingsTertiaryNav
}, {
    path: '/settings/connectors/:uid/update',
    element: <ConnectorEdit />,
    tertiaryNav: settingsTertiaryNav
}, {
    path: '/cloud-app',
    element: <CloudAppList />
}, {
    path: '/cloud-app/create',
    element: <CloudAppCreate />
}, {
    path: '/cloud-app/:uid/update',
    element: <CloudAppEdit />,
    apiMethod: cloudAppApi.endpoints.readByUid
}, {
    path: '/cloud-app-stage',
    element: <CloudAppStageList />,
    requiresRole: [CLOUDAPP_STAGE_READ]
}, {
    path: '/cloud-app-stage/create',
    element: <WizardCloudAppStageCreate />
}, {
    path: '/cloud-app-stage/:uid/update',
    element: <CloudAppStageEdit />,
    apiMethod: cloudAppStageApi.endpoints.readByUid
}, {
    path: '/cloud-user',
    element: <CloudUserList />,
    requiresRole: [CLOUDUSER_READ]
}, {
    path: '/cloud-user/create',
    element: <CloudUserCreate />,
}, {
    path: '/cloud-user/:uid/update',
    element: <CloudUserEdit />,
    apiMethod: cloudUsersApi.endpoints.readByUid
}]

ReactDOM.render(<App />, document.getElementById('root'))
