//@ts-check
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { Button, Form, Modal, ModalVariant, TextInput } from '@patternfly/react-core'

import FormFields from '../Form/fields'
import PaneCloudServer from './paneCloudServer'

const fields = [{
    name: 'name',
    component: TextInput,
    type: 'text',
    isRequired: true
}, {
    name: 'description',
    component: TextInput,
    type: 'text'
}, {
    name: 'urn',
    component: TextInput,
    type: 'text',
    isRequired: true
}]

const useModalAddCloudAppStageNode = (cloudProviderUid) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const onConfirm = () => {
    }

    const { formatMessage } = useIntl()
    const [isOpen, setOpen] = useState(false)
    const onClose = () => setOpen(false)

    const [cloudAppStageNode, setCloudAppStageNode] = useState({
        name: "Node-1",
        description: "",
        urn: "node-1",
        location: "",
        server: "",
        os_image: ""
    })
    const onChange = (text, e) => {
        setCloudAppStageNode({
            ...cloudAppStageNode,
            [e.target.name]: text
        })
    }

    const modal = <Modal
        isOpen={isOpen}
        variant={ModalVariant.large}
        title={formatMessage({ id: `modal.cloudAppStage.addCloudAppStageNode.title` })}
        showClose={true}
        actions={[
            <Button key="confirm"
                variant="primary"
                onClick={onConfirm}
                isLoading={false}
                isDisabled={false}>
                Confirm
            </Button>,
            <Button key="cancel" variant="link" onClick={onClose}>
                Cancel
            </Button>
        ]}
        onClose={onClose}>
        <Form>
            <FormFields
                fields={fields}
                messageId="cloudAppStageNode"
                entity={cloudAppStageNode}
                onChange={onChange} />
            <PaneCloudServer
                cloudProviderUid={cloudProviderUid}
                cloudAppStageNode={cloudAppStageNode}
                onChange={onChange} />
        </Form>
    </Modal>

    return {
        modal: modal,
        isOpen: isOpen,
        openModal: () => setOpen(true)
    }

}

export default useModalAddCloudAppStageNode
