//@ts-check
import { useRef, useState } from 'react'
import KeycloakClient from '../../App/keycloakClient'

/**
 * 
 * @param {*} endpoint 
 * @param {*} apiOperation 
 * @returns {Array}
 */
const useRestApiClient = (endpoint, apiOperation) => {
    const [state, setState] = useState({ data: null, isUninitialized: true, isLoading: false, isError: false, error: null })
    const stateRef = useRef()
    stateRef.current = state

    const endpointUrl = new URL(endpoint)

    const action = ({ paramsEntity = {}, bodyEntity = null } = {}) => {
        setState({ ...stateRef.current, isUninitialized: false, isLoading: true })

        const path = apiOperation.pathParams
            ? apiOperation.pathParams.reduce((path, { name }) => {
                return path.replace(`{${name}}`, paramsEntity[name])
            }, apiOperation.path)
            : apiOperation.path

        const resourceUrl = `${endpointUrl.protocol}//${endpointUrl.hostname}${path}`

        return KeycloakClient.keycloakClient.updateToken(30)
            .then(() => fetch(resourceUrl, {
                method: apiOperation.httpMethod,
                mode: 'cors',
                cache: 'no-cache',
                headers: {
                    'Authorization': `Bearer ${KeycloakClient.keycloakClient.token}`,
                    ...((bodyEntity != null)
                        ? { 'Content-Type': 'application/json' }
                        : {})
                },
                body: (bodyEntity != null)
                    ? JSON.stringify(bodyEntity)
                    : undefined
            }).then(response => {
                if (response.status == 204) {
                    setState({ ...stateRef.current, isLoading: false })
                    return Promise.resolve()
                }
                if (response.status in apiOperation.response) {
                    const contentType = apiOperation.response[response.status].contentType
                    if (contentType == 'application/octet-stream') {
                        return response.blob().then(file => {
                            var a = window.document.createElement("a")
                            a.href = window.URL.createObjectURL(file)
                            a.download = 'tempdb.key' // FIXME
                            a.click()
                            setState({ ...stateRef.current, isLoading: false })
                        })
                    } else if (contentType == 'application/json') {
                        return response.json().then(json => {
                            setState({ ...stateRef.current, data: json, isLoading: false })
                        })
                    }
                }
                console.warn('Unhandled response: %o', response)
            }))
    }

    return [action, state]
}

export default useRestApiClient
