//@ts-check
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import { TableComposable, Thead, Tr, Th, Tbody, Td, ActionsColumn } from '@patternfly/react-table'

const EntityListTable = ({
    messageId,
    entities,
    columns,
    entityActions = undefined,
    selectedEntities = [],
    setSelectedEntities = undefined }) => {
    const { formatMessage } = useIntl()
    
    return (
        <TableComposable>
            <Thead>
                <Tr>
                    {setSelectedEntities &&
                        <Th select={{
                            onSelect: (_event, isSelecting) => setSelectedEntities(isSelecting
                                ? entities.map(r => r.uid)
                                : []),
                            isSelected: selectedEntities.length == entities.length
                        }} />}
                    {Object.keys(columns).map((column) => (
                        <Th key={column}>
                            <FormattedMessage id={`field.${messageId}.${column}.label`} />
                        </Th>
                    ))}
                    {entityActions && <Th></Th>}
                </Tr>
            </Thead>
            <Tbody >
                {entities.map((entity, rowIndex) => (
                    <Tr key={entity.uid}>
                        {setSelectedEntities &&
                            <Th select={{
                                onSelect: (_event, isSelecting) => setSelectedEntities(isSelecting
                                    ? [...selectedEntities, entity.uid]
                                    : selectedEntities.filter(x => x !== entity.uid)),
                                isSelected: selectedEntities.includes(entity.uid)
                            }} />}
                        {Object.entries(columns).map(([column, { formatter }], cellIndex) => (
                            <Td
                                key={column}
                                dataLabel={formatMessage({ id: `field.${messageId}.${column}.label` })}>
                                {formatter(entity[column])}
                            </Td>))}
                        {entityActions &&
                            <Td isActionCell>
                                <ActionsColumn
                                    items={entityActions(entity)}
                                    rowData={{ actionProps: { menuAppendTo: () => document.body } }} />
                            </Td>}
                    </Tr>))}
            </Tbody>
        </TableComposable>
    )
}

export default EntityListTable
