//@ts-check
import { fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { configApi } from './config'

import KeycloakClient from '../App/keycloakClient'

export const dynamicBaseQuery = (endpointId) => (args, api, extraOptions) => {
    const endpoints = api.getState()[configApi.reducerPath].queries['read(undefined)'].data.endpoints
    const baseUrl = endpoints[endpointId]
        ? endpoints[endpointId]
        : endpoints.default
    if (!baseUrl) {
        return {
            error: {
                status: 400,
                statusText: 'Bad Request',
                data: 'No Endpoint host found',
            }
        }
    }
    return KeycloakClient.keycloakClient.updateToken(30)
        .then((refreshed) => fetchBaseQuery({
            baseUrl: baseUrl,
            prepareHeaders: (headers) => {
                headers.set('Authorization', `Bearer ${KeycloakClient.keycloakClient.token}`)
                return headers
            }
        })(args, api, extraOptions))
        .catch(() => ({
            error: {
                status: 403,
                statusText: 'No token',
                data: 'Update token failed',
            }
        }))
}
