//@ts-check
import React from 'react'
import { useIntl } from 'react-intl'

import { Form, Select, SelectOption, SelectVariant, TextInput } from "@patternfly/react-core"

import TitledPane from '../../Wizard/titledPane'
import FormFields from '../../Form/fields'

import { useDropdownState } from '../../utils/dropdown'

const SelectSingle = ({ messageId, name, value, onChange, options }) => {
    const { formatMessage } = useIntl()
    const [isOpen, onToggle, onSelectDropdown] = useDropdownState()

    const onSelect = (event, selection, isPlaceholder) => {
        if (isPlaceholder) {
            clearSelection()
        } else {
            console.log(selection)
            onChange(selection.value, { target: { name: name } })
            onSelectDropdown()
        }
    }

    const clearSelection = () => {
        onChange('', { target: { name: name } })
        onSelectDropdown()
    }

    const valueBuilder = (option) => ({
        value: option,
        toString: () => formatMessage({ id: `field.${messageId}.${name}.option.${option}` }),
        compareTo: (selectOption) => selectOption.value == option
    })

    return (
        <Select
            menuAppendTo={() => document.getElementById('main-content')}
            variant={SelectVariant.single}
            onToggle={onToggle}
            onSelect={onSelect}
            selections={valueBuilder(value)}
            isOpen={isOpen}>
            <SelectOption
                value={valueBuilder('')}
                isPlaceholder />
            {options.map((option, index) => (
                <SelectOption
                    key={index}
                    value={valueBuilder(option)} />
            ))}
        </Select>
    )
}

const fields = [{
    name: 'name',
    component: TextInput,
    type: 'text',
    isRequired: true
}, {
    name: 'description',
    component: TextInput,
    type: 'text'
}, {
    name: 'environment',
    component: SelectSingle,
    options: ['prod', 'test'],
    messageId: 'cloudAppStage',
    isRequired: true
}, {
    name: 'urn',
    component: TextInput,
    type: 'text',
    isRequired: true
}]

const WizardStepCloudAppStage = ({ cloudAppStage, onChange }) => (
    <TitledPane messageId="cloudAppStage.details">
        <Form>
            <FormFields
                fields={fields}
                messageId="cloudAppStage"
                entity={cloudAppStage}
                onChange={onChange} />
        </Form>
    </TitledPane>
)

export default WizardStepCloudAppStage