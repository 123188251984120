//@ts-check
import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import { Button, Modal, ModalVariant, SimpleList, SimpleListItem } from '@patternfly/react-core'
import { useReadAllQuery } from '../../Api/cloudAppStage'
import EmptyStateLoading from '../../EmptyState/loading'

const useModalSelectCloudAppStage = ({ onConfirm, isPending }) => {
    const { formatMessage } = useIntl()
    const [isOpen, setOpen] = useState(false)
    const onClose = () => setOpen(false)

    const [selectedItem, setSelectedItem] = useState(null)
    const handleConfirm = () => {
        onConfirm(selectedItem).then(() => {
            onClose()
        })
    }

    const onSelect = (ref) => {
        setSelectedItem(ref.current.dataset.uid)
    }

    const { data: cloudAppStages, isLoading } = useReadAllQuery()

    const modal = <Modal
        isOpen={isOpen}
        variant={ModalVariant.medium}
        title={formatMessage({ id: `modal.selectCloudAppStage.title` })}
        showClose={true}
        actions={[
            <Button key="confirm"
                variant="primary"
                onClick={handleConfirm}
                isLoading={isPending}
                isDisabled={isPending || selectedItem == null}>
                Confirm
            </Button>,
            <Button key="cancel" variant="link" onClick={onClose}>
                Cancel
            </Button>
        ]}
        onClose={onClose}>
        {isLoading
            ? <EmptyStateLoading />
            : <SimpleList onSelect={onSelect}>
                {cloudAppStages.map((cloudAppStage) => (
                    <SimpleListItem
                        key={cloudAppStage.uid}
                        isActive={cloudAppStage.uid == selectedItem}
                        componentProps={{ 'data-uid': cloudAppStage.uid }}>
                        {cloudAppStage.name} - {cloudAppStage.description}
                    </SimpleListItem>))}
            </SimpleList>}
    </Modal>

    return {
        modal: modal,
        isOpen: isOpen,
        openModal: () => setOpen(true)
    }
}

export default useModalSelectCloudAppStage