//@ts-check
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { pushNotification } from '../state/notification'

import { uniqueId } from '../utils/uniqueId'

export const useResponseNotificationBuilder = ({ messageIdOnSuccess, messageIdOnFailure, navigateTo = undefined }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    return (entity) => (response) => {
        if ('error' in response) {
            dispatch(pushNotification({
                uid: uniqueId(),
                variant: 'danger',
                messageId: messageIdOnFailure,
                entity,
                allowedRoles: response.error.data.allowedRoles
            }))
        } else {
            if (navigateTo != undefined) {
                navigate(navigateTo)
            }
            dispatch(pushNotification({
                uid: uniqueId(),
                variant: 'success',
                messageId: messageIdOnSuccess,
                entity
            }))
        }
    }
}