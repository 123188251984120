//@ts-check
import React from 'react'
import { useSelector } from 'react-redux'
import { Link, matchPath, useLocation } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl'

import { Breadcrumb, BreadcrumbItem, Spinner } from '@patternfly/react-core'

import { routes } from '..'

export const useRoute = () => {
    const location = useLocation()

    const currentRoute = routes.find(({ path }) => {
        if (matchPath(path, location.pathname)) {
            return true
        }
        return false
    })
    if (!currentRoute) {
        return [currentRoute, [], null]
    }

    const match = matchPath(currentRoute.path, location.pathname)
    const pathParts = match.pattern.path.split('/').filter(x => !x.startsWith(':')).slice(1)

    return [currentRoute, pathParts, match]
}

export const useRouteEntity = (route, uid) => {
    const selector = route.apiMethod.select(uid)
    const { data: entity, isLoading } = useSelector(selector)

    return [entity, isLoading]
}

const PageBreadcrumb = () => {
    const [route, pathParts, match] = useRoute()

    if (!route) {
        console.warn('[PageBreadcrumb] Route not found')
        return null
    }

    return (
        <Breadcrumb>
            <BreadcrumbItem>
                <Link to="/">Home</Link>
            </BreadcrumbItem>
            {pathParts.map((path, index) => (index != pathParts.length - 1)
                ? <BreadcrumbItem key={path}>
                    <Link to={`/${pathParts.slice(0, index + 1).join('/')}`}>
                        <BreadcrumbItemTitle
                            pathParts={pathParts}
                            index={index} />
                    </Link>
                </BreadcrumbItem>
                : <BreadcrumbItem key={path} isActive={true}>
                    {(route.apiMethod != null)
                        ? <BreadcrumbItemTitleDynamic
                            pathParts={pathParts}
                            index={index}
                            route={route}
                            uid={match.params.uid} />
                        : <BreadcrumbItemTitle
                            pathParts={pathParts}
                            index={index} />}
                </BreadcrumbItem>)}
        </Breadcrumb>
    )
}

export default PageBreadcrumb

const BreadcrumbItemTitleDynamic = ({ pathParts, index, route, uid }) => {
    const [entity, isLoading] = useRouteEntity(route, uid)

    return isLoading
        ? <Spinner isSVG size='md' />
        : <BreadcrumbItemTitle
            pathParts={pathParts}
            index={index}
            entity={entity} />
}

const BreadcrumbItemTitle = ({ pathParts, index, entity = undefined }) => {
    const { messages } = useIntl()

    const messageId = (pathParts.length == 1 && pathParts[0] === '')
        ? 'index'
        : pathParts.slice(0, index + 1).join('.')

    const messageIdItem = (`page.${messageId}.breadcrumb` in messages)
        ? `page.${messageId}.breadcrumb`
        : `page.${messageId}.title`

    return <FormattedMessage id={messageIdItem} values={entity} />

}
