//@ts-check
import React, { useEffect } from 'react'

import { Card, CardActions, CardBody, CardFooter, CardHeader, CardHeaderMain, CardTitle, Checkbox, Gallery, Split, SplitItem } from "@patternfly/react-core"

import TitledPane from '../../Wizard/titledPane'
import EmptyStateLoading from '../../EmptyState/loading'

import { useReadAllQuery } from '../../Api/cloudProvider'

import ServerIcon from '@patternfly/react-icons/dist/js/icons/server-icon'

const WizardStepCloudProvider = ({ selectedProviderUid, onSelectProvider }) => {
    const { data: cloudProviders, isLoading } = useReadAllQuery()

    useEffect(() => {
        if (cloudProviders && cloudProviders.length == 1) {
            onSelectProvider(cloudProviders[0].uid)
        }
    }, [cloudProviders])

    return (
        <TitledPane messageId="cloudAppStage.cloudProvider">
            {isLoading
                ? <EmptyStateLoading />
                : <Gallery hasGutter>
                    {cloudProviders.map((provider) => (
                        <Card
                            key={provider.uid}
                            isSelectableRaised
                            hasSelectableInput
                            isSelected={selectedProviderUid == provider.uid}
                            onClick={() => onSelectProvider((selectedProviderUid == provider.uid)
                                ? null
                                : provider.uid)}>
                            <CardHeader>
                                <CardHeaderMain>
                                    <i className={provider.icon}></i>
                                </CardHeaderMain>
                                <CardActions>
                                    <Checkbox
                                        isChecked={selectedProviderUid == provider.uid}
                                        onChange={() => onSelectProvider((selectedProviderUid == provider.uid)
                                            ? null
                                            : provider.uid)}
                                        id={`check-${provider.uid}`}
                                        name={`check-${provider.uid}`} />
                                </CardActions>
                            </CardHeader>
                            <CardTitle>{provider.name}</CardTitle>
                            <CardBody>{provider.description}</CardBody>
                            <CardFooter>
                                <Split hasGutter>
                                    <SplitItem>
                                        <ServerIcon /> Standort
                                    </SplitItem>
                                    <SplitItem>
                                        <Split hasGutter>
                                            {provider.locations.map((location) => (
                                                <SplitItem key={location}>
                                                    <span className={`fi fi-${location}`}></span>
                                                </SplitItem>
                                            ))}
                                        </Split>
                                    </SplitItem>
                                </Split>
                            </CardFooter>
                        </Card>
                    ))}
                </Gallery>}
        </TitledPane>
    )
}

export default WizardStepCloudProvider
