//@ts-check
import React from 'react'

import { Title, Button, EmptyState, EmptyStatePrimary, EmptyStateIcon, EmptyStateBody, TextContent, Text } from '@patternfly/react-core'

import EmojiSadTearIcon from '@patternfly/react-icons/dist/esm/icons/sad-tear-icon'

const EmptyStateNotFound = () => (
    <EmptyState>
        <EmptyStateIcon icon={EmojiSadTearIcon} />
        <Title size="lg" headingLevel="h4">
            Entity not found
        </Title>
        <EmptyStateBody>
            <TextContent>
                <Text>No entity with uid <b>bdc34485-7df6-433d-b2aa-6a7b1203d130</b> could be found.</Text>
                <Text>Please go back to the overview list and try to find the wanted entity. In case the problem remains, please contact the support team.</Text>
            </TextContent>
        </EmptyStateBody>
        <EmptyStatePrimary>
            <Button variant="link">Go back to overview</Button>
        </EmptyStatePrimary>
    </EmptyState>
)

export default EmptyStateNotFound
