//@ts-check
import { createAction, createReducer } from '@reduxjs/toolkit'

export const selectIsNotificationDrawerOpen = ({ ui }) => ui.isNotificationDrawerOpen

export const openNotificationDrawer = createAction('UI_NOTIFICATION_DRAWER_OPEN')
export const closeNotificationDrawer = createAction('UI_NOTIFICATION_DRAWER_CLOSE')


const uiReducer = createReducer({
    isNotificationDrawerOpen: false
}, (builder) => {
    builder
        .addCase(openNotificationDrawer, (state, payload) => {
            state.isNotificationDrawerOpen = true
        })
        .addCase(closeNotificationDrawer, (state, payload) => {
            state.isNotificationDrawerOpen = false
        })
        .addDefaultCase((state, action) => { })
})

export default uiReducer
