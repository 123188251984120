//@ts-check
import React, { useState } from 'react'

import FieldGroupPane from '../../Form/fieldGroupPane'
import DescriptionListEntityFields from '../../DescriptionList/entity'
import ConnectorIdentifier from '../../Connector/identifierLabel'
import StaticTextValue from '../../FormEditableField/static'
import { useUpdateByUidMutation } from '../../Api/cloudApp'

const entityFieldsSourceCode = [
    { fieldName: 'connector_uid', component: ConnectorIdentifier },
    { fieldName: 'repository_uid', component: StaticTextValue }
]

const PaneSourceCode = ({ cloudApp }) => (
    <FieldGroupPane messageId="cloudApp.scm">
        <DescriptionListEntityFields
            fields={entityFieldsSourceCode}
            messageId="cloudApp.sourceCodeRepository"
            entity={cloudApp.dev_workflow.source_code_repository}>
            {(props) => <FieldWrapper {...props} />}
        </DescriptionListEntityFields>
    </FieldGroupPane>
)

export default PaneSourceCode

const FieldWrapper = ({ component, uid, fieldName, value, ...args }) => {
    const [nextValue, setNextValue] = useState(value)
    const [updateField, { isLoading: isPending }] = useUpdateByUidMutation()

    const onSave = () => {
        return updateField({ uid, fieldName, value: nextValue })
    }

    return React.createElement(component, {
        uid,
        fieldName,
        value,
        nextValue,
        setNextValue,
        onSave,
        isPending,
        ...args
    })
}