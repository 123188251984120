//@ts-check
import useModalConfirmDeleteEntity from '../Modal/confirmDeleteEntity'
import { useDeleteByUidMutation } from '../Api/cloudAppStage'
import { useResponseNotificationBuilder } from '../Notification/hooks'

const useModalDeleteCloudAppStage = (entity) => {
    const [deleteCloudAppStage, { isLoading: isPending }] = useDeleteByUidMutation()
    const responseNotificationBuilder = useResponseNotificationBuilder({
        messageIdOnSuccess: 'cloudAppStageDeleted',
        messageIdOnFailure: 'cloudAppStageDeleteForbidden',
        navigateTo: '/cloud-app-stage'
    })

    const onConfirm = () => deleteCloudAppStage(entity.uid)
        .then(responseNotificationBuilder(entity))

    return useModalConfirmDeleteEntity({
        messageId: 'cloudAppStage',
        onConfirm,
        isPending
    })

}

export default useModalDeleteCloudAppStage