//@ts-check
import React from 'react'

import FieldGroupPane from '../../Form/fieldGroupPane'

const PaneUsers = ({ cloudApp }) => (
    <FieldGroupPane messageId="cloudApp.users">
        TODO: implement
    </FieldGroupPane>
)

export default PaneUsers
