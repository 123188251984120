//@ts-check
import React from 'react'

import EmptyStateError from '../../EmptyState/error'

const WizardStepCreateFailed = () => {

    return (<EmptyStateError />)

}

export default WizardStepCreateFailed