//@ts-check
import React, { useEffect, useState } from 'react'
import { useMatch } from 'react-router-dom'
import { FormattedMessage, IntlProvider, useIntl } from 'react-intl'

import { DescriptionList, DescriptionListDescription, DescriptionListGroup, DescriptionListTerm, PageSection, PageSectionVariants } from '@patternfly/react-core'

import EmptyStateLoading from '../../../EmptyState/loading'
import EmptyStateNotFound from '../../../EmptyState/notFound'
import EmptyStateError from '../../../EmptyState/error'
import EditableTextValue from '../../../FormEditableField/input'

import { useReadByUidQuery, useUpdateByUidMutation } from '../../../Api/connector'

const ConnectorEdit = () => {
    const match = useMatch('/settings/connectors/:uid/update')

    const { data: connector, isLoading, isError, error } = useReadByUidQuery(match.params.uid)

    return (
        <PageSection
            variant={PageSectionVariants.light}
            isFilled>
            {isLoading
                ? <EmptyStateLoading />
                : isError
                    ? (error.status == 404)
                        ? <EmptyStateNotFound />
                        : <EmptyStateError />
                    : <ConnectorEntity connector={connector} />}
        </PageSection>
    )
}

export default ConnectorEdit

const ConnectorEntity = ({ connector }) => {
    const { locale } = useIntl()
    const entityFields = Object.entries(connector.config.options).map(([option, info]) => ({
        fieldName: option,
        component: EditableTextValue,
        value: connector.config.values[option]
            ? connector.config.values[option]
            : info.default
    }))

    return (
        <IntlProvider
            locale={locale}
            messages={connector.config.messages[locale]
                ? connector.config.messages[locale]
                : connector.config.messages.default}>
            <DescriptionList
                isHorizontal
                horizontalTermWidthModifier={{
                    sm: '100%',
                    lg: '15vh',
                }}
                orientation={{
                    sm: 'vertical',
                    md: 'vertical',
                    lg: 'horizontal',
                    xl: 'horizontal',
                    '2xl': 'horizontal'
                }}>
                {entityFields.map(({ fieldName, component, value }) => (
                    <DescriptionListGroup key={fieldName}>
                        <DescriptionListTerm>
                            <FormattedMessage id={`config.${fieldName}.label`} />
                        </DescriptionListTerm>
                        <DescriptionListDescription>
                            <FieldWrapper
                                component={component}
                                uid={connector.uid}
                                fieldName={fieldName}
                                value={value} />
                        </DescriptionListDescription>
                    </DescriptionListGroup>))}
            </DescriptionList>
        </IntlProvider>
    )
}

const FieldWrapper = ({ component, uid, fieldName, value, ...args }) => {
    const [nextValue, setNextValue] = useState(value)
    const [updateField, { isLoading: isPending }] = useUpdateByUidMutation()

    const onSave = () => {
        return updateField({ uid, property: fieldName, value: nextValue })
    }

    useEffect(() => {
        setNextValue(value)
    }, [value])

    return React.createElement(component, {
        uid,
        fieldName,
        value,
        nextValue,
        setNextValue,
        onSave,
        isPending,
        ...args
    })

}
