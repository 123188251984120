//@ts-check
import React from 'react'
import { FormattedMessage } from 'react-intl'

import { EmptyState, EmptyStateBody, EmptyStateIcon, Spinner, Text, TextContent, TextVariants } from '@patternfly/react-core'

const WizardStepCreate = ({ cloudApp }) => (
    <EmptyState>
        <EmptyStateIcon variant="container" component={Spinner} />
        <EmptyStateBody>
            <TextContent>
                <Text component={TextVariants.p}>
                    <FormattedMessage id="wizard.cloudAppCreate.createPending.pendingTitle" values={cloudApp} />
                </Text>
                <Text component={TextVariants.p}>
                    <FormattedMessage id="wizard.cloudAppCreate.createPending.pendingDescription" values={cloudApp} />
                </Text>
            </TextContent>
        </EmptyStateBody>
    </EmptyState>
)

export default WizardStepCreate