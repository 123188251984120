//@ts-check

import { useState } from "react"

/**
 * @typedef {Array} dropdownState
 * @property {boolean} isOpen
 * @property {Function} onToggle
 * @property {Function} onSelect
 */

/**
 * 
 * @returns {dropdownState}
 */
export const useDropdownState = () => {
    const [isOpen, setOpen] = useState(false)

    const onToggle = () => setOpen(!isOpen)
    const onSelect = () => setOpen(false)

    return [isOpen, onToggle, onSelect]
}
