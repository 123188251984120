//@ts-check
import React from 'react'
import { Link, useMatch } from 'react-router-dom'

import { Button, PageSection, PageSectionVariants, Skeleton, Toolbar, ToolbarContent, ToolbarItem } from '@patternfly/react-core'

import EmptyStateLoading from '../EmptyState/loading'
import EmptyStateNotFound from '../EmptyState/notFound'
import EmptyStateError from '../EmptyState/error'
import EntityUpdate from '../EntityUpdate'

const EntityEditView = ({ route, messageId, toolbarItems, entityFields, readEntityQuery, updateByUidMutation }) => {
    const match = useMatch(route)

    const { data: entity, isLoading, isError, error } = readEntityQuery(match.params.uid)

    return (
        <React.Fragment>
            {!isError &&
                <Toolbar id="toolbar-items" usePageInsets={true}>
                    <ToolbarContent>
                        {isLoading
                            ? <Skeleton fontSize="3xl" />
                            : <ToolbarActions items={toolbarItems(entity)} />}
                    </ToolbarContent>
                </Toolbar>}
            <PageSection variant={PageSectionVariants.light}>
                {isLoading
                    ? <EmptyStateLoading />
                    : isError
                        ? (error.status == 404)
                            ? <EmptyStateNotFound />
                            : <EmptyStateError />
                        : <EntityUpdate
                            messageId={messageId}
                            entityFields={entityFields(entity)}
                            updateHook={updateByUidMutation}
                            entity={entity} />}
            </PageSection>
        </React.Fragment>
    )

}

export default EntityEditView

const ToolbarActions = ({ items }) => (
    <React.Fragment>
        {items.map((item) => (item.isSeparator
            ? <ToolbarItem
                key={item.id}
                variant="separator" />
            : (item.to != undefined)
                ? <ToolbarItem key={item.id}>
                    <Link to={item.to}>
                        <Button
                            variant={item.variant}
                            icon={item.icon}
                            isDanger={item.isDanger}
                            isDisabled={item.isDisabled}>
                            {item.title}
                        </Button>
                    </Link>
                </ToolbarItem>
                : <ToolbarItem key={item.id}>
                    <Button
                        variant={item.variant}
                        icon={item.icon}
                        onClick={item.onClick}
                        isDanger={item.isDanger}
                        isDisabled={item.isDisabled}
                        isLoading={item.isLoading}>
                        {item.title}
                    </Button>
                </ToolbarItem>))}
    </React.Fragment>
)
