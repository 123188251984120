//@ts-check
import React from 'react'

import { Form, TextInput } from "@patternfly/react-core"

import TitledPane from '../../Wizard/titledPane'
import FormFields from '../../Form/fields'

const fields = [{
    name: 'password',
    component: TextInput,
    type: 'text',
    isRequired: true
}, {
    name: 'pubkey',
    component: TextInput,
    type: 'text'
}]

const WizardStepAdminUser = ({ adminUser, onChange }) => (
    <TitledPane messageId="cloudAppStageNode.adminUser">
        <Form>
            <FormFields
                fields={fields}
                messageId="cloudAppStageNode.adminUser"
                entity={adminUser}
                onChange={onChange} />
        </Form>
    </TitledPane>
)

export default WizardStepAdminUser