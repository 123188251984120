//@ts-check
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { FormattedList, useIntl } from 'react-intl'

import { AboutModal, Spinner, TextContent, TextList, TextListItem } from '@patternfly/react-core'

import { selectUser, selectUserRoles } from '../state/app'

import { useReadVersionQuery } from '../Api/version'

import releaseInfo from '../release'

const useAboutModal = () => {
    const [isOpen, setOpen] = useState(false)
    const onClose = () => setOpen(false)

    const { data: releaseInfoBackend, isLoading, isError } = useReadVersionQuery()

    const { username } = useSelector(selectUser)
    const roles = useSelector(selectUserRoles)

    var userAgent = navigator.userAgent
    var browser = navigator.appName
    var version = '' + parseFloat(navigator.appVersion)

    var nameOffset, verOffset, ix

    if ((verOffset = userAgent.indexOf('Opera')) != -1) {
        browser = 'Opera'
        version = userAgent.substring(verOffset + 6)
        if ((verOffset = userAgent.indexOf('Version')) != -1) {
            version = userAgent.substring(verOffset + 8)
        }
    } else if ((verOffset = userAgent.indexOf('OPR')) != -1) {
        browser = 'Opera';
        version = userAgent.substring(verOffset + 4)
    } else if ((verOffset = userAgent.indexOf('Edge')) != -1) {
        browser = 'Microsoft Legacy Edge'
        version = userAgent.substring(verOffset + 5)
    } else if ((verOffset = userAgent.indexOf('Edg')) != -1) {
        browser = 'Microsoft Edge'
        version = userAgent.substring(verOffset + 4)
    }
    else if ((verOffset = userAgent.indexOf('MSIE')) != -1) {
        browser = 'Microsoft Internet Explorer'
        version = userAgent.substring(verOffset + 5)
    } else if ((verOffset = userAgent.indexOf('Chrome')) != -1) {
        browser = 'Chrome'
        version = userAgent.substring(verOffset + 7)
    } else if ((verOffset = userAgent.indexOf('Safari')) != -1) {
        browser = 'Safari'
        version = userAgent.substring(verOffset + 7)
        if ((verOffset = userAgent.indexOf('Version')) != -1) {
            version = userAgent.substring(verOffset + 8)
        }
    } else if ((verOffset = userAgent.indexOf('Firefox')) != -1) {
        browser = 'Firefox'
        version = userAgent.substring(verOffset + 8)
    } else if (userAgent.indexOf('Trident/') != -1) {
        browser = 'Microsoft Internet Explorer'
        version = userAgent.substring(userAgent.indexOf('rv:') + 3)
    } else if ((nameOffset = userAgent.lastIndexOf(' ') + 1) < (verOffset = userAgent.lastIndexOf('/'))) {
        browser = userAgent.substring(nameOffset, verOffset)
        version = userAgent.substring(verOffset + 1)
        if (browser.toLowerCase() == browser.toUpperCase()) {
            browser = navigator.appName
        }
    }

    if ((ix = version.indexOf(';')) != -1) {
        version = version.substring(0, ix)
    }
    if ((ix = version.indexOf(' ')) != -1) {
        version = version.substring(0, ix)
    }
    if ((ix = version.indexOf(')')) != -1) {
        version = version.substring(0, ix)
    }

    const modal = (isOpen)
        ? <AboutModal
            isOpen={true}
            onClose={onClose}
            trademark="&copy; 2022 by mind4bytes IT GmbH"
            brandImageSrc="/itbox365-brand.svg"
            brandImageAlt="ITBox365 Logo"
            backgroundImageSrc="/background-about.jpg"
            noAboutModalBoxContentContainer={true}
            productName="ITBox365">
            <TextContent className="pf-u-py-xl">
                <TextList component="dl">
                    <TextListItem component="dt">ITBox365 Version</TextListItem>
                    <TextListItem component="dd">
                        {(isLoading || isError)
                            ? <Spinner size="md" isSVG />
                            : <React.Fragment>{releaseInfoBackend.version} ({releaseInfoBackend.gitCommit})</React.Fragment>}
                    </TextListItem>
                    <TextListItem component="dt">Console Version</TextListItem>
                    <TextListItem component="dd">{releaseInfo.version} ({releaseInfo.gitCommit})</TextListItem>
                    <TextListItem component="dt">User Name</TextListItem>
                    <TextListItem component="dd">{username}</TextListItem>
                    <TextListItem component="dt">User Role</TextListItem>
                    <TextListItem component="dd"><FormattedList type="conjunction" value={roles} /></TextListItem>
                    <TextListItem component="dt">Browser</TextListItem>
                    <TextListItem component="dd">{browser}</TextListItem>
                    <TextListItem component="dt">Browser Version</TextListItem>
                    <TextListItem component="dd">{version}</TextListItem>
                    <TextListItem component="dt">Browser OS</TextListItem>
                    <TextListItem component="dd">{navigator.oscpu}</TextListItem>
                </TextList>
            </TextContent>
        </AboutModal>
        : null

    return {
        modal: modal,
        isOpen: isOpen,
        openModal: () => setOpen(true)
    }
}

export default useAboutModal
