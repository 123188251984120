//@ts-check
import { createApi } from '@reduxjs/toolkit/query/react'
import { dynamicBaseQuery } from "./common"

export const cloudAppStageNodeApi = createApi({
    reducerPath: 'cloudAppStageNodeApi',
    baseQuery: dynamicBaseQuery('cloudAppStageNodeApi'),
    tagTypes: ['EntityByUid'],
    endpoints: (builder) => ({
        create: builder.mutation({
            query: ({ cloudAppStageUid, cloudProviderUid, name, description, urn, location, server, os_image, password, pubkey }) => ({
                url: `/cloud-app-stage/${cloudAppStageUid}/nodes`,
                method: 'POST',
                body: {
                    app_stage_node: {
                        name,
                        description,
                        urn,
                        app_stage_uid: cloudAppStageUid,
                        node_location: location,
                        node_server_type: server,
                        node_image: os_image,
                        provisioning_connector: cloudProviderUid
                    },
                    admin_user: {
                        password,
                        pubkey
                    }
                }
            }),
            invalidatesTags: ['EntityByUid']
        }),
        readAll: builder.query({
            query: ({ cloudAppStageUid }) => ({
                url: `/cloud-app-stage/${cloudAppStageUid}/nodes`
            }),
        }),
        readByUid: builder.query({
            query: ({ cloudAppStageUid, uid }) => ({
                url: `/cloud-app-stage/${cloudAppStageUid}/nodes/${uid}`
            }),
        }),
    })
})

export const {
    useCreateMutation,
    useReadAllQuery,
    useReadByUidQuery,
    endpoints
} = cloudAppStageNodeApi
