//@ts-check
import React from 'react'
import { FormattedNumber } from 'react-intl'

import { DataList, DataListCell, DataListItem, DataListItemCells, DataListItemRow } from "@patternfly/react-core"

import CpuIcon from '@patternfly/react-icons/dist/js/icons/microchip-icon'
import RamIcon from '@patternfly/react-icons/dist/js/icons/memory-icon'
import HddIcon from '@patternfly/react-icons/dist/js/icons/hdd-icon'
import NetworkIcon from '@patternfly/react-icons/dist/js/icons/network-icon'

const ServerInfo = ({ server, additionalLabels = [] }) => (
    <DataList aria-label="" isCompact>
        {[
            { icon: <CpuIcon />, label: "CPU", value: server.cpu },
            { icon: <RamIcon />, label: "RAM", value: <FormattedNumber value={server.ram} style="unit" unit="megabyte" unitDisplay="narrow" /> },
            { icon: <HddIcon />, label: "HDD", value: <FormattedNumber value={server.hdd} style="unit" unit="gigabyte" unitDisplay="narrow" /> },
            { icon: <NetworkIcon />, label: "Traffic", value: <FormattedNumber value={server.traffic} style="unit" unit="terabyte" unitDisplay="narrow" /> },
            ...additionalLabels
        ].map(({ icon, label, value }) => (
            <DataListItem aria-labelledby="">
                <DataListItemRow>
                    <DataListItemCells
                        dataListCells={[
                            <DataListCell key="primary content">
                                {icon}&nbsp;{label}
                            </DataListCell>,
                            <DataListCell key="secondary content">
                                {value}
                            </DataListCell>
                        ]} />
                </DataListItemRow>
            </DataListItem>))}
    </DataList>
)

export default ServerInfo
