//@ts-check
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const i18nApi = createApi({
    reducerPath: 'i18nApi',
    baseQuery: fetchBaseQuery({
        baseUrl: '/',
    }),
    tagTypes: ['Messages'],
    endpoints: (builder) => ({
        readMessages: builder.query({
            query: (iso_lang) => ({
                url: `/i18n/${iso_lang}.json`
            }),
            providesTags: ['Messages']
        })
    })
})

export const {
    useLazyReadMessagesQuery,
    endpoints
} = i18nApi
