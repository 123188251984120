//@ts-check
import { createAction, createReducer } from '@reduxjs/toolkit'

export const selectIsAuthenticated = ({ app }) => app.user != null

export const selectUser = ({ app }) => app.user

export const selectUserRoles = ({ app }) => app.user.roles

export const selectIsInactive = ({ app }) => app.isInactive

export const setAppUser = createAction('APP_SET_USER', ({ username, firstname, lastname, email, roles }) =>
    ({ payload: { username, firstname, lastname, email, roles } }))

export const setAppUserActive = createAction('APP_USER_ACTIVE')

export const setAppAccessTokenExpired = createAction('APP_ACCESS_TOKEN_EXPIRED')

export const setAppAccessTokenRefreshed = createAction('APP_ACCESS_TOKEN_REFRESHED')

const appReducer = createReducer({
    user: null,
    isInactive: false
}, (builder) => {
    builder
        .addCase(setAppUser, (state, { payload: { username, firstname, lastname, email, roles } }) => {
            state.user = {
                username,
                firstname,
                lastname,
                email,
                roles
            }
        })
        .addCase(setAppAccessTokenExpired, (state) => {
            state.isInactive = true
        })
        .addCase(setAppUserActive, (state) => {
            state.isInactive = false
        })
        .addCase(setAppAccessTokenRefreshed, (state) => {
            state.isInactive = false
        })
        .addDefaultCase((state, action) => { })
})

export default appReducer
