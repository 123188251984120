//@ts-check
import useModalConfirmDeleteEntity from '../Modal/confirmDeleteEntity'
import { useDeleteByUidMutation } from '../Api/cloudUsers'
import { useResponseNotificationBuilder } from '../Notification/hooks'

const useModalDeleteCloudUser = () => {
    const [deleteCloudUser, { isLoading: isPending }] = useDeleteByUidMutation()
    const responseNotificationBuilder = useResponseNotificationBuilder({
        messageIdOnSuccess: 'cloudUserDeleted',
        messageIdOnFailure: 'cloudUserDeleteForbidden',
        navigateTo: '/cloud-user'
    })

    const onConfirmDeleteCloudUser = (entity) => deleteCloudUser(entity.uid)
        .then(responseNotificationBuilder(entity))

    return useModalConfirmDeleteEntity({ messageId: 'cloudUser', onConfirm: onConfirmDeleteCloudUser, isPending })
}

export default useModalDeleteCloudUser
