//@ts-check
import React from 'react'

import { Card, CardActions, CardBody, CardHeader, CardHeaderMain, CardTitle, Split, SplitItem } from '@patternfly/react-core'

import ServerIcon from '@patternfly/react-icons/dist/js/icons/server-icon'

const CardCloudAppStageNode = ({ node, isSelected = false, onChange = undefined, cardActions = undefined, children }) => (
    <Card
        isSelectableRaised
        hasSelectableInput
        isSelected={isSelected}
        onChange={onChange}>
        <CardHeader>
            <CardHeaderMain>
                <Split hasGutter>
                    <SplitItem>
                        <ServerIcon />
                    </SplitItem>
                    <SplitItem>
                        {node.ipv4_address}
                    </SplitItem>
                </Split>
            </CardHeaderMain>
            {cardActions && <CardActions>
                {cardActions}
            </CardActions>}
        </CardHeader>
        <CardTitle>{node.name} <small>({node.urn})</small></CardTitle>
        {node.description && <CardBody>{node.description}</CardBody>}
        {children}
    </Card>
)

export default CardCloudAppStageNode
