//@ts-check
import React, { useState } from 'react'

import { DataList, DragDrop, Drawer, DrawerContentBody, Droppable, PageSection, PageSectionVariants } from '@patternfly/react-core'

import EmptyStateLoading from '../../EmptyState/loading'
import EmptyStateNoData from '../../EmptyState/noData'
import ListToolbar from './toolbar'
import CloudAppStageItem from './dataListItem'

import { endpoints, useReadAllQuery } from '../../Api/cloudAppStage'

const CloudAppStageList = () => {
    const { data: cloudAppStages, isLoading } = useReadAllQuery()
    const [selectedItems, setSelectedItems] = useState([])

    const toggleItemSelection = (itemUid) => {
        const index = selectedItems.indexOf(itemUid)
        if (index != -1) {
            const nextState = selectedItems.slice(0)
            nextState.splice(index, 1)
            setSelectedItems(nextState)
        } else {
            setSelectedItems(selectedItems.concat(itemUid))
        }
    }

    const toggleAllItemSelection = () => {
        if (selectedItems.length > 0) {
            setSelectedItems(new Array(0))
        } else {
            setSelectedItems(cloudAppStages.map(x => x.uid))
        }
    }

    return (
        <PageSection
            variant={PageSectionVariants.light}
            padding={{ default: 'noPadding' }}
            isFilled>
                <Drawer isInline>
                    <DrawerContentBody>
                        <ListToolbar
                            isLoading={isLoading}
                            selectedItems={selectedItems}
                            toggleItemSelection={toggleItemSelection}
                            toggleAllItemSelection={toggleAllItemSelection} />
                        {isLoading
                            ? <EmptyStateLoading />
                            : (cloudAppStages.length == 0)
                                ? <EmptyStateNoData createRoute="/cloud-app-stage/create" />
                                : <CloudAppStages
                                    selectedItems={selectedItems}
                                    toggleItemSelection={toggleItemSelection} />}
                    </DrawerContentBody>
                </Drawer>
        </PageSection>
    )
}

export default CloudAppStageList

const CloudAppStages = ({ selectedItems, toggleItemSelection }) => {
    const { data: appStages } = endpoints.readAll.useQueryState()

    const onDrop = (source, dest) => {
        if (dest) {
            /*
            const nextItems = [...items]
            const orderSource = nextItems[source.index].order
            nextItems[source.index].order = nextItems[dest.index].order
            nextItems[dest.index].order = orderSource
            setItems(nextItems)
            return true
            */
        }
    }

    return (
        <DataList aria-label="">
            {appStages
                //.sort((x, y) => x.order - y.order)
                .map((cloudAppStage) => (
                    <CloudAppStageItem
                        key={cloudAppStage.uid}
                        isSelected={selectedItems.indexOf(cloudAppStage.uid) != -1}
                        toggleItemSelection={toggleItemSelection}
                        cloudAppStage={cloudAppStage} />))}
        </DataList>
    )
    /*
    return (
        <DragDrop onDrop={onDrop}>
            <Droppable hasNoWrapper>
            <DataList aria-label="">
                    {appStages
                        //.sort((x, y) => x.order - y.order)
                        .map((cloudAppStage) => (
                            <CloudAppStageItem
                                key={cloudAppStage.uid}
                                isSelected={selectedItems.indexOf(cloudAppStage.uid) != -1}
                                toggleItemSelection={toggleItemSelection}
                                cloudAppStage={cloudAppStage} />))}
                </DataList>
            </Droppable>
        </DragDrop>
    )
    */
}
