//@ts-check
import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { Alert, Card, CardActions, CardBody, CardExpandableContent, CardFooter, CardHeader, CardTitle, Checkbox, DescriptionListDescription, DescriptionListGroup, DescriptionListTerm, Gallery, Radio, Stack, StackItem } from "@patternfly/react-core"

import { DescriptionListEntity } from '../../DescriptionList/entity'
import EmptyStateLoading from '../../EmptyState/loading'
import TitledPane from '../../Wizard/titledPane'

import { useReadAllQuery as useReadAllStagesQuery } from '../../Api/cloudAppStage'
import { useReadAllQuery as useReadAllStageNodesQuery } from '../../Api/cloudAppStageNode'

import CardCloudAppStageNode from '../../CloudAppStage/cardCloudAppStageNode'

const WizardStepAssignCloudAppStages = ({ cloudApp, onSelectCloudAppStage, onCloudAppStageNodeAppend, onCloudAppStageNodeRemove }) => (
    <TitledPane messageId="cloudAppCreate.stage">
        <DescriptionListEntity>
            {Object.entries(cloudApp.app_stages).map(([appStageType, appStageUid]) => (
                <DescriptionListGroup key={appStageType}>
                    <DescriptionListTerm>
                        <FormattedMessage id={`field.cloudAppStage.environment.option.${appStageType}`} />
                    </DescriptionListTerm>
                    <DescriptionListDescription>
                        <StageDeployConfig
                            appStageType={appStageType}
                            appStageTypeAssignedStage={appStageUid}
                            selectedNodes={cloudApp.app_stages_nodes[appStageType]}
                            onSelectCloudAppStage={onSelectCloudAppStage}
                            onCloudAppStageNodeAppend={onCloudAppStageNodeAppend}
                            onCloudAppStageNodeRemove={onCloudAppStageNodeRemove} />
                    </DescriptionListDescription>
                </DescriptionListGroup>))}
        </DescriptionListEntity>
    </TitledPane>
)

export default WizardStepAssignCloudAppStages

const StageDeployConfig = ({
    appStageType,
    appStageTypeAssignedStage,
    selectedNodes,
    onSelectCloudAppStage,
    onCloudAppStageNodeAppend,
    onCloudAppStageNodeRemove }) => {
    const { data: cloudAppStages, isLoading } = useReadAllStagesQuery()

    return (cloudAppStages && cloudAppStages.length > 0)
        ? isLoading
            ? <EmptyStateLoading />
            : <Stack hasGutter>
                {cloudAppStages
                    .filter(x => x.environment === appStageType).length == 0
                    ? <Alert
                        variant="warning"
                        isInline
                        isPlain
                        title={<FormattedMessage id="wizard.cloudAppCreate.stage.alertNoAssignableCloudAppStages" />} />
                    : cloudAppStages
                        .filter(x => x.environment === appStageType)
                        .map((cloudAppStage) => (
                            <StackItem>
                                <StageSelector
                                    key={cloudAppStage.uid}
                                    cloudAppStage={cloudAppStage}
                                    selectedNodes={selectedNodes}
                                    appStageType={appStageType}
                                    isSelected={appStageTypeAssignedStage === cloudAppStage.uid}
                                    onSelectCloudAppStage={onSelectCloudAppStage}
                                    onCloudAppStageNodeAppend={onCloudAppStageNodeAppend}
                                    onCloudAppStageNodeRemove={onCloudAppStageNodeRemove} />
                            </StackItem>))}
            </Stack>
        : <Alert
            variant="warning"
            isInline
            isPlain
            title={<FormattedMessage id="wizard.cloudAppCreate.stage.alertNoCloudAppStages" />} />
}


const StageSelector = ({
    cloudAppStage,
    selectedNodes,
    appStageType,
    isSelected,
    onSelectCloudAppStage,
    onCloudAppStageNodeAppend,
    onCloudAppStageNodeRemove }) => {
    const [isExpanded, setExpanded] = useState(false)

    return (
        <Card
            isSelectable
            hasSelectableInput
            onClick={() => onSelectCloudAppStage(appStageType, cloudAppStage.uid)}
            isSelected={isSelected}
            isExpanded={isExpanded}>
            <CardHeader
                onExpand={() => setExpanded(!isExpanded)}>
                <CardActions>
                    <Radio
                        isChecked={isSelected}
                        onChange={() => onSelectCloudAppStage(appStageType, cloudAppStage.uid)}
                        id={`check-${cloudAppStage.uid}`}
                        name={`check-${cloudAppStage.uid}`} />
                </CardActions>
                <CardTitle>{cloudAppStage.name} - <i>{cloudAppStage.description}</i></CardTitle>
            </CardHeader>
            <CardExpandableContent>
                <CardBody>
                    <NodeSelector
                        appStageUid={cloudAppStage.uid}
                        selectedNodes={selectedNodes}
                        appStageType={appStageType}
                        onCloudAppStageNodeAppend={onCloudAppStageNodeAppend}
                        onCloudAppStageNodeRemove={onCloudAppStageNodeRemove} />
                </CardBody>
            </CardExpandableContent>
        </Card>
    )
}

const NodeSelector = ({ appStageUid, selectedNodes, appStageType, onCloudAppStageNodeAppend, onCloudAppStageNodeRemove }) => {
    const { data: cloudAppStageNodes, isLoading } = useReadAllStageNodesQuery({ cloudAppStageUid: appStageUid })

    return isLoading
        ? <EmptyStateLoading />
        : (cloudAppStageNodes && cloudAppStageNodes.length > 0)
            ? <Gallery hasGutter>
                {cloudAppStageNodes.map((node) => (
                    <CardCloudAppStageNode
                        node={node}
                        isSelected={selectedNodes.includes(node.uid)}
                        cardActions={<CardActions>
                            <Checkbox
                                isChecked={selectedNodes.includes(node.uid)}
                                onChange={() => !selectedNodes.includes(node.uid)
                                    ? onCloudAppStageNodeAppend(appStageType, node.uid)
                                    : onCloudAppStageNodeRemove(appStageType, node.uid)}
                                id={`check-${node.uid}`}
                                name={`check-${node.uid}`} />
                        </CardActions>}
                        onChange={() => !selectedNodes.includes(node.uid)
                            ? onCloudAppStageNodeAppend(appStageType, node.uid)
                            : onCloudAppStageNodeRemove(appStageType, node.uid)}>
                        <CardFooter>{node.urn}</CardFooter>
                    </CardCloudAppStageNode>))}
            </Gallery>
            : <Alert
                variant="warning"
                isInline
                title={<FormattedMessage id="wizard.cloudAppCreate.stage.alertNoCloudAppStageNodes" />} />
}
