//@ts-check
import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { ActionGroup, Button, Form, PageSection, PageSectionVariants, TextInput } from '@patternfly/react-core'

import FormFields from '../../Form/fields'

import { useResponseNotificationBuilder } from '../../Notification/hooks'

import { useCreateMutation } from '../../Api/cloudUsers'

import SaveIcon from '@patternfly/react-icons/dist/esm/icons/save-icon'

const fields = [{
    name: 'firstname',
    component: TextInput,
    type: 'text',
    isRequired: true
}, {
    name: 'lastname',
    component: TextInput,
    type: 'text',
    isRequired: true
}, {
    name: 'email',
    component: TextInput,
    type: 'email',
    isRequired: true
}]

const CloudUserCreate = () => {
    const [createCloudUser, { isLoading: isPending }] = useCreateMutation()
    const responseNotificationBuilder = useResponseNotificationBuilder({
        messageIdOnSuccess: 'cloudUserCreated',
        messageIdOnFailure: 'cloudUserCreateForbidden',
        navigateTo: '/cloud-user'
    })
    const [entity, setEntity] = useState({
        firstname: '',
        lastname: '',
        email: ''
    })

    const onChange = (value, e) => {
        setEntity({
            ...entity,
            [e.target.name]: value
        })
    }

    const onSave = (e) => {
        e.preventDefault()
        createCloudUser({ cloudUser: entity }).then(responseNotificationBuilder(entity))
    }
    return (
        <PageSection variant={PageSectionVariants.light}>
            <Form>
                <FormFields
                    messageId="cloudUser"
                    fields={fields}
                    entity={entity}
                    onChange={onChange}
                    isDisabled={isPending} />
                <ActionGroup>
                    <Button
                        type="submit"
                        onClick={onSave}
                        icon={<SaveIcon />}
                        isLoading={isPending}
                        isDisabled={isPending}>
                        <FormattedMessage id="button.cloudUser.btnFormCreateSubmit" />
                    </Button>
                </ActionGroup>
            </Form>
        </PageSection>
    )
}

export default CloudUserCreate
