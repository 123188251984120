//@ts-check
import React from 'react'

import { Form, TextInput } from "@patternfly/react-core"

import TitledPane from '../../Wizard/titledPane'
import FormFields from '../../Form/fields'
import PaneCloudServer from '../paneCloudServer'

const fields = [{
    name: 'name',
    component: TextInput,
    type: 'text',
    isRequired: true
}, {
    name: 'description',
    component: TextInput,
    type: 'text'
}, {
    name: 'urn',
    component: TextInput,
    type: 'text',
    isRequired: true
}]

const WizardStepCloudAppStageNode = ({ cloudProviderUid, cloudAppStageNode, onChange }) => (
    <TitledPane messageId="cloudAppStage.cloudAppStageNode">
        <Form>
            <FormFields
                fields={fields}
                messageId="cloudAppStageNode"
                entity={cloudAppStageNode}
                onChange={onChange} />
            <PaneCloudServer
                cloudProviderUid={cloudProviderUid}
                cloudAppStageNode={cloudAppStageNode}
                onChange={onChange} />
        </Form>
    </TitledPane>
)

export default WizardStepCloudAppStageNode
