//@ts-check
import React from 'react'

import { Card, CardActions, CardBody, CardHeader, CardTitle, Checkbox, Gallery } from "@patternfly/react-core"

import EmptyStateLoading from '../../EmptyState/loading'
import TitledPane from '../../Wizard/titledPane'

import { useReadAllByTypeQuery } from '../../Api/connector'

const devWorkflows = [{
    uid: 'DevWorkflowNoRepo',
    name: 'No repository',
    description: "my description"
},
{
    uid: 'DevWorkflowCentral',
    name: 'Central repository',
    description: "Optimal for very small dev teams with just 1 or 2 developers."
},
{
    uid: 'DevWorkflowFeatureBranch',
    name: 'Feature branch',
    description: "Better suited for medium size dev teams with 2 - 4 developers."
},
{
    uid: 'DevWorkflowGitFlow',
    name: 'Gitflow',
    description: "Sophisticated workflow for larger teams with at least 4 developers."
}]

const WizardStepDevWorkflow = ({ selectedDevWorkflow, onSelectDevWorkflow }) => {
    const { data: scmProviders, isLoading } = useReadAllByTypeQuery('source-code-repository')

    const selectableWorkflows = isLoading
        ? []
        : scmProviders.length == 0
            ? devWorkflows.filter(x => x.uid === 'DevWorkflowNoRepo')
            : devWorkflows

    return (
        <TitledPane messageId="cloudAppCreate.workflow">
            {isLoading
                ? <EmptyStateLoading />
                : <Gallery hasGutter>
                    {selectableWorkflows.map((devWorkflow) => (
                        <Card
                            key={devWorkflow.uid}
                            isSelectableRaised
                            hasSelectableInput
                            isSelected={selectedDevWorkflow == devWorkflow.uid}
                            onClick={() => onSelectDevWorkflow((selectedDevWorkflow == devWorkflow.uid)
                                ? null
                                : devWorkflow.uid)}>
                            <CardHeader>
                                <CardActions>
                                    <Checkbox
                                        isChecked={selectedDevWorkflow == devWorkflow.uid}
                                        onChange={() => onSelectDevWorkflow((selectedDevWorkflow == devWorkflow.uid)
                                            ? null
                                            : devWorkflow.uid)}
                                        id={`check-${devWorkflow.uid}`}
                                        name={`check-${devWorkflow.uid}`} />
                                </CardActions>
                            </CardHeader>
                            <CardTitle>{devWorkflow.name}</CardTitle>
                            <CardBody>{devWorkflow.description}</CardBody>
                        </Card>))}
                </Gallery>}
        </TitledPane>
    )
}

export default WizardStepDevWorkflow
