//@ts-check
import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import { Button, Modal, ModalVariant } from '@patternfly/react-core'

const useModalConfirmDeleteEntity = ({ messageId, onConfirm, isPending }) => {
    const { formatMessage } = useIntl()
    const [state, setState] = useState({ isOpen: false, entity: null })
    const onClose = () => setState({ isOpen: false, entity: null })

    const handleConfirm = () => {
        onConfirm(state.entity).then(() => {
            onClose()
        })
    }

    const modal = (state.entity != null)
        ? <Modal
            isOpen={state.isOpen}
            variant={ModalVariant.small}
            title={formatMessage({ id: `modal.${messageId}.confirmDelete.title` }, state.entity)}
            titleIconVariant="danger"
            showClose={true}
            actions={[
                <Button key="confirm"
                    variant="primary"
                    onClick={handleConfirm}
                    isLoading={isPending}
                    isDisabled={isPending}>
                    Confirm
                </Button>,
                <Button key="cancel" variant="link" onClick={onClose}>
                    Cancel
                </Button>
            ]}
            onClose={onClose}>
            <FormattedMessage id={`modal.${messageId}.confirmDelete.body`} values={state.entity} />
        </Modal>
        : null

    return {
        modal: modal,
        isOpen: state.isOpen,
        openModal: (entity) => {
            setState({ isOpen: true, entity: entity })
        }
    }
}

export default useModalConfirmDeleteEntity
