//@ts-check
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { DataListAction, DataListCell, DataListCheck, DataListControl, DataListItem, DataListItemCells, DataListItemRow, Draggable, Dropdown, DropdownItem, DropdownPosition, DropdownSeparator, KebabToggle, Split, SplitItem, Stack, StackItem, Text, TextContent } from '@patternfly/react-core';

import EditIcon from '@patternfly/react-icons/dist/esm/icons/edit-icon'
import TrashIcon from '@patternfly/react-icons/dist/esm/icons/trash-icon'

import { useDropdownState } from '../../utils/dropdown'

const CloudAppItem = ({ isSelected, toggleItemSelection, cloudApp }) => {
    const { uid, name, description } = cloudApp
    return (
        <Draggable hasNoWrapper>
            <DataListItem aria-labelledby={uid} ref={React.createRef()}>
                <DataListItemRow>
                    <DataListControl>
                        <DataListCheck
                            aria-labelledby={uid}
                            name={uid}
                            otherControls
                            defaultChecked={undefined}
                            isChecked={isSelected}
                            onChange={() => toggleItemSelection(uid)} />
                    </DataListControl>
                    <DataListItemCells dataListCells={[
                        <DataListCell key="name">
                            <TextContent>
                                <Link to={`/cloud-app/${uid}/update`}>
                                    <Text component='h2'>{name}</Text>
                                </Link>
                                <Text component='p'>{description}</Text>
                            </TextContent>
                        </DataListCell>]} />
                    <DataListAction
                        id={`actions-${uid}`}
                        aria-labelledby=""
                        aria-label=""
                        isPlainButtonAction>
                        <DropdownItemAction entity={cloudApp} />
                    </DataListAction>
                </DataListItemRow>
            </DataListItem>
        </Draggable>
    )
}

export default CloudAppItem

const DropdownItemAction = ({ entity }) => {
    const navigate = useNavigate()
    const [isOpen, onToggle, onSelect] = useDropdownState()
    /*
    const {
        openModal: openModalConfirmDelete,
        modal: modalConfirmDelete
    } = useModalDeleteCloudAppStage(entity)
    */
    const openModalConfirmDelete = () => { }
    const modalConfirmDelete = null

    const onClickEdit = (e) => {
        navigate(`/cloud-app/${entity.uid}/update`)
    }

    const dropdownItems = [
        <DropdownItem
            key="edit"
            component="button"
            icon={<EditIcon />}
            onClick={onClickEdit}>
            Edit
        </DropdownItem>,
        <DropdownSeparator key="separator" />,
        <DropdownItem
            key="delete"
            icon={<TrashIcon />}
            onClick={openModalConfirmDelete}>
            Delete
        </DropdownItem>
    ]

    return (
        <React.Fragment>
            <Dropdown
                isPlain
                position={DropdownPosition.right}
                isOpen={isOpen}
                onSelect={onSelect}
                toggle={<KebabToggle onToggle={onToggle} />}
                dropdownItems={dropdownItems} />
            {modalConfirmDelete}
        </React.Fragment>
    )
}