//@ts-check
import { createAction, createReducer } from '@reduxjs/toolkit'

export const selectNotifications = ({ notification }) => notification.notifications

export const selectToasts = ({ notification }) => notification.toasts

export const selectNotificationsUnread = ({ notification }) => {
    return notification.notifications.filter(x => !x.isRead)
}

export const pushNotification = createAction('NOTIFICATION_PUSH',
    ({ uid, variant, messageId, timestamp = Date.now(), displayToast = true, entity = undefined, allowedRoles=undefined }) => ({
        payload: {
            uid,
            variant,
            messageId,
            timestamp,
            displayToast,
            entity,
            allowedRoles
        }
    }))

export const markNotificationRead = createAction('NOTIFICATION_MARK_READ', (uid) => ({ payload: { uid } }))
export const markAllNotificationRead = createAction('NOTIFICATION_MARK_ALL_READ')
export const clearAllNotifications = createAction('NOTIFICATION_CLEAR_ALL')
export const deleteNotification = createAction('NOTIFICATION_DELETE', (uid) => ({ payload: { uid } }))

export const dismissToast = createAction('TOAST_DISMISS', (uid, isUserAction = false) => ({ payload: { uid, isUserAction } }))

const appReducer = createReducer({
    notifications: [],
    toasts: []
}, (builder) => {
    builder
        .addCase(pushNotification, (state, { payload: { uid, variant, messageId, timestamp, displayToast, entity, allowedRoles } }) => {
            state.notifications.unshift({
                uid, variant, messageId, timestamp, isRead: false, entity, allowedRoles
            })
            if (displayToast) {
                state.toasts.unshift({
                    uid, variant, messageId, entity, allowedRoles
                })
            }
        })
        .addCase(markNotificationRead, (state, { payload: { uid } }) => {
            const index = state.notifications.findIndex(x => x.uid === uid)
            if (index != -1) {
                state.notifications[index].isRead = true
            }
        })
        .addCase(markAllNotificationRead, (state, payload) => {
            state.notifications.forEach(x => {
                x.isRead = true
            })
        })
        .addCase(clearAllNotifications, (state, payload) => {
            state.notifications = []
        })
        .addCase(deleteNotification, (state, { payload: { uid } }) => {
            const index = state.notifications.findIndex(x => x.uid === uid)
            if (index != -1) {
                state.notifications.splice(index, 1)
            }
        })
        .addCase(dismissToast, (state, { payload: { uid, isUserAction } }) => {
            const index = state.toasts.findIndex(x => x.uid === uid)
            if (index != -1) {
                state.toasts.splice(index, 1)
            }
            if (isUserAction) {
                const index = state.notifications.findIndex(x => x.uid === uid)
                if (index != -1) {
                    state.notifications[index].isRead = true
                }
            }
        })
        .addDefaultCase((state, action) => { })
})

export default appReducer
