//@ts-check
import React, { useState } from 'react'

import { ActionList, ActionListItem, Button, Form, InputGroup, InputGroupText, InputGroupTextVariant, Modal, ModalVariant, Spinner } from '@patternfly/react-core'

import TimesIcon from '@patternfly/react-icons/dist/esm/icons/times-icon'
import CheckIcon from '@patternfly/react-icons/dist/esm/icons/check-icon'
import PencilIcon from '@patternfly/react-icons/dist/esm/icons/pencil-alt-icon'

const EditableField = ({ value, isPending, isValueChanged, onSave, children }) => {
    const [isEditMode, setEditMode] = useState(false)
    const toggleEditMode = () => setEditMode(!isEditMode)

    const { modal, openModal } = useModalEditLossWarning({ toggleEditMode })

    const handleSave = (e) => {
        e.preventDefault()
        onSave().then(() => {
            toggleEditMode()
        })
    }

    const handleCancel = () => {
        if (isValueChanged) {
            openModal()
        } else {
            toggleEditMode()
        }
    }

    const onKeyUp = (e) => {
        if (e.key == "Escape") {
            handleCancel()
        }
    }

    const btnEdit = <Button
        variant="plain"
        icon={<PencilIcon />}
        onClick={toggleEditMode} />

    return !isEditMode
        ? <React.Fragment>
            {value} {btnEdit}
        </React.Fragment>
        : <Form
            onSubmit={handleSave}
            onKeyUp={onKeyUp}>
            <InputGroup>
                {children}
                {isPending
                    ? <InputGroupText variant={InputGroupTextVariant.plain}>
                        <Spinner isSVG size="md" />
                    </InputGroupText>
                    : <ActionList isIconList>
                        <ActionListItem>
                            <Button
                                variant="plain"
                                onClick={handleCancel}>
                                <TimesIcon />
                            </Button>
                        </ActionListItem>
                        <ActionListItem>
                            <Button
                                variant="plain"
                                onClick={handleSave}>
                                <CheckIcon />
                            </Button>
                        </ActionListItem>
                    </ActionList>}
            </InputGroup>
            {modal}
        </Form>
}

const useModalEditLossWarning = ({ toggleEditMode }) => {
    const [isOpen, setOpen] = useState(false)
    const onClose = () => setOpen(false)

    const onConfirm = () => {
        onClose()
        toggleEditMode()
    }

    const modal = <Modal
        isOpen={isOpen}
        variant={ModalVariant.small}
        title="Unsaved data"
        titleIconVariant="warning"
        showClose={true}
        actions={[
            <Button key="confirm" variant="primary" onClick={onConfirm}>
                Confirm
            </Button>,
            <Button key="cancel" variant="link" onClick={onClose}>
                Cancel
            </Button>
        ]}
        onClose={onClose}>
        Your changes will be lost when you proceed.
    </Modal>

    return {
        modal: modal,
        isOpen: isOpen,
        openModal: () => setOpen(true)
    }
}

export default EditableField
