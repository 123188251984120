//@ts-check

export const loadJavaScript = (src) => new Promise((resolve, reject) => {
    const scriptElement = document.createElement("script")
    scriptElement.src = src
    scriptElement.onload = resolve
    scriptElement.onerror = (e, source, lineno, colno, error) => reject()
    const refs = document.getElementsByTagName("head")[0].childNodes
    insert_node_head(refs, scriptElement)
})

const insert_node_head = (refs, node) => {
    const ref = refs[refs.length - 1]
    ref.parentNode.insertBefore(node, (ref.nextSibling))
}
