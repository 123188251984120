//@ts-check
import React from 'react'
import { FormattedMessage } from 'react-intl'

import EntityEditView from '../../Views/entityUpdate'
import StaticTextValue from '../../FormEditableField/static'
import SwitchValue from '../../FormEditableField/switch'
import EditableTextValue from '../../FormEditableField/input'

import useModalDeleteCloudUser from '../modalDeleteCloudUser'
import useModalCloudUserPasswordReset from '../modalPasswordReset'
import useModalCloudUserSetTemporaryPassword from '../modalSetTemporaryPassword'
import { useResponseNotificationBuilder } from '../../Notification/hooks'
import { useReadByUidQuery, useResetPasswordMutation, useSetTemporaryPasswordMutation, useUpdateByUidMutation, useUpdatePasswordMutation } from '../../Api/cloudUsers'

import PlusIcon from '@patternfly/react-icons/dist/esm/icons/plus-icon'
import KeyIcon from '@patternfly/react-icons/dist/esm/icons/key-icon'
import UserLockIcon from '@patternfly/react-icons/dist/esm/icons/user-lock-icon'
import UserCogIcon from '@patternfly/react-icons/dist/esm/icons/user-cog-icon'
import TrashIcon from '@patternfly/react-icons/dist/esm/icons/trash-icon'

const entityFields = [
    { fieldName: 'firstname', component: EditableTextValue },
    { fieldName: 'lastname', component: EditableTextValue },
    { fieldName: 'username', component: StaticTextValue },
    { fieldName: 'email', component: EditableTextValue },
    { fieldName: 'isVerified', component: SwitchValue }
]

const CloudUserEdit = () => {
    const { modal: ModalDeleteCloudUser, openModal: onOpenModalDeleteCloudUser } = useModalDeleteCloudUser()

    const [cloudUserResetPassword, { isLoading: isPendingCloudUserResetPassword }] = useResetPasswordMutation()
    const [cloudUserSetTemporaryPassword, { isLoading: isPendingSetTemporaryPassword }] = useSetTemporaryPasswordMutation()
    const [cloudUserUpdatePassword, { isLoading: isPendingCloudUserUpdatePassword }] = useUpdatePasswordMutation()

    const responseNotificationBuilderSetTemporaryPassword = useResponseNotificationBuilder({
        messageIdOnSuccess: 'cloudUserTemporaryPasswordSet',
        messageIdOnFailure: 'cloudUserTemporaryPasswordSetForbidden'
    })

    const responseNotificationBuilderUpdatePassword = useResponseNotificationBuilder({
        messageIdOnSuccess: 'cloudUserUpdatePassword',
        messageIdOnFailure: 'cloudUserUpdatePasswordForbidden'
    })

    const onConfirmSetTemporaryPassword = (cloudUser, password) => cloudUserSetTemporaryPassword({ uid: cloudUser.uid, password })
        .then(responseNotificationBuilderSetTemporaryPassword(cloudUser))

    const { modal: ModalCloudUserPasswordReset, openModal: openModalCloudUserPasswordReset } = useModalCloudUserPasswordReset()
    const {
        modal: ModalSetTemporaryPassword,
        openModal: openModalSetTemporaryPassword
    } = useModalCloudUserSetTemporaryPassword({
        onConfirm: onConfirmSetTemporaryPassword,
        isPending: isPendingSetTemporaryPassword
    })

    const toolbarItems = (cloudUser) => [{
        title: <FormattedMessage id="toolbar.cloudUser.btnCreate" />,
        icon: <PlusIcon />,
        to: '/cloud-user/create'
    }, {
        isSeparator: true
    }, {
        title: <FormattedMessage id="toolbar.cloudUser.btnResetPassword" />,
        icon: <KeyIcon />,
        variant: 'secondary',
        onClick: () => {
            cloudUserResetPassword(cloudUser.uid).then((response) => {
                if ('error' in response) {
                } else {
                    openModalCloudUserPasswordReset(response.data)
                }
            })
        },
        isLoading: isPendingCloudUserResetPassword
    }, {
        title: <FormattedMessage id="toolbar.cloudUser.btnSetTemporaryPassword" />,
        icon: <UserLockIcon />,
        variant: 'secondary',
        onClick: () => openModalSetTemporaryPassword(cloudUser),
    }, {
        title: <FormattedMessage id="toolbar.cloudUser.btnUpdatePassword" />,
        icon: <UserCogIcon />,
        variant: 'secondary',
        onClick: () => cloudUserUpdatePassword(cloudUser.uid)
            .then(responseNotificationBuilderUpdatePassword(cloudUser)),
        isLoading: isPendingCloudUserUpdatePassword
    }, {
        isSeparator: true
    }, {
        title: <FormattedMessage id="toolbar.cloudUser.btnDelete" />,
        icon: <TrashIcon />,
        variant: 'secondary',
        isDanger: true,
        onClick: () => onOpenModalDeleteCloudUser(cloudUser)
    }]

    return (
        <React.Fragment>
            <EntityEditView
                route="/cloud-user/:uid/update"
                messageId="cloudUser"
                toolbarItems={toolbarItems}
                entityFields={() => entityFields}
                readEntityQuery={useReadByUidQuery}
                updateByUidMutation={useUpdateByUidMutation} />
            {ModalDeleteCloudUser}
            {ModalCloudUserPasswordReset}
            {ModalSetTemporaryPassword}
        </React.Fragment>
    )
}

export default CloudUserEdit
