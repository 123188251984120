//@ts-check
import React, {  } from 'react'

import { List, ListItem } from '@patternfly/react-core'

const StringList = ({ value }) => (
    <List isPlain isBordered>
        {value.map(item => <ListItem key={item}>{item}</ListItem>)}
    </List>
)

export default StringList
