//@ts-check
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from "react-router-dom"
import { useIntl } from 'react-intl'

import { PageSection, PageSectionVariants, Wizard } from '@patternfly/react-core'

import WizardStepCloudApp from './wizardStepCloudApp'
import WizardStepDevWorkflow from './wizardStepDevWorkflow'
import WizardStepSourceCodeRepository from './wizardStepSourceRepository'
import WizardStepAssignCloudAppStages from './wizardStepAssignCloudAppStages'
import WizardStepCreate from './wizardStepCreate'
import WizardStepCreateFailed from './wizardStepCreateFailed'

import { useCreateMutation } from '../../Api/cloudApp'
import { pushNotification } from '../../state/notification'

import { uniqueId } from '../../utils/uniqueId'
import { useReadAllByTypeQuery } from '../../Api/connector'
import EmptyStateLoading from '../../EmptyState/loading'

const CloudAppCreate = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { formatMessage } = useIntl()
    const [createCloudApp, { isLoading: isPendingCloudApp, isError: isErrorCloudApp }] = useCreateMutation()
    const { data: dockerRegistryConnectors, isLoading: isLoadingDockerRegistryConnectors } = useReadAllByTypeQuery('docker-registry')
    const { data: buildConnectors, isLoading: isLoadingBuildConnectors } = useReadAllByTypeQuery('build')

    const [cloudApp, setCloudApp] = useState({
        name: "",
        description: "",
        urn: "",
        dev_workflow: null,
        app_stages: {},
        app_stages_nodes: {}
    })
    const [selectedDevWorkflow, setSelectedDevWorkflow] = useState(null)

    const onChangeCloudApp = (text, e) => {
        setCloudApp({
            ...cloudApp,
            [e.target.name]: text
        })
    }

    const onSelectScmConnector = (connectorUid, e) => {
        setCloudApp({
            ...cloudApp,
            dev_workflow: {
                ...cloudApp.dev_workflow,
                source_code_repository: {
                    ...cloudApp.dev_workflow.source_code_repository,
                    connector_uid: connectorUid
                }
            }
        })
    }

    const onSelectCloudAppStage = (environment, cloudAppStageUid) => {
        setCloudApp({
            ...cloudApp,
            app_stages: {
                ...cloudApp.app_stages,
                [environment]: cloudAppStageUid
            },
            app_stages_nodes: {
                ...cloudApp.app_stages_nodes,
                [environment]: []
            }
        })
    }

    const onCloudAppStageNodeAppend = (environment, cloudAppStageNodeUid) => {
        setCloudApp({
            ...cloudApp,
            app_stages_nodes: {
                ...cloudApp.app_stages_nodes,
                [environment]: cloudApp.app_stages_nodes[environment].concat(cloudAppStageNodeUid)
            }
        })
    }

    const onCloudAppStageNodeRemove = (environment, cloudAppStageNodeUid) => {
        setCloudApp({
            ...cloudApp,
            app_stages_nodes: {
                ...cloudApp.app_stages_nodes,
                [environment]: cloudApp.app_stages_nodes[environment].filter(x => x != cloudAppStageNodeUid)
            }
        })
    }

    const onNext = (nextStep, prevStep) => {
        const connector_uid = (dockerRegistryConnectors.length == 1)
            ? dockerRegistryConnectors[0].uid
            : null
        const buildConnectorUid = (buildConnectors.length == 1)
            ? buildConnectors[0].uid
            : null
        if (nextStep.id === 'scm') {
            setCloudApp({
                ...cloudApp,
                dev_workflow: {
                    _class: selectedDevWorkflow,
                    docker_registry: {
                        connector_uid_snapshots: connector_uid,
                        connector_uid_release: connector_uid
                    },
                    source_code_repository: {},
                    build: {
                        connector_uid: buildConnectorUid
                    }
                }
            })
        }
        if (nextStep.id == 'stage') {
            let app_stages
            let app_stages_nodes
            if (selectedDevWorkflow == 'DevWorkflowNoRepo' || selectedDevWorkflow == 'DevWorkflowCentral') {
                app_stages = {
                    prod: null,
                    test: null
                }
                app_stages_nodes = {
                    prod: [],
                    test: []
                }
            } else if (selectedDevWorkflow == 'DevWorkflowFeatureBranch') {
                app_stages = {
                    prod: null,
                    test: null,
                    integ: null
                }
                app_stages_nodes = {
                    prod: [],
                    test: [],
                    integ: []
                }
            } else if (selectedDevWorkflow == 'DevWorkflowGitFlow') {
                app_stages = {
                    prod: null,
                    hotfix: null,
                    test: null,
                    integ: null
                }
                app_stages_nodes = {
                    prod: [],
                    hotfix: [],
                    test: [],
                    integ: []
                }
            }
            if (selectedDevWorkflow == 'DevWorkflowNoRepo') {
                setCloudApp({
                    ...cloudApp,
                    dev_workflow: {
                        _class: 'DevWorkflowNoRepo',
                        docker_registry: {
                            connector_uid_snapshots: connector_uid,
                            connector_uid_release: connector_uid
                        },
                        docker_compose_yaml: ''
                    },
                    app_stages: app_stages,
                    app_stages_nodes: app_stages_nodes
                })
            } else {
                setCloudApp({
                    ...cloudApp,
                    app_stages: app_stages,
                    app_stages_nodes: app_stages_nodes
                })
            }
        }
        if (nextStep.id === 'createPending') {
            createCloudApp({ cloudApp })
                .then((response) => {
                    if ('error' in response) {
                        return
                    }
                    navigate(`/cloud-app/${response.data.uid}/update`)
                    dispatch(pushNotification({
                        uid: uniqueId(),
                        variant: 'success',
                        messageId: 'cloudAppStageNodeCreated',
                        entity: response.data
                    }))
                })
        }
    }

    const steps = [{
        id: "details",
        name: formatMessage({ id: 'wizard.cloudAppCreate.nameDetails' }),
        component: <WizardStepCloudApp
            cloudApp={cloudApp}
            onChange={onChangeCloudApp} />,
        enableNext: cloudApp.name != '' && cloudApp.urn != '',
        nextButtonText: formatMessage({ id: 'wizard.cloudAppCreate.btnDetailsNext' })
    }, {
        id: "workflow",
        name: formatMessage({ id: 'wizard.cloudAppCreate.nameWorkflow' }),
        component: <WizardStepDevWorkflow
            selectedDevWorkflow={selectedDevWorkflow}
            onSelectDevWorkflow={setSelectedDevWorkflow} />,
        enableNext: selectedDevWorkflow != null,
        nextButtonText: (selectedDevWorkflow == null)
            ? formatMessage({ id: 'wizard.cloudAppCreate.btnWorkflowNextInvalid' })
            : (selectedDevWorkflow == 'DevWorkflowNoRepo')
                ? formatMessage({ id: 'wizard.cloudAppCreate.btnWorkflowNextCloudAppStage' })
                : formatMessage({ id: 'wizard.cloudAppCreate.btnWorkflowNextSourceCode' })
    },
    ...((selectedDevWorkflow != null && selectedDevWorkflow != 'DevWorkflowNoRepo') ? [{
        id: "scm",
        name: formatMessage({ id: 'wizard.cloudAppCreate.nameScm' }),
        component: cloudApp.dev_workflow != null && <WizardStepSourceCodeRepository
            selectedDevScmConnector={cloudApp.dev_workflow.source_code_repository.connector_uid}
            onSelectScmConnector={onSelectScmConnector} />,
        enableNext: true,
        nextButtonText: formatMessage({ id: 'wizard.cloudAppCreate.btnScmNextCloudAppStage' })
    }] : []),
    ...(cloudApp.dev_workflow && (cloudApp.dev_workflow.docker_registry.connector_uid_snapshots == null
        || cloudApp.dev_workflow.docker_registry.connector_uid_release == null) ? [{
            id: "stage",
            name: formatMessage({ id: 'wizard.cloudAppCreate.nameDockerRegistry' }),
            component: <div>TODO: select docker registry</div>,
            enableNext: true,
            nextButtonText: formatMessage({ id: 'wizard.cloudAppCreate.btnDockerRegistryCreateCloudApp' })
        }] : []),
    ...(selectedDevWorkflow != null ? [{
        id: "stage",
        name: formatMessage({ id: 'wizard.cloudAppCreate.nameStage' }),
        component: <WizardStepAssignCloudAppStages
            cloudApp={cloudApp}
            onSelectCloudAppStage={onSelectCloudAppStage}
            onCloudAppStageNodeAppend={onCloudAppStageNodeAppend}
            onCloudAppStageNodeRemove={onCloudAppStageNodeRemove} />,
        enableNext: true,
        nextButtonText: formatMessage({ id: 'wizard.cloudAppCreate.btnStageCreateCloudApp' })
    }] : []), {
        id: "createPending",
        name: 'Pending',
        component: isErrorCloudApp
            ? <WizardStepCreateFailed />
            : <WizardStepCreate cloudApp={cloudApp} />,
        isFinishedStep: true
    }]

    const title = 'Create CloudApp'
    return (
        <PageSection variant={PageSectionVariants.light} padding={{ default: 'noPadding' }}>
            {(isLoadingDockerRegistryConnectors || isLoadingBuildConnectors)
                ? <EmptyStateLoading />
                : <Wizard
                    navAriaLabel={`${title} steps`}
                    mainAriaLabel={`${title} content`}
                    onClose={() => navigate('/cloud-app')}
                    steps={steps}
                    onNext={onNext} />}
        </PageSection>
    )

}

export default CloudAppCreate