//@ts-check
import React from 'react'

import { Split, SplitItem } from "@patternfly/react-core"

const LabelLocation = ({ location }) => (
    <Split hasGutter>
        <SplitItem>{location.city}</SplitItem>
        <SplitItem><span className={`fi fi-${location.country}`}></span></SplitItem>
    </Split>
)

export default LabelLocation