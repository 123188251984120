//@ts-check
import useModalConfirmDeleteEntity from '../Modal/confirmDeleteEntity'

import { useDeleteByUidMutation } from '../Api/cloudApp'
import { useResponseNotificationBuilder } from '../Notification/hooks'


const useModalDeleteCloudApp = (entity) => {
    const [deleteCloudApp, { isLoading: isPending }] = useDeleteByUidMutation()
    const responseNotificationBuilder = useResponseNotificationBuilder({
        messageIdOnSuccess: 'cloudAppDeleted',
        messageIdOnFailure: 'cloudAppDeleteForbidden',
        navigateTo: '/cloud-app'
    })

    const onConfirm = () => deleteCloudApp(entity.uid)
        .then(responseNotificationBuilder(entity))

    return useModalConfirmDeleteEntity({
        messageId: 'cloudApp',
        onConfirm,
        isPending
    })

}

export default useModalDeleteCloudApp