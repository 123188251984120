//@ts-check
import React from 'react'

import { Switch } from '@patternfly/react-core'

const SwitchValue = ({ fieldName, value, onSave }) => (
    <Switch
        id={fieldName}
        aria-label=""
        isChecked={value}
        onChange={onSave} />
)

export default SwitchValue
