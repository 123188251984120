//@ts-check
//@ts-check
import React, { useState } from 'react'
import { useIntl } from 'react-intl'

import { Button, Modal, ModalVariant, TextContent, Text } from '@patternfly/react-core'

const useModalCloudUserPasswordReset = () => {
    const { formatMessage } = useIntl()
    const [state, setState] = useState({ isOpen: false, entity: null })
    const onClose = () => setState({ isOpen: false, entity: null })

    const modal = (state.entity != null)
        ? <Modal
            isOpen={state.isOpen}
            variant={ModalVariant.small}
            title={formatMessage({ id: `modal.cloudUserPasswordReset.displayTemporaryPassword.title` }, state.entity)}
            titleIconVariant="danger"
            showClose={true}
            actions={[
                <Button key="close"
                    variant="primary"
                    onClick={onClose}>
                    Close
                </Button>
            ]}
            onClose={onClose}>
            <TextContent>
                <Text component="h2">Password reset</Text>
                <Text component="p">Your password was reset to the following value</Text>
                <Text component="p">{state.entity.password}</Text>
                <Text component="p">You will be asked to change your password on your next login. Please note this password now, as for security reason it will not be displayed again.</Text>
            </TextContent>
        </Modal>
        : null

    return {
        modal: modal,
        isOpen: state.isOpen,
        openModal: (entity) => {
            setState({ isOpen: true, entity: entity })
        }
    }
}

export default useModalCloudUserPasswordReset