//@ts-check
import React from 'react'
import { Link } from 'react-router-dom'

import { Title, EmptyState, EmptyStateIcon, Button } from '@patternfly/react-core'

import AddCircleIcon from '@patternfly/react-icons/dist/esm/icons/add-circle-o-icon'

const EmptyStateNoData = ({ createRoute }) => (
    <EmptyState>
        <EmptyStateIcon icon={AddCircleIcon} />
        <Title size="lg" headingLevel="h4">
            No CloudApp-Stage found
        </Title>
        <Link to={createRoute}>
            <Button variant="primary">Create CloudApp-Stage</Button>
        </Link>
    </EmptyState>
)

export default EmptyStateNoData