//@ts-check
import React from 'react'
import { FormattedMessage, IntlProvider, useIntl } from 'react-intl'
import { Link } from 'react-router-dom'

import { Button, Toolbar, ToolbarContent, ToolbarItem } from '@patternfly/react-core'

import TrashIcon from '@patternfly/react-icons/dist/esm/icons/trash-icon'
import SaveIcon from '@patternfly/react-icons/dist/esm/icons/save-icon'
import PlusCircleIcon from '@patternfly/react-icons/dist/js/icons/plus-circle-icon'
import CloudUploadIcon from '@patternfly/react-icons/dist/js/icons/cloud-upload-alt-icon'

import useModalDeleteCloudApp from '../modalDeleteCloudApp'
import useModalServiceRequestGET from '../OpenApi/modalServiceRequestGET'

import { useDeployMutation } from '../../Api/cloudApp'

import { useOpenApiDefinition } from '../../Api/openapi'
import useModalServiceRequestDELETE from '../OpenApi/modalServiceRequestDELETE'
import useModalServiceRequestPOST from '../OpenApi/modalServiceRequestPOST'

const ToolbarEntity = ({ cloudApp, activeTabKey }) => {
    const {
        modal: modalConfirmDelete,
        openModal: openModalConfirmDelete
    } = useModalDeleteCloudApp()

    const { isSuccess: isCloudAppActions } = useOpenApiDefinition(cloudApp)

    return (
        <Toolbar id="toolbar-items">
            <ToolbarContent >
                <ToolbarItem>
                    <Link to="/cloud-app/create">
                        <Button
                            variant="secondary"
                            icon={<PlusCircleIcon />}>
                            Add CloudApp
                        </Button>
                    </Link>
                </ToolbarItem>
                <ToolbarItem variant="separator" />
                {isCloudAppActions &&
                    <ToolbarItemsCloudApp cloudApp={cloudApp} />}
                {isCloudAppActions &&
                    <ToolbarItem variant="separator" />}
                {activeTabKey == 'deploy' && <ToolbarItemsDeploy cloudApp={cloudApp} />}
                {activeTabKey == 'details' && <ToolbarItem>
                    <Button
                        variant="danger"
                        icon={<TrashIcon />}
                        onClick={() => openModalConfirmDelete(cloudApp)}>
                        Delete
                    </Button>
                </ToolbarItem>}
                {activeTabKey == 'sso' && <ToolbarItem>
                    <Button
                        variant="danger"
                        icon={<TrashIcon />}
                        onClick={() => { }}
                        isDisabled>
                        Delete SSO Client
                    </Button>
                </ToolbarItem>}
                {activeTabKey == 'stack' && <ToolbarItem>
                    <Button
                        variant="primary"
                        icon={<SaveIcon />}
                        onClick={() => { }}>
                        Save
                    </Button>
                </ToolbarItem>}
                {modalConfirmDelete}
            </ToolbarContent>
        </Toolbar>
    )
}

export default ToolbarEntity

const ToolbarItemsCloudApp = ({ cloudApp }) => {
    const { locale } = useIntl()
    const { data: openApiDefinition } = useOpenApiDefinition(cloudApp)

    return (
        <IntlProvider
            locale={locale}
            messages={openApiDefinition.i18nMessages[locale]
                ? openApiDefinition.i18nMessages[locale]
                : {}}>
            {openApiDefinition.apiOperations
                .filter(({ httpMethod }) => httpMethod == 'post')
                .map((apiOperation) => (<CloudAppActionPost
                    key={apiOperation.operationId}
                    cloudApp={cloudApp}
                    apiOperation={apiOperation} />))}
            {openApiDefinition.apiOperations
                .filter(({ httpMethod }) => httpMethod == 'get')
                .map((apiOperation) => (<CloudAppActionGet
                    key={apiOperation.operationId}
                    cloudApp={cloudApp}
                    apiOperation={apiOperation} />))}
            {openApiDefinition.apiOperations
                .filter(({ httpMethod }) => httpMethod == 'delete')
                .map((apiOperation) => (<CloudAppActionDelete
                    key={apiOperation.operationId}
                    cloudApp={cloudApp}
                    apiOperation={apiOperation} />))}
        </IntlProvider>
    )
}

const CloudAppActionPost = ({ cloudApp, apiOperation }) => {
    const {
        modal: modalServiceRequestPOST,
        openModal: openModalServiceRequestPOST
    } = useModalServiceRequestPOST({
        endpoint: cloudApp.endpoints.openapi,
        apiOperation
    })

    return (
        <ToolbarItem>
            <Button
                variant="primary"
                icon={apiOperation.icon ? <i className={apiOperation.icon}></i> : undefined}
                onClick={() => openModalServiceRequestPOST()}>
                <FormattedMessage id={`apiOperation.${apiOperation.operationId}.toolbarBtn`} />
            </Button>
            {modalServiceRequestPOST}
        </ToolbarItem>
    )
}

const CloudAppActionGet = ({ cloudApp, apiOperation }) => {
    const {
        modal: modalServiceRequestGET,
        openModal: openModalServiceRequestGET
    } = useModalServiceRequestGET({
        endpoint: cloudApp.endpoints.openapi,
        apiOperation
    })

    return (
        <ToolbarItem>
            <Button
                variant="primary"
                icon={apiOperation.icon ? <i className={apiOperation.icon}></i> : undefined}
                onClick={openModalServiceRequestGET}>
                <FormattedMessage id={`apiOperation.${apiOperation.operationId}.toolbarBtn`} />
            </Button>
            {modalServiceRequestGET}
        </ToolbarItem>
    )
}

const CloudAppActionDelete = ({ cloudApp, apiOperation }) => {
    const {
        modal: modalServiceRequest,
        openModal: openModalServiceRequest
    } = useModalServiceRequestDELETE({ cloudApp, apiOperation })

    return (
        <ToolbarItem>
            <Button
                variant="danger"
                icon={apiOperation.icon ? <i className={apiOperation.icon}></i> : undefined}
                onClick={openModalServiceRequest}>
                <FormattedMessage id={`apiOperation.${apiOperation.operationId}.toolbarBtn`} />
            </Button>
            {modalServiceRequest}
        </ToolbarItem>
    )
}


const ToolbarItemsDeploy = ({ cloudApp }) => {
    return Object.entries(cloudApp.app_stages).map(([environment, appStageUid]) => (
        <ToolbarItem key={environment}>
            <ButtonDeployCloudApp
                cloudAppUid={cloudApp.uid}
                environment={environment} />
        </ToolbarItem>
    ))
}

const ButtonDeployCloudApp = ({ cloudAppUid, environment }) => {
    const [deployCloudApp, { isLoading: isPending, isError }] = useDeployMutation()

    const onClick = () => {
        deployCloudApp({ cloudAppUid, environment })
    }

    return (
        <Button
            variant="primary"
            icon={<CloudUploadIcon />}
            onClick={onClick}
            isLoading={isPending}
            isDisabled={isPending || isError}>
            Deploy {environment}
        </Button>
    )
}
