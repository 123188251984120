//@ts-check
import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { Alert, Button, CardFooter, Checkbox, DescriptionListDescription, DescriptionListGroup, DescriptionListTerm, ExpandableSection, Gallery, Skeleton, Split, SplitItem, Text, TextContent } from '@patternfly/react-core'

import FieldGroupPane from '../../Form/fieldGroupPane'
import EmptyStateLoading from '../../EmptyState/loading'
import { DescriptionListEntity } from '../../DescriptionList/entity'
import CardCloudAppStageNode from '../../CloudAppStage/cardCloudAppStageNode'

import { useReadAllQuery } from '../../Api/cloudAppStageNode'
import { useReadByUidQuery } from '../../Api/cloudAppStage'
import { useAssignEnvironmentStageNodeMutation, useRemoveEnvironmentStageNodeMutation, useSetEnvironmentStageMutation } from '../../Api/cloudApp'

import useModalSelectCloudAppStage from './modalSelectCloudAppStage'

const PaneDeploy = ({ cloudApp }) => (
    <FieldGroupPane messageId="cloudApp.deploy">
        <DescriptionListEntity>
            {Object.entries(cloudApp.app_stages).map(([appStageType, appStageUid]) => (
                <DescriptionListGroup key={appStageType}>
                    <DescriptionListTerm>
                        <FormattedMessage id={`field.cloudAppStage.environment.option.${appStageType}`} />
                    </DescriptionListTerm>
                    <DescriptionListDescription>
                        {(appStageUid == null || appStageUid == '')
                            ? <Alert variant="warning" isInline isPlain title="Stage not assigned" />
                            : <StageDeployConfig
                                cloudAppUid={cloudApp.uid}
                                environment={appStageType}
                                appStageUid={appStageUid}
                                selectedNodes={cloudApp.app_stages_nodes[appStageType]
                                    ? cloudApp.app_stages_nodes[appStageType]
                                    : []} />}
                    </DescriptionListDescription>
                </DescriptionListGroup>))}
        </DescriptionListEntity>
    </FieldGroupPane>
)

export default PaneDeploy

const StageDeployConfig = ({ cloudAppUid, environment, appStageUid, selectedNodes }) => {
    const [isExpanded, setExpanded] = useState(false)

    const { data: cloudAppStageNodes, isLoading } = useReadAllQuery({ cloudAppStageUid: appStageUid })
    const { data: appStage, isLoading: isLoadingAppStage } = useReadByUidQuery(appStageUid)

    const [setEnvironmentStage, { isLoading: isPending }] = useSetEnvironmentStageMutation()

    const onConfirm = (stageUid) => {
        return setEnvironmentStage({ cloudAppUid, environment, stageUid })
    }

    const { modal, openModal } = useModalSelectCloudAppStage({ onConfirm, isPending })

    return (
        <React.Fragment>
            <TextContent>
                <Text component="h3">
                    <Split hasGutter>
                        <SplitItem>{isLoadingAppStage ? <Skeleton /> : appStage && appStage.name}</SplitItem>
                        {!isLoadingAppStage && <SplitItem><Button variant="secondary" onClick={openModal}>Change CloudApp-Stage</Button></SplitItem>}
                    </Split>
                </Text>
            </TextContent>
            {(cloudAppStageNodes && cloudAppStageNodes.length > 0)
                ? <ExpandableSection
                    toggleText={isExpanded ? 'Hide node selection' : 'Show node selection'}
                    onToggle={setExpanded}
                    isExpanded={isExpanded}
                    isIndented>
                    {isLoading
                        ? <EmptyStateLoading />
                        : <Gallery hasGutter>
                            {cloudAppStageNodes.map((node) => (
                                <SelectableCloudAppStageNode
                                    key={node.uid}
                                    cloudAppUid={cloudAppUid}
                                    environment={environment}
                                    node={node}
                                    isSelected={selectedNodes.includes(node.uid)} />))}
                        </Gallery>}
                </ExpandableSection>
                : <Alert variant="warning" isInline isPlain title="No nodes" />}
            {modal}
        </React.Fragment>
    )
}

const SelectableCloudAppStageNode = ({ cloudAppUid, environment, node, isSelected }) => {
    const [assignEnvironmentStageNode] = useAssignEnvironmentStageNodeMutation()
    const [removeEnvironmentStageNode] = useRemoveEnvironmentStageNodeMutation()

    const onChange = () => {
        if (isSelected) {
            removeEnvironmentStageNode({ cloudAppUid, environment, nodeUid: node.uid })
        } else {
            assignEnvironmentStageNode({ cloudAppUid, environment, nodeUid: node.uid })
        }
    }

    return (
        <CardCloudAppStageNode
            node={node}
            isSelected={isSelected}
            cardActions={<Checkbox
                isChecked={isSelected}
                onChange={() => { }}
                id={`check-${node.uid}`}
                name={`check-${node.uid}`} />}
            onChange={onChange}>
            <CardFooter>{node.urn}</CardFooter>
        </CardCloudAppStageNode>
    )
}
