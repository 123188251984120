//@ts-check
import React, { useState } from 'react'

import { TextArea } from '@patternfly/react-core'

import FieldGroupPane from '../../Form/fieldGroupPane'
import EmptyStateLoading from '../../EmptyState/loading'

import { usePutComposeFileMutation } from '../../Api/cloudApp'

const PaneStack = ({ cloudApp }) => (
    <FieldGroupPane messageId="cloudApp.stack">
        <ComposeFileEditor
            uid={cloudApp.uid}
            value={cloudApp.dev_workflow.docker_compose_yaml} />
    </FieldGroupPane>
)

export default PaneStack

const ComposeFileEditor = ({ uid, value }) => {
    const [nextValue, setNextValue] = useState(value)
    const [updateField, { isLoading: isPending }] = usePutComposeFileMutation()

    const onSave = () => {
        if (value != nextValue) {
            updateField({ uid, value: nextValue })
        }
    }

    return isPending
        ? <EmptyStateLoading />
        : <TextArea
            style={{ minHeight: '50vh' }}
            id="compose-file"
            value={nextValue}
            onChange={setNextValue}
            resizeOrientation='vertical'
            onBlur={onSave} />

}