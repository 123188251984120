//@ts-check
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { DataListAction, DataListCell, DataListCheck, DataListControl, DataListItem, DataListItemCells, DataListItemRow, Draggable, Dropdown, DropdownItem, DropdownPosition, DropdownSeparator, KebabToggle, Split, SplitItem, Stack, StackItem, Text, TextContent } from '@patternfly/react-core';

import ServerIcon from '@patternfly/react-icons/dist/esm/icons/server-icon'
import RocketIcon from '@patternfly/react-icons/dist/esm/icons/rocket-icon'
import EditIcon from '@patternfly/react-icons/dist/esm/icons/edit-icon'
import TrashIcon from '@patternfly/react-icons/dist/esm/icons/trash-icon'

import { useDropdownState } from '../../utils/dropdown'
import useModalDeleteCloudAppStage from '../modalDeleteCloudAppStage'
import { useHasRole } from '../../Security/requiresRole'
import { CLOUDAPP_STAGE_DELETE, CLOUDAPP_STAGE_UPDATE } from '../../App/securityRoles'

const CloudAppStageItem = ({ isSelected, toggleItemSelection, cloudAppStage }) => {
    const isCloudAppStageUpdateRole = useHasRole(CLOUDAPP_STAGE_UPDATE)
    const isCloudAppStageDeleteRole = useHasRole(CLOUDAPP_STAGE_DELETE)
    const { uid, name, description, environment, nodes } = cloudAppStage
    return (
        <Draggable hasNoWrapper>
            <DataListItem aria-labelledby={uid} ref={React.createRef()}>
                <DataListItemRow>
                    {(isCloudAppStageUpdateRole || isCloudAppStageDeleteRole) &&
                        <DataListControl>
                            <DataListCheck
                                aria-labelledby={uid}
                                name={uid}
                                otherControls
                                defaultChecked={undefined}
                                isChecked={isSelected}
                                onChange={() => toggleItemSelection(uid)} />
                        </DataListControl>}
                    <DataListItemCells dataListCells={[
                        <DataListCell key="name">
                            <Stack hasGutter>
                                <StackItem>
                                    <TextContent>
                                        <Link to={`/cloud-app-stage/${uid}/update`}>
                                            <Text component='h2'>
                                                <SplitItem>
                                                    <Split hasGutter>
                                                        <SplitItem>{name}</SplitItem>
                                                        <SplitItem>&mdash;</SplitItem>
                                                        <SplitItem>{environment}</SplitItem>
                                                    </Split>
                                                </SplitItem>
                                            </Text>
                                        </Link>
                                        <Text component='p'>{description}</Text>
                                    </TextContent>
                                </StackItem>
                                <StackItem>
                                    <Split hasGutter>
                                        <SplitItem>
                                            <Split hasGutter>
                                                <SplitItem><ServerIcon /></SplitItem>
                                                <SplitItem>{nodes.length} Node</SplitItem>
                                            </Split>
                                        </SplitItem>
                                        <SplitItem>
                                            <Split hasGutter>
                                                <SplitItem><RocketIcon /></SplitItem>
                                                <SplitItem>3 CloudApps</SplitItem>
                                            </Split>
                                        </SplitItem>
                                    </Split>
                                </StackItem>
                            </Stack>
                        </DataListCell>]} />
                    {(isCloudAppStageUpdateRole || isCloudAppStageDeleteRole) &&
                        <DataListAction
                            id={`actions-${uid}`}
                            aria-labelledby=""
                            aria-label=""
                            isPlainButtonAction>
                            <DropdownItemAction entity={cloudAppStage} />
                        </DataListAction>}
                </DataListItemRow>
            </DataListItem>
        </Draggable>
    )
}

// <DataListDragButton />

export default CloudAppStageItem

const DropdownItemAction = ({ entity }) => {
    const navigate = useNavigate()
    const [isOpen, onToggle, onSelect] = useDropdownState()
    const {
        openModal: openModalConfirmDelete,
        modal: modalConfirmDelete
    } = useModalDeleteCloudAppStage()

    const onClickEdit = (e) => {
        navigate(`/cloud-app-stage/${entity.uid}/update`)
    }

    const dropdownItems = [
        <DropdownItem
            key="edit"
            component="button"
            icon={<EditIcon />}
            onClick={onClickEdit}>
            Edit
        </DropdownItem>,
        <DropdownSeparator key="separator" />,
        <DropdownItem
            key="delete"
            icon={<TrashIcon />}
            onClick={() => openModalConfirmDelete(entity)}>
            Delete
        </DropdownItem>
    ]

    return (
        <React.Fragment>
            <Dropdown
                isPlain
                position={DropdownPosition.right}
                isOpen={isOpen}
                onSelect={onSelect}
                toggle={<KebabToggle onToggle={onToggle} />}
                dropdownItems={dropdownItems} />
            {modalConfirmDelete}
        </React.Fragment>
    )
}