//@ts-check
import React from 'react'
import { EmptyState, EmptyStateBody, EmptyStateIcon, Progress, ProgressMeasureLocation, ProgressSize, Spinner, Text, TextContent, TextVariants } from '@patternfly/react-core'

const WizardStepCreate = ({ cloudAppStage, isPendingCloudAppStage, isPendingCloudAppStageNode }) => {
    const value = isPendingCloudAppStage
        ? 0
        : isPendingCloudAppStageNode
            ? 33
            : 100
    const statusText = isPendingCloudAppStage
        ? 'Creating CloudApp-Stage'
        : isPendingCloudAppStageNode
            ? 'Creating CloudApp-Stage Node-1'
            : 'done'
    return (
        <EmptyState>
            <EmptyStateIcon variant="container" component={Spinner} />
            <EmptyStateBody>
                <Progress
                    aria-label={`Progress 100`}
                    value={value}
                    size={ProgressSize.lg}
                    measureLocation={ProgressMeasureLocation.top}
                    variant={value == 100 ? 'success' : undefined}
                    title={`CloudAppStage ${cloudAppStage.name} wird gerade erstellt`}
                    label={statusText} />
                <TextContent>
                    <Text component={TextVariants.p}>Sobald die CloudAppStage <b>{cloudAppStage.name}</b> fertig für Sie eingerichtet ist, werden Sie automatisch benachrichtigt.</Text>
                    <Text component={TextVariants.p}>Alternativ können Sie auch einfach warten und werden automatisch weitergeleitet.</Text>
                </TextContent>
            </EmptyStateBody>
        </EmptyState>)

}

export default WizardStepCreate