//@ts-check
import React from 'react'
import { useSelector } from 'react-redux'

import { Alert } from '@patternfly/react-core'

import { selectUserRoles } from '../state/app'

const RequiresRole = ({ role, children, displayAlert = true }) => {
    return useHasRole(role)
        ? children
        : displayAlert
            ? <AlertRequireRole role={role} />
            : null
}

export default RequiresRole

export const useHasRole = (role = undefined) => {
    const roles = useSelector(selectUserRoles)
    return (role == undefined)
        ? (role) => roles.includes(role)
        : roles.includes(role)
}

export const AlertRequireRole = ({ role }) => (
    <Alert variant="warning" title="Access denied">
        You need the <b>{role}</b> to access this resource.
    </Alert>
)