//@ts-check
import React, { useEffect, useState } from 'react'

import { SimpleList, SimpleListItem, TextInput } from '@patternfly/react-core'

import EmptyStateLoading from '../../EmptyState/loading'
import ModalServiceRequest from './modalServiceRequest'

import { useOpenApiDefinition } from '../../Api/openapi'
import useRestApiClient from './apiClient'
import useEntityState from '../../Form/state'

const useModalServiceRequestDELETE = ({ cloudApp, apiOperation }) => {
    const [state, setState] = useState({ isOpen: false })
    const onClose = () => setState({ isOpen: false })

    const requiredPathParams = apiOperation.pathParams.filter(x => x.isRequired)

    const paramsFields = requiredPathParams.map(({ name, isRequired }) => ({
        name: name,
        component: TextInput,
        type: 'text',
        isRequired: isRequired
    }))

    return {
        modal: state.isOpen &&
            <ModalComponent
                onClose={onClose}
                cloudApp={cloudApp}
                apiOperation={apiOperation}
                paramsFields={paramsFields}
                requiredPathParams={requiredPathParams} />,
        isOpen: state.isOpen,
        openModal: () => setState({ isOpen: true })
    }
}

export default useModalServiceRequestDELETE

const ModalComponent = ({ cloudApp, apiOperation, paramsFields, requiredPathParams, onClose }) => {
    const { entity: paramsEntity, onChange: onChangeParamsEntity } = useEntityState(paramsFields)

    const [serviceRequest, { isLoading: isPending }] = useRestApiClient(cloudApp.endpoints.openapi, apiOperation)

    const allRequiredPathParamsSet = requiredPathParams
        .map((pathParam) => (pathParam.name in paramsEntity)
            && (paramsEntity[pathParam.name] != null)
            && (paramsEntity[pathParam.name] != ''))
        .filter(isSet => !isSet)
        .length == 0

    const handleConfirm = () => {
        serviceRequest({ paramsEntity }).then(() => {
            onClose()
        })
    }

    return (
        <ModalServiceRequest
            isOpen={true}
            apiOperation={apiOperation}
            isServiceRequestPending={isPending}
            isReadyToSubmit={!isPending && allRequiredPathParamsSet}
            onConfirm={handleConfirm}
            onClose={onClose}>
            {requiredPathParams.map(pathParam => (
                <PathParamSource
                    key={pathParam.name}
                    cloudApp={cloudApp}
                    pathParam={pathParam}
                    currentValue={paramsEntity[pathParam.name]}
                    onChange={onChangeParamsEntity} />
            ))}
        </ModalServiceRequest>
    )
}

const PathParamSource = ({ cloudApp, pathParam, currentValue, onChange }) => {
    const { data: openApiDefinition } = useOpenApiDefinition(cloudApp)

    const valueSourceApiOperation = findApiOperationByOperationId(openApiDefinition, pathParam.valueSource.operationId)

    const [serviceRequest, { data, isUninitialized, isLoading }] = useRestApiClient(cloudApp.endpoints.openapi, valueSourceApiOperation)

    useEffect(() => {
        serviceRequest()
    }, [])

    return isUninitialized || isLoading
        ? <EmptyStateLoading />
        : <SelectableStringList
            value={data}
            currentValue={currentValue}
            onChange={(item) => onChange(item, { target: { name: pathParam.name } })} />
}

const findApiOperationByOperationId = (openApiDefinition, operationId) => {
    for (let apiOperation of openApiDefinition.apiOperations) {
        if (apiOperation.operationId == operationId) {
            return apiOperation
        }
    }
    return null
}

const SelectableStringList = ({ value, currentValue, onChange }) => (
    <SimpleList>
        {value.map((item) => (
            <SimpleListItem
                key={item}
                isActive={item == currentValue}
                onClick={() => onChange(item)}>
                {item}
            </SimpleListItem>))}
    </SimpleList>
)