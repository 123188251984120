//@ts-check
import { loadJavaScript } from '../utils'

class KeycloakClientAdapter {

    constructor() {
        this.keycloakClient = null
    }

    init(keycloakUrl) {
        return loadJavaScript(`${keycloakUrl}/js/keycloak.js`)
            .then(() => {
                this.keycloakClient = Keycloak({
                    url: keycloakUrl,
                    realm: 'ITBox365',
                    clientId: 'itbox365-console'
                })
                return this.keycloakClient
                    .init({
                        promiseType: 'native',
                        onLoad: 'check-sso',
                        silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`,
                        enableLogging: true
                    })
                    .then((authenticated) => authenticated
                        ? this.keycloakClient.loadUserInfo()
                            .then((userInfo) => ({ userInfo, keycloakClient: this.keycloakClient }))
                        : this.keycloakClient.login()
                    )
            })
    }

}

export default new KeycloakClientAdapter()
