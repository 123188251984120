//@ts-check
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from "react-router-dom"

import { PageSection, PageSectionVariants, Wizard } from '@patternfly/react-core'

import WizardStepCloudProvider from './wizardStepCloudProvider'
import WizardStepCloudAppStage from './wizardStepCloudAppStage'
import WizardStepCloudAppStageNode from './wizardStepCloudAppStageNode'
import WizardStepReview from './wizardStepReview'
import WizardStepCreate from './wizardStepCreate'
import WizardStepCreateFailed from './wizardStepCreateFailed'
import WizardStepAdminUser from './wizardStepAdminUser'

import { useCreateMutation as useCreateCloudAppStageMutation } from '../../Api/cloudAppStage'
import { useCreateMutation as useCreateCloudAppStageNodeMutation } from '../../Api/cloudAppStageNode'
import { pushNotification } from '../../state/notification'

import { uniqueId } from '../../utils/uniqueId'

const WizardCloudAppStageCreate = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [createCloudAppStage, { isLoading: isPendingCloudAppStage, isError: isErrorCloudAppStage }] = useCreateCloudAppStageMutation()
    const [createCloudAppStageNode, { isLoading: isPendingCloudAppStageNode, isError: isErrorCloudAppStageNode }] = useCreateCloudAppStageNodeMutation()

    const [state, setState] = useState({
        cloudProviderUid: '',
        cloudAppStage: {
            name: "",
            description: "",
            environment: "",
            urn: ""
        },
        cloudAppStageNode: {
            name: "",
            description: "",
            urn: "",
            location: "",
            server: "",
            os_image: ""
        },
        adminUser: {
            password: '',
            pubkey: ''
        },
        acceptTermsAndConditions: false
    })

    const onSelectProvider = (uid) => {
        setState({ ...state, cloudProviderUid: uid })
    }

    const onChangeCloudAppStage = (text, e) => {
        setState({
            ...state,
            cloudAppStage: {
                ...state.cloudAppStage,
                [e.target.name]: text
            }
        })
    }

    const onChangeCloudAppStageNode = (text, e) => {
        setState({
            ...state,
            cloudAppStageNode: {
                ...state.cloudAppStageNode,
                [e.target.name]: text
            }
        })
    }

    const onChangeAdminUser = (text, e) => {
        setState({
            ...state,
            adminUser: {
                ...state.adminUser,
                [e.target.name]: text
            }
        })
    }

    const onToggleTermsAndConditions = () => {
        setState({ ...state, acceptTermsAndConditions: !state.acceptTermsAndConditions })
    }

    const onNext = (nextStep, prevStep) => {
        if (nextStep.id === 'createPending') {
            createCloudAppStage({ ...state.cloudAppStage, cloudProviderUid: state.cloudProviderUid })
                .then((response) => {
                    if ('error' in response) {
                        return
                    }
                    const cloudAppStageUid = response.data.uid
                    createCloudAppStageNode({
                        cloudAppStageUid,
                        cloudProviderUid: state.cloudProviderUid,
                        ...state.cloudAppStageNode,
                        password: state.adminUser.password,
                        pubkey: state.adminUser.pubkey
                    }).then((response) => {
                        if ('error' in response) {
                            return
                        }
                        navigate(`/cloud-app-stage/${cloudAppStageUid}/update`)
                        dispatch(pushNotification({
                            uid: uniqueId(),
                            variant: 'success',
                            messageId: 'cloudAppStageNodeCreated',
                            entity: response.data
                        }))
                    })
                })
        }
    }

    const steps = [{
        id: "cloudProvider",
        name: 'Select Cloud Provider',
        component: <WizardStepCloudProvider
            selectedProviderUid={state.cloudProviderUid}
            onSelectProvider={onSelectProvider} />,
        enableNext: state.cloudProviderUid != null
    }, {
        id: "details",
        name: 'CloudApp-Stage',
        component: <WizardStepCloudAppStage
            cloudAppStage={state.cloudAppStage}
            onChange={onChangeCloudAppStage} />,
        enableNext: state.cloudAppStage.name != ''
            && state.cloudAppStage.environment != ''
            && state.cloudAppStage.urn != ''
    }, {
        id: "cloudAppStageNode",
        name: 'Add Node',
        component: <WizardStepCloudAppStageNode
            cloudProviderUid={state.cloudProviderUid}
            cloudAppStageNode={state.cloudAppStageNode}
            onChange={onChangeCloudAppStageNode} />,
        enableNext: state.cloudAppStageNode.name != ''
            && state.cloudAppStageNode.urn != ''
            && state.cloudAppStage.location != ''
            && state.cloudAppStage.server != ''
            && state.cloudAppStage.os_image != ''
    }, {
        id: "adminUser",
        name: 'Admin user',
        component: <WizardStepAdminUser
            adminUser={state.adminUser}
            onChange={onChangeAdminUser} />,
        enableNext: state.adminUser.password != ''
            && state.adminUser.pubkey != ''
    }, {
        id: "review",
        name: 'Review',
        component: <WizardStepReview
            cloudProviderUid={state.cloudProviderUid}
            cloudAppStageNode={state.cloudAppStageNode}
            acceptTermsAndConditions={state.acceptTermsAndConditions}
            onToggleTermsAndConditions={onToggleTermsAndConditions} />,
        enableNext: state.acceptTermsAndConditions,
        nextButtonText: 'Order server now'
    }, {
        id: "createPending",
        name: 'Pending',
        component: (isErrorCloudAppStage || isErrorCloudAppStageNode)
            ? <WizardStepCreateFailed />
            : <WizardStepCreate
                cloudAppStage={state.cloudAppStage}
                isPendingCloudAppStage={isPendingCloudAppStage}
                isPendingCloudAppStageNode={isPendingCloudAppStageNode} />,
        isFinishedStep: true
    }]

    const title = 'Basic wizard'
    return (
        <PageSection variant={PageSectionVariants.light} padding={{ default: 'noPadding' }}>
            <Wizard
                navAriaLabel={`${title} steps`}
                mainAriaLabel={`${title} content`}
                steps={steps}
                onNext={onNext} />
        </PageSection>
    )

}

export default WizardCloudAppStageCreate