//@ts-check
import { createApi } from '@reduxjs/toolkit/query/react'
import { dynamicBaseQuery } from "./common"

export const cloudProviderApi = createApi({
    reducerPath: 'cloudProviderApi',
    baseQuery: dynamicBaseQuery('cloudProviderApi'),
    tagTypes: ['CloudProviders'],
    endpoints: (builder) => ({
        readAll: builder.query({
            query: () => ({
                url: `/cloud-provider`
            })
        }),
        readAllLocations: builder.query({
            query: (uid) => ({
                url: `/cloud-provider/${uid}/locations`
            })
        }),
        readAllServers: builder.query({
            query: (uid) => ({
                url: `/cloud-provider/${uid}/servers`
            })
        }),
        readAllOsImages: builder.query({
            query: (uid) => ({
                url: `/cloud-provider/${uid}/os-images`
            })
        }),
    })
})

export const {
    useReadAllQuery,
    useReadAllLocationsQuery,
    useReadAllServersQuery,
    useReadAllOsImagesQuery,
    endpoints
} = cloudProviderApi
