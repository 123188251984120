//@ts-check
import { createApi } from '@reduxjs/toolkit/query/react'
import { dynamicBaseQuery } from "./common"

export const cloudUsersApi = createApi({
    reducerPath: 'cloudUsersApi',
    baseQuery: dynamicBaseQuery('cloudUsersApi'),
    endpoints: (builder) => ({
        create: builder.mutation({
            query: ({ cloudUser }) => ({
                url: `/cloud-user`,
                method: 'POST',
                body: cloudUser
            }),
            invalidatesTags: [{ type: 'CloudUser', id: 'LIST' }]
        }),
        resetPassword: builder.mutation({
            query: (uid) => ({
                url: `/cloud-user/${uid}/reset-password`,
                method: 'POST'
            }),
        }),
        setTemporaryPassword: builder.mutation({
            query: ({ uid, password }) => ({
                url: `/cloud-user/${uid}/temporary-password`,
                method: 'POST',
                body: { password: password }
            }),
        }),
        updatePassword: builder.mutation({
            query: (uid) => ({
                url: `/cloud-user/${uid}/update-password`,
                method: 'POST'
            }),
        }),
        readAll: builder.query({
            query: () => ({
                url: `/cloud-user`
            }),
            providesTags: (result) => result
                ? [...result.map(({ uid }) => ({ type: 'CloudUser', id: uid })), { type: 'CloudUser', id: 'LIST' },]
                : [{ type: 'CloudUser', id: 'LIST' }],
        }),
        readByUid: builder.query({
            query: (uid) => ({
                url: `/cloud-user/${uid}`
            }),
            providesTags: (result, error, id) => [{ type: 'CloudUser', id }],
        }),
        updateByUid: builder.mutation({
            query: ({ uid, fieldName, value }) => ({
                url: `/cloud-user/${uid}/${fieldName}`,
                method: 'PUT',
                body: { value: value }
            }),
            async onQueryStarted({ uid, ...patch }, { dispatch, queryFulfilled }) {
                try {
                    const { data: cloudUser } = await queryFulfilled
                    dispatch(cloudUsersApi.util.updateQueryData('readByUid', uid, (draft) => {
                        return cloudUser
                    }))
                } catch { }
            },
        }),
        deleteByUid: builder.mutation({
            query: (uid) => ({
                url: `/cloud-user/${uid}`,
                method: 'DELETE'
            }),
            invalidatesTags: (result, error, { uid }) => [{ type: 'CloudUser', uid }],
        }),
    })
})

export const {
    useCreateMutation,
    useResetPasswordMutation,
    useSetTemporaryPasswordMutation,
    useUpdatePasswordMutation,
    useReadAllQuery,
    useLazyReadAllQuery,
    useReadByUidQuery,
    useUpdateByUidMutation,
    useDeleteByUidMutation,
    endpoints
} = cloudUsersApi
