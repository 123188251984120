//@ts-check
import React from 'react'

import { Skeleton } from '@patternfly/react-core'

import { useReadMetaByUidQuery as useReadConnectorMetaByUidQuery } from '../Api/connector'

const ConnectorIdentifier = ({ value }) => {
    const { data: meta, isLoading } = useReadConnectorMetaByUidQuery(value)

    return (isLoading || meta == null)
        ? <Skeleton />
        : <span>{meta.name}</span>
}

export default ConnectorIdentifier
