//@ts-check
import React from 'react'

import { Card, CardActions, CardBody, CardHeader, CardHeaderMain, CardTitle, Checkbox, Gallery } from "@patternfly/react-core"

import TitledPane from '../../Wizard/titledPane'
import EmptyStateLoading from '../../EmptyState/loading'

import { useReadAllByTypeQuery } from '../../Api/connector'

const WizardStepSourceCodeRepository = ({ selectedDevScmConnector, onSelectScmConnector }) => {
    const { data: scmProviders, isLoading } = useReadAllByTypeQuery('source-code-repository')
    return (
        <TitledPane messageId="cloudAppCreate.scm">
            {isLoading
                ? <EmptyStateLoading />
                : <Gallery hasGutter>
                    {scmProviders.map((repository) => (
                        <Card
                            key={repository.uid}
                            isSelectableRaised
                            hasSelectableInput
                            isSelected={selectedDevScmConnector == repository.uid}
                            onClick={() => onSelectScmConnector((selectedDevScmConnector == repository.uid)
                                ? null
                                : repository.uid)}>
                            <CardHeader>
                                <CardHeaderMain>
                                    <i className={repository.icon}></i>
                                </CardHeaderMain>
                                <CardActions>
                                    <Checkbox
                                        isChecked={selectedDevScmConnector == repository.uid}
                                        onChange={() => onSelectScmConnector((selectedDevScmConnector == repository.uid)
                                            ? null
                                            : repository.uid)}
                                        id={`check-${repository.uid}`}
                                        name={`check-${repository.uid}`} />
                                </CardActions>
                            </CardHeader>
                            <CardTitle>{repository.name}</CardTitle>
                            <CardBody>{repository.description}</CardBody>
                        </Card>))}
                </Gallery>}
        </TitledPane>
    )
}

export default WizardStepSourceCodeRepository