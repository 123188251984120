//@ts-check
import { createApi } from '@reduxjs/toolkit/query/react'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { dynamicBaseQuery } from "./common"

export const openapiApi = createApi({
    reducerPath: 'openApi',
    baseQuery: dynamicBaseQuery({ baseUrl: '/' }),
    endpoints: () => ({}),
})

export const useOpenApiDefinition = (cloudApp) => {
    const { getState } = useStore()
    if (!(cloudApp.urn in openapiApi.endpoints)) {
        return { data: null, isUninitialized: true, isSuccess: false }
    }
    return openapiApi.endpoints[cloudApp.urn].select()(getState())
}

export const useReadOpenApiDefinition = ({ cloudApp }) => {
    const dispatch = useDispatch()
    const [state, setState] = useState({ data: null, isUninitialized: true, isLoading: false, isError: false, error: null })
    const stateRef = useRef()
    stateRef.current = state

    useEffect(() => {
        if (cloudApp == null || cloudApp.endpoints == null || cloudApp.endpoints.openapi == '') {
            return
        }
        setState({ ...stateRef.current, isUninitialized: false, isLoading: true })
        openapiApi.injectEndpoints({
            endpoints: (build) => ({
                [cloudApp.urn]: build.query({ query: () => cloudApp.endpoints.openapi }),
            }),
            overrideExisting: false
        })
        const onResponse = (response) => {
            setState({ ...stateRef.current, data: response.data, isLoading: response.isLoading })
        }
        const result = dispatch(openapiApi.endpoints[cloudApp.urn].initiate()).then(onResponse)
        return result.unsubscribe
    }, [cloudApp])

    return state
}