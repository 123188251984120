//@ts-check
import React from 'react'
import { Link } from 'react-router-dom'

import { Button, Dropdown, DropdownItem, DropdownToggle, DropdownToggleCheckbox, Pagination, SearchInput, Select, SelectVariant, Skeleton, Toolbar, ToolbarContent, ToolbarFilter, ToolbarGroup, ToolbarItem, ToolbarToggleGroup } from '@patternfly/react-core';

import FilterIcon from '@patternfly/react-icons/dist/esm/icons/filter-icon'
import AddIcon from '@patternfly/react-icons/dist/esm/icons/add-circle-o-icon'
import EditIcon from '@patternfly/react-icons/dist/esm/icons/edit-icon'
import TrashIcon from '@patternfly/react-icons/dist/esm/icons/trash-icon'
import CheckIcon from '@patternfly/react-icons/dist/esm/icons/check-icon'

import { useDropdownState } from '../../utils/dropdown'
import { endpoints } from '../../Api/cloudApp'
import useModalDeleteCloudApp from '../modalDeleteCloudApp'

const ListToolbar = ({ isLoading, selectedItems, toggleItemSelection, toggleAllItemSelection }) => (
    <Toolbar id="inline-modifier-data-toolbar" usePageInsets>
        {isLoading
            ? <Skeleton fontSize="3xl" />
            : <ToolbarContent>
                <ToolbarItem variant="bulk-select">
                    <DropdownBulkSelection
                        selectedItems={selectedItems}
                        toggleItemSelection={toggleItemSelection}
                        toggleAllItemSelection={toggleAllItemSelection} />
                </ToolbarItem>
                <ToolbarItem variant="separator" />
                <ToolbarGroup variant="button-group">
                    <ToolbarItem>
                        <Link to="/cloud-app/create">
                            <Button variant="primary" icon={<AddIcon />}>
                                New
                            </Button>
                        </Link>
                    </ToolbarItem>
                </ToolbarGroup>
                {selectedItems.length > 0 && <ToolbarItem variant="separator" />}
                {selectedItems.length > 0 &&
                    <ToolbarGroup variant="icon-button-group">
                        {selectedItems.length == 1 && <ToolbarItem>
                            <Link to={`/cloud-app/${selectedItems[0]}/update`}>
                                <Button variant="plain" aria-label="edit">
                                    <EditIcon />
                                </Button>
                            </Link>
                        </ToolbarItem>}
                        <ToolbarItem>
                            <ButtonDeleteCloudApp cloudApp={selectedItems[0]} />
                        </ToolbarItem>
                    </ToolbarGroup>}
            </ToolbarContent>}
    </Toolbar>
)

export default ListToolbar

const DropdownBulkSelection = ({ selectedItems, toggleItemSelection, toggleAllItemSelection }) => {
    const { data: cloudApps } = endpoints.readAll.useQueryState()
    const [isOpen, onToggle, onSelect] = useDropdownState()

    return (
        <Dropdown
            onSelect={onSelect}
            toggle={
                <DropdownToggle
                    splitButtonItems={[
                        <DropdownToggleCheckbox
                            key="split-checkbox"
                            id="select-all"
                            aria-label="Select all"
                            onClick={toggleAllItemSelection}
                            isChecked={(selectedItems.length == cloudApps.length)
                                ? true
                                : (selectedItems.length == 0)
                                    ? false
                                    : null}>
                            {selectedItems.length > 0 && selectedItems.length < cloudApps.length &&
                                <React.Fragment>{selectedItems.length} selected</React.Fragment>}
                        </DropdownToggleCheckbox>]}
                    onToggle={onToggle} />}
            isOpen={isOpen}
            dropdownItems={cloudApps.map((cloudAppStage) => (
                <DropdownItem
                    key={cloudAppStage.uid}
                    component="button"
                    icon={selectedItems.indexOf(cloudAppStage.uid) != -1 && <CheckIcon />}
                    onClick={() => toggleItemSelection(cloudAppStage.uid)}>
                    {cloudAppStage.name} {cloudAppStage.environment}
                </DropdownItem>
            ))} />
    )
}

const ButtonDeleteCloudApp = ({ cloudApp }) => {
    const { modal, openModal } = useModalDeleteCloudApp()

    return (
        <Button
            variant="plain"
            onClick={()=>openModal(cloudApp)}>
            <TrashIcon />
            {modal}
        </Button>
    )
}