//@ts-check
import React, { useState } from 'react'

import { Button, EmptyState, EmptyStateBody, EmptyStateIcon, Split, SplitItem, Title } from '@patternfly/react-core'

import FieldGroupPane from '../../Form/fieldGroupPane'
import DescriptionListEntityFields from '../../DescriptionList/entity'
import StaticTextValue from '../../FormEditableField/static'
import SwitchValue from '../../FormEditableField/switch'
import EditableTextValue from '../../FormEditableField/input'

import { useUpdateByUidMutation } from '../../Api/cloudApp'

import SignInIcon from '@patternfly/react-icons/dist/js/icons/sign-in-alt-icon'

const PaneSingleSignOn = ({ cloudApp }) => (
    <FieldGroupPane messageId="cloudApp.sso">
        {cloudApp.idp_client == null
            ? <EmptyStateNoSsoConfigured />
            : cloudApp.idp_client._class == 'OpenIdConnectClient'
                ? <OpenIdConnectClientConfig idpClient={cloudApp.idp_client} />
                : cloudApp.idp_client._class == 'Saml2Client'
                    ? <SamlClientConfig idpClient={cloudApp.idp_client} />
                    : <div>Error</div>}
    </FieldGroupPane>
)

export default PaneSingleSignOn

const EmptyStateNoSsoConfigured = () => (
    <EmptyState>
        <EmptyStateIcon icon={SignInIcon} />
        <Title size="lg" headingLevel="h4">
            Single-Sign-On not configured
        </Title>
        <EmptyStateBody>
            <Split hasGutter>
                <SplitItem>
                    <Button variant="primary">Setup openID connect</Button>
                </SplitItem>
                <SplitItem>
                    <Button variant="secondary">Setup SAML</Button>
                </SplitItem>
            </Split>
        </EmptyStateBody>
    </EmptyState>
)

const entityFieldsOpenIdConnectClient = [
    { fieldName: 'roles', component: StaticTextValue },
    { fieldName: 'accessType', component: StaticTextValue },
    { fieldName: 'protocolMappers', component: () => <div>TODO: protocolMappers</div> },
    { fieldName: 'authorization_enabled', component: SwitchValue }
]

const OpenIdConnectClientConfig = ({ idpClient }) => (
    <DescriptionListEntityFields
        fields={entityFieldsOpenIdConnectClient}
        messageId="cloudApp.idpClient"
        entity={idpClient}>
        {(props) => <FieldWrapper {...props} />}
    </DescriptionListEntityFields>
)

const entityFieldsSamlClient = [
    { fieldName: 'roles', component: StaticTextValue },
    { fieldName: 'assertionConsumerUrlPost', component: StaticTextValue },
    { fieldName: 'singleLogoutUrlRedirect', component: StaticTextValue },
    { fieldName: 'idpEntityId', component: StaticTextValue },
    { fieldName: 'protocolMappers', component: () => <div>TODO: protocolMappers</div> }
]

const SamlClientConfig = ({ idpClient }) => (
    <DescriptionListEntityFields
        fields={entityFieldsSamlClient}
        messageId="cloudApp.idpClient"
        entity={idpClient}>
        {(props) => <FieldWrapper {...props} />}
    </DescriptionListEntityFields>
)

const FieldWrapper = ({ component, uid, fieldName, value, ...args }) => {
    const [nextValue, setNextValue] = useState(value)
    const isPending = false
    //const [updateField, { isLoading: isPending }] = useUpdateByUidMutation()

    const onSave = () => {
        //return updateField({ uid, fieldName, value: nextValue })
    }

    return React.createElement(component, {
        uid,
        fieldName,
        value,
        nextValue,
        setNextValue,
        onSave,
        isPending,
        ...args
    })
}