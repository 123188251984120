//@ts-check
//@ts-check
import React, { useState } from 'react'
import { useIntl } from 'react-intl'

import { Button, Modal, ModalVariant, TextContent, Text, Form, FormGroup, TextInput } from '@patternfly/react-core'

const useModalCloudUserSetTemporaryPassword = ({ onConfirm, isPending }) => {
    const { formatMessage } = useIntl()
    const [state, setState] = useState({ isOpen: false, entity: null, password: '' })
    const onClose = () => setState({ isOpen: false, entity: null, password: '' })

    const handleConfirm = () => {
        onConfirm(state.entity, state.password).then(() => {
            onClose()
        })
    }

    const modal = (state.entity != null)
        ? <Modal
            isOpen={state.isOpen}
            variant={ModalVariant.small}
            title={formatMessage({ id: `modal.cloudUserPasswordReset.setTemporaryPassword.title` }, state.entity)}
            showClose={true}
            actions={[
                <Button key="confirm"
                    variant="primary"
                    onClick={handleConfirm}
                    isLoading={isPending}
                    isDisabled={isPending}>
                    Reset password
                </Button>,
                <Button key="cancel" variant="link" onClick={onClose}>
                    Cancel
                </Button>
            ]}
            onClose={onClose}>
            <TextContent>
                <Text component="h2">Set a temporary password</Text>
            </TextContent>
            <Form>
                <FormGroup label="Temporary password" isRequired fieldId="password">
                    <TextInput
                        isRequired
                        type="text"
                        id="password"
                        name="password"
                        value={state.password}
                        onChange={(text) => setState({ ...state, password: text })} />
                </FormGroup>
            </Form>
            <TextContent>
                <Text component="p">The given password will be set as temporary password. For security reason the user needs to change the password on the next login.</Text>
            </TextContent>
        </Modal>
        : null

    return {
        modal: modal,
        isOpen: state.isOpen,
        openModal: (entity) => {
            setState({ isOpen: true, entity: entity })
        }
    }
}

export default useModalCloudUserSetTemporaryPassword