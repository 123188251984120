//@ts-check
import React from 'react'
import { FormattedMessage } from 'react-intl'

import { Flex, FlexItem, Title } from '@patternfly/react-core'

const FieldGroupPane = ({ messageId, children }) => (
    <Flex direction={{ default: 'column' }}>
        <FlexItem spacer={{ default: 'spacerLg' }}>
            <Title headingLevel="h2" size="lg" className="pf-u-mt-sm">
                <FormattedMessage id={`fieldGroup.${messageId}.title`} />
            </Title>
        </FlexItem>
        <FlexItem>
            {children}
        </FlexItem>
    </Flex>
)

export default FieldGroupPane
