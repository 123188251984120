//@ts-check
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import { Button, Modal, ModalVariant } from '@patternfly/react-core'

const ModalServiceRequest = ({ isOpen, apiOperation, isServiceRequestPending, isReadyToSubmit, onConfirm, onClose, children }) => {
    const { formatMessage } = useIntl()

    const actions = []
    switch (apiOperation.httpMethod) {
        case 'post':
            actions.push(
                <Button key="post"
                    variant="primary"
                    onClick={onConfirm}
                    isLoading={isServiceRequestPending}
                    isDisabled={!isReadyToSubmit}>
                    <FormattedMessage id={`apiOperation.${apiOperation.operationId}.modalSubmit`} />
                </Button>)
            actions.push(<BtnCancel operationId={apiOperation.operationId} onClose={onClose} />)
            break
        case 'get':
            actions.push(
                <Button key="close" variant="primary" onClick={onClose}>
                    <FormattedMessage id={`apiOperation.${apiOperation.operationId}.modalCancel`} />
                </Button>)
            break
        case 'delete':
            actions.push(
                <Button key="delete"
                    variant="danger"
                    onClick={onConfirm}
                    isLoading={isServiceRequestPending}
                    isDisabled={!isReadyToSubmit}>
                    <FormattedMessage id={`apiOperation.${apiOperation.operationId}.modalSubmit`} />
                </Button>)
            actions.push(<BtnCancel operationId={apiOperation.operationId} onClose={onClose} />)
            break
    }

    return (
        <Modal
            isOpen={isOpen}
            variant={ModalVariant.small}
            title={formatMessage({ id: `apiOperation.${apiOperation.operationId}.modalTitle` })}
            showClose={true}
            actions={actions}
            onClose={onClose}>
            {children}
        </Modal>
    )
}

export default ModalServiceRequest

const BtnCancel = ({ operationId, onClose }) => (
    <Button key="cancel"
        variant="link"
        onClick={onClose}>
        <FormattedMessage id={`apiOperation.${operationId}.modalCancel`} />
    </Button>
)
