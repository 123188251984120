//@ts-check
import React from 'react'
import { Link } from 'react-router-dom'

import { Button, Dropdown, DropdownItem, DropdownToggle, DropdownToggleCheckbox, Skeleton, Toolbar, ToolbarContent, ToolbarGroup, ToolbarItem } from '@patternfly/react-core';

import { useHasRole } from '../../Security/requiresRole'

import AddIcon from '@patternfly/react-icons/dist/esm/icons/add-circle-o-icon'
import EditIcon from '@patternfly/react-icons/dist/esm/icons/edit-icon'
import TrashIcon from '@patternfly/react-icons/dist/esm/icons/trash-icon'
import CheckIcon from '@patternfly/react-icons/dist/esm/icons/check-icon'

import { endpoints } from '../../Api/cloudAppStage'

import { useDropdownState } from '../../utils/dropdown'

import { CLOUDAPP_STAGE_CREATE, CLOUDAPP_STAGE_DELETE, CLOUDAPP_STAGE_UPDATE } from '../../App/securityRoles'

const ListToolbar = ({ isLoading, selectedItems, toggleItemSelection, toggleAllItemSelection }) => {
    const isCloudAppStageCreateRole = useHasRole(CLOUDAPP_STAGE_CREATE)
    const isCloudAppStageUpdateRole = useHasRole(CLOUDAPP_STAGE_UPDATE)
    const isCloudAppStageDeleteRole = useHasRole(CLOUDAPP_STAGE_DELETE)
    return (
        <Toolbar id="inline-modifier-data-toolbar" usePageInsets>
            {isLoading
                ? <Skeleton fontSize="3xl" />
                : <ToolbarContent>
                    {(isCloudAppStageUpdateRole || isCloudAppStageDeleteRole) &&
                        <ToolbarItem variant="bulk-select">
                            <DropdownBulkSelection
                                selectedItems={selectedItems}
                                toggleItemSelection={toggleItemSelection}
                                toggleAllItemSelection={toggleAllItemSelection} />
                        </ToolbarItem>}
                    {isCloudAppStageCreateRole && <ToolbarItem variant="separator" />}
                    {isCloudAppStageCreateRole && <ToolbarGroup variant="button-group">
                        <ToolbarItem>
                            <Link to="/cloud-app-stage/create">
                                <Button variant="primary" icon={<AddIcon />}>
                                    New
                                </Button>
                            </Link>
                        </ToolbarItem>
                    </ToolbarGroup>}
                    {(isCloudAppStageUpdateRole || isCloudAppStageDeleteRole) && selectedItems.length > 0 &&
                        <ToolbarItem variant="separator" />}
                    {(isCloudAppStageUpdateRole || isCloudAppStageDeleteRole) && selectedItems.length > 0 &&
                        <ToolbarGroup variant="icon-button-group">
                            {(isCloudAppStageUpdateRole && selectedItems.length == 1) &&
                                <ToolbarItem>
                                    <Link to={`/cloud-app-stage/${selectedItems[0]}/update`}>
                                        <Button variant="plain" aria-label="edit">
                                            <EditIcon />
                                        </Button>
                                    </Link>
                                </ToolbarItem>}
                            {isCloudAppStageDeleteRole &&
                                <ToolbarItem>
                                    <Button variant="plain" aria-label="delete">
                                        <TrashIcon />
                                    </Button>
                                </ToolbarItem>}
                        </ToolbarGroup>}
                </ToolbarContent>}
        </Toolbar>
    )
}

export default ListToolbar

const DropdownBulkSelection = ({ selectedItems, toggleItemSelection, toggleAllItemSelection }) => {
    const { data: cloudAppStages } = endpoints.readAll.useQueryState()
    const [isOpen, onToggle, onSelect] = useDropdownState()

    return (
        <Dropdown
            onSelect={onSelect}
            toggle={
                <DropdownToggle
                    splitButtonItems={[
                        <DropdownToggleCheckbox
                            key="split-checkbox"
                            id="select-all"
                            aria-label="Select all"
                            onClick={toggleAllItemSelection}
                            isChecked={(selectedItems.length == cloudAppStages.length)
                                ? true
                                : (selectedItems.length == 0)
                                    ? false
                                    : null}>
                            {selectedItems.length > 0 && selectedItems.length < cloudAppStages.length &&
                                <React.Fragment>{selectedItems.length} selected</React.Fragment>}
                        </DropdownToggleCheckbox>]}
                    onToggle={onToggle} />}
            isOpen={isOpen}
            dropdownItems={cloudAppStages.map((cloudAppStage) => (
                <DropdownItem
                    key={cloudAppStage.uid}
                    component="button"
                    icon={selectedItems.indexOf(cloudAppStage.uid) != -1 && <CheckIcon />}
                    onClick={() => toggleItemSelection(cloudAppStage.uid)}>
                    {cloudAppStage.name} {cloudAppStage.environment}
                </DropdownItem>
            ))} />
    )
}