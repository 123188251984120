//@ts-check
import { createApi } from '@reduxjs/toolkit/query/react'
import { dynamicBaseQuery } from "./common"

export const connectorApi = createApi({
    reducerPath: 'connectorApi',
    baseQuery: dynamicBaseQuery('connectorApi'),
    tagTypes: ['Connectors', 'ConnectorMeta'],
    endpoints: (builder) => ({
        readAll: builder.query({
            query: () => ({
                url: `/connector`
            }),
            providesTags: ['Connectors']
        }),
        readAllByType: builder.query({
            query: (connectorType) => ({
                url: `/connector?type=${connectorType}`
            }),
            providesTags: ['Connectors']
        }),
        readByUid: builder.query({
            query: (uid) => ({
                url: `/connector/${uid}`
            })
        }),
        readMetaByUid: builder.query({
            query: (uid) => ({
                url: `/connector/${uid}/meta`
            }),
            providesTags: ['ConnectorMeta']
        }),
        updateByUid: builder.mutation({
            query: ({ uid, property, value }) => ({
                url: `/connector/${uid}/config`,
                method: 'POST',
                body: { [property]: value }
            }),
            async onQueryStarted({ uid, property, value }, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled
                    dispatch(connectorApi.util.updateQueryData('readByUid', uid, (draft) => {
                        draft.config.values[property] = value
                    }))
                } catch { }
            },
        }),
    })
})

export const {
    useReadAllQuery,
    useReadAllByTypeQuery,
    useReadByUidQuery,
    useReadMetaByUidQuery,
    useUpdateByUidMutation,
    endpoints
} = connectorApi
