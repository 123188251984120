//@ts-check
import { createApi } from '@reduxjs/toolkit/query/react'
import { dynamicBaseQuery } from "./common"

export const servicesApi = createApi({
    reducerPath: 'servicesApi',
    baseQuery: dynamicBaseQuery('servicesApi'),
    endpoints: (builder) => ({        
        readAll: builder.query({
            query: () => ({
                url: `/services`
            }),
            providesTags: (result) => result
                ? [...result.map(({ uid }) => ({ type: 'Services', id: uid })), { type: 'Services', id: 'LIST' },]
                : [{ type: 'Services', id: 'LIST' }],
        })
    })
})

export const {
    useReadAllQuery,
    endpoints
} = servicesApi
