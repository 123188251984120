//@ts-check
import React, { useState } from 'react'

import { ActionList, ActionListItem, Alert, Button, DescriptionListDescription, DescriptionListGroup, DescriptionListTerm, Flex, FlexItem, Skeleton, Spinner, Split, SplitItem, Title } from '@patternfly/react-core'

import FieldGroupPane from '../../Form/fieldGroupPane'
import DescriptionListEntityFields, { DescriptionListEntity } from '../../DescriptionList/entity'
import ConnectorIdentifier from '../../Connector/identifierLabel'
import { useBuildMutation, useReadBuildStatusQuery, useUpdateByUidMutation } from '../../Api/cloudApp'

import SunIcon from '@patternfly/react-icons/dist/esm/icons/sun-icon'
import CloudSunIcon from '@patternfly/react-icons/dist/esm/icons/cloud-sun-icon'
import CloudRainIcon from '@patternfly/react-icons/dist/esm/icons/cloud-rain-icon'

import PlayIcon from '@patternfly/react-icons/dist/esm/icons/play-icon'
import StopIcon from '@patternfly/react-icons/dist/esm/icons/stop-icon'

const entityFieldsDockerRegistry = [
    { fieldName: 'connector_uid_snapshots', component: ConnectorIdentifier },
    { fieldName: 'connector_uid_release', component: ConnectorIdentifier },
]

const scoreIcons = {
    0: '',
    20: <i className="fas fa-thunderstorm-sun"></i>,
    40: <CloudRainIcon />,
    60: <i className="fas fa-clouds-sun" />,
    80: <CloudSunIcon />,
    100: <SunIcon />
}

const PaneBuild = ({ cloudApp }) => (
    <FieldGroupPane messageId="cloudApp.build">
        <Flex direction={{ default: 'column' }}>
            <FlexItem>
                <DescriptionListEntity>
                    <DescriptionListGroup>
                        <DescriptionListTerm>
                            Build provider
                        </DescriptionListTerm>
                        <DescriptionListDescription>
                            {(cloudApp.dev_workflow.build != null && cloudApp.dev_workflow.build.connector_uid != null)
                                ? <ConnectorIdentifier value={cloudApp.dev_workflow.build.connector_uid} />
                                : <Alert variant="warning" isInline isPlain title="Build provider not assigned" />}
                        </DescriptionListDescription>
                    </DescriptionListGroup>
                    {(cloudApp.dev_workflow.build != null && cloudApp.dev_workflow.build.build_jobs != null)
                        ? Object.entries(cloudApp.dev_workflow.build.build_jobs).map(([gitBranch, buildJobName]) => (
                            <DescriptionListGroup key={gitBranch}>
                                <DescriptionListTerm>
                                    Build-Job {gitBranch}
                                </DescriptionListTerm>
                                <DescriptionListDescription>
                                    <BuildJob
                                        cloudAppUid={cloudApp.uid}
                                        gitBranch={gitBranch}
                                        buildJobName={buildJobName} />
                                </DescriptionListDescription>
                            </DescriptionListGroup>))
                        : <DescriptionListGroup>
                            <DescriptionListTerm>
                                Build-Jobs
                            </DescriptionListTerm>
                            <DescriptionListDescription>
                                <Alert variant="warning" isInline isPlain title="No build jobs found" />
                            </DescriptionListDescription>
                        </DescriptionListGroup>}
                </DescriptionListEntity>
            </FlexItem>
            <FlexItem spacer={{ default: 'spacerLg' }}>
                <Title headingLevel="h2" size="lg" className="pf-u-mt-sm">
                    Docker registry
                </Title>
            </FlexItem>
            <FlexItem>
                <DescriptionListEntityFields
                    fields={entityFieldsDockerRegistry}
                    messageId="cloudApp.dockerRegistry"
                    entity={cloudApp.dev_workflow.docker_registry}>
                    {(props) => <FieldWrapper {...props} />}
                </DescriptionListEntityFields>
            </FlexItem>
        </Flex>
    </FieldGroupPane>
)

export default PaneBuild

const BuildJob = ({ cloudAppUid, gitBranch, buildJobName }) => {
    const { data: buildStatus, isLoading } = useReadBuildStatusQuery({ cloudAppUid, branch: gitBranch })

    return (
        <Split hasGutter>
            <SplitItem>
                {isLoading
                    ? <Skeleton />
                    : buildStatus.building
                        ? <Spinner isSVG size='md' />
                        : scoreIcons[buildStatus.healthScore]}
            </SplitItem>
            <SplitItem>
                {buildJobName}
            </SplitItem>
            <SplitItem>
                <BuildJobControl
                    cloudAppUid={cloudAppUid}
                    branch={gitBranch} />
            </SplitItem>
        </Split>
    )
}

const BuildJobControl = ({ cloudAppUid, branch }) => {
    const [triggerBuild, { isLoading: isPending }] = useBuildMutation()

    const onClickBuild = () => {
        triggerBuild({ cloudAppUid, branch }).then(() => {
            console.log("Build")
        })
    }

    return (
        <ActionList isIconList>
            <ActionListItem>
                <Button
                    variant="plain"
                    style={{ paddingTop: 0, paddingBottom: 0 }}
                    onClick={onClickBuild}
                    isDisabled={isPending}>
                    <PlayIcon />
                </Button>
            </ActionListItem>
            <ActionListItem>
                <Button
                    variant="plain"
                    style={{ paddingTop: 0, paddingBottom: 0 }}>
                    <StopIcon />
                </Button>
            </ActionListItem>
        </ActionList>
    )
}

const FieldWrapper = ({ component, uid, fieldName, value, ...args }) => {
    const [nextValue, setNextValue] = useState(value)
    const [updateField, { isLoading: isPending }] = useUpdateByUidMutation()

    const onSave = () => {
        return updateField({ uid, fieldName, value: nextValue })
    }

    return React.createElement(component, {
        uid,
        fieldName,
        value,
        nextValue,
        setNextValue,
        onSave,
        isPending,
        ...args
    })
}
