//@ts-check
import React, { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { matchPath, NavLink, useLocation } from 'react-router-dom'

import { Nav, NavItem, NavExpandable, NavList, NavItemSeparator, Spinner, Bullseye } from '@patternfly/react-core'

import RequiresRole from '../Security/requiresRole'

import { useLazyReadAllQuery as useLazyReadAllCloudAppsQuery } from '../Api/cloudApp'
import { useLazyReadAllQuery as useLazyReadAllCloudAppStagesQuery } from '../Api/cloudAppStage'

const menuItems = [{
  isExpandable: true,
  entityType: 'cloudApp',
  basePath: '/cloud-app',
  serviceRequest: useLazyReadAllCloudAppsQuery,
  requiresRole: 'cloudApp-read'
}, {
  isExpandable: true,
  entityType: 'cloudAppStage',
  basePath: '/cloud-app-stage',
  serviceRequest: useLazyReadAllCloudAppStagesQuery,
  requiresRole: 'cloudAppStage-read'
}, {
  isExpandable: false,
  entityType: 'cloudUser',
  basePath: '/cloud-user',
  requiresRole: 'cloudUser-read'
}]

const PageNav = () => (
  <Nav>
    <NavList>
      {menuItems.map((menuItem) => (
        <RequiresRole
          key={menuItem.entityType}
          role={menuItem.requiresRole}
          displayAlert={false}>
          {menuItem.isExpandable
            ? <ExpandableSectionEntities
              entityType={menuItem.entityType}
              basePath={menuItem.basePath}
              serviceRequest={menuItem.serviceRequest} />
            : <NavItem component="div">
              <NavLink to={menuItem.basePath}>
                <FormattedMessage id={`sidebarNav.${menuItem.entityType}.entityList`} />
              </NavLink>
            </NavItem>}
        </RequiresRole>))}
    </NavList>
  </Nav>
)

export default PageNav

const ExpandableSectionEntities = ({ entityType, basePath, serviceRequest }) => {
  const { formatMessage } = useIntl()
  const location = useLocation()
  const [isExpanded, setExpanded] = useState(false)

  const [loadEntities, { data: entities, isLoading, isUninitialized }] = serviceRequest()

  useEffect(() => {
    if (isExpanded && isUninitialized) {
      loadEntities()
    }
  }, [isExpanded])

  const anyEntities = (entities != null && entities.length > 0)

  return (
    <NavExpandable
      title={formatMessage({ id: `sidebarNav.${entityType}.title` })}
      isExpanded={isExpanded}
      onExpand={() => setExpanded(!isExpanded)}>
      {(isLoading || isUninitialized)
        ? <Bullseye><Spinner isSVG size='lg' /></Bullseye>
        : anyEntities && entities.map((entity) => (
          <NavItem
            key={entity.uid}
            component="div"
            isActive={matchPath(`${basePath}/${entity.uid}/update`, location.pathname) != null}>
            <NavLink to={`${basePath}/${entity.uid}/update`}>
              {entity.name}
            </NavLink>
          </NavItem>
        ))}
      {anyEntities && <NavItemSeparator />}
      <NavItem
        itemId="manageEntities"
        isActive={matchPath(basePath, location.pathname) != null}>
        <NavLink to={basePath}>
          <FormattedMessage id={`sidebarNav.${entityType}.entityList`} />
        </NavLink>
      </NavItem>
    </NavExpandable>
  )
}
