//@ts-check
import React from 'react'
import { FormattedMessage } from 'react-intl'

import { FormGroup } from "@patternfly/react-core"

const FormFields = ({ fields, messageId, entity, onChange = undefined, isDisabled = false }) => fields.map(({ component, ...field }) => (
    <FormGroup
        key={field.name}
        label={<FormattedMessage id={`field.${messageId}.${field.name}.label`} />}
        isRequired={field.isRequired}
        fieldId={field.name}>
        {React.createElement(component, {
            ...field,
            id: field.name,
            value: field.value
                ? field.value
                : entity[field.name],
            onChange: onChange,
            isDisabled: isDisabled
        })}
    </FormGroup>))

export default FormFields