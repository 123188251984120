//@ts-check
import React, { useState } from 'react'
import { PageSection, PageSectionVariants } from '@patternfly/react-core'

const Settings = () => {
    return (
        <PageSection variant={PageSectionVariants.light}>
            <div>Hallo Welt</div>
        </PageSection>
    )
}

export default Settings
