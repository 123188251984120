//@ts-check
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormattedMessage, FormattedRelativeTime, useIntl } from 'react-intl'

import { Dropdown, KebabToggle, DropdownItem, NotificationDrawerList, NotificationDrawerListItem, NotificationDrawerListItemHeader, DropdownPosition, NotificationDrawerListItemBody, DropdownDirection } from '@patternfly/react-core'

import { NotificationMessage } from '../Toasts'

import { deleteNotification, markNotificationRead, selectNotifications } from '../state/notification'

import TrashIcon from '@patternfly/react-icons/dist/esm/icons/trash-icon'

const NotificationList = () => {
    const notifications = useSelector(selectNotifications)

    return (
        <NotificationDrawerList>
            {notifications.map(notification => <NotificationItem
                key={notification.uid}
                notification={notification} />)}
        </NotificationDrawerList>
    )
}

export default NotificationList

const NotificationItem = ({ notification }) => {
    const dispatch = useDispatch()
    const { formatMessage } = useIntl()
    const [isActionDropdownOpen, setActionDropdownOpen] = useState(false)
    const onSelect = (event) => setActionDropdownOpen(false)

    const onMarkNotificationRead = () => dispatch(markNotificationRead(notification.uid))
    const onDeleteNotification = () => dispatch(deleteNotification(notification.uid))

    const notificationDrawerDropdownItems = [
        <DropdownItem
            key="delete"
            component="button"
            icon={<TrashIcon />}
            onClick={onDeleteNotification}>
            <FormattedMessage id="notificationDrawer.notification.buttonDelete" />
        </DropdownItem>
    ]

    return (
        <NotificationDrawerListItem
            variant={notification.variant}
            onClick={onMarkNotificationRead}
            isRead={notification.isRead}>
            <NotificationDrawerListItemHeader
                variant={notification.variant}
                title={formatMessage({ id: `notification.${notification.messageId}.title` }, notification.entity)}>
                <Dropdown
                    position={DropdownPosition.right}
                    direction={DropdownDirection.down}
                    onSelect={onSelect}
                    toggle={<KebabToggle onToggle={setActionDropdownOpen} />}
                    isOpen={isActionDropdownOpen}
                    isPlain
                    dropdownItems={notificationDrawerDropdownItems} />
            </NotificationDrawerListItemHeader>
            <NotificationDrawerListItemBody timestamp={<FormattedRelativeTime
                value={((Date.now() - notification.timestamp) / 1000) * -1}
                numeric="auto"
                updateIntervalInSeconds={1} />}>
                <NotificationMessage notification={notification} />
            </NotificationDrawerListItemBody>
        </NotificationDrawerListItem >
    )
}
