//@ts-check
import React from 'react'

import { TextInput } from '@patternfly/react-core'
import EditableField from './common'

const EditableTextValue = ({ fieldName, value, onSave, nextValue, setNextValue, isPending }) => (
    <EditableField
        value={value}
        isPending={isPending}
        isValueChanged={value != nextValue}
        onSave={onSave}>
        <TextInput
            autoFocus
            id={fieldName}
            value={nextValue}
            onChange={setNextValue}
            isDisabled={isPending} />
    </EditableField>
)

export default EditableTextValue
