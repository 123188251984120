//@ts-check
import React, { useState } from 'react'
import { useMatch } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

import { PageSection, PageSectionVariants, Tab, TabContent, TabContentBody, Tabs, TabTitleIcon, TabTitleText } from '@patternfly/react-core'

import EmptyStateLoading from '../../EmptyState/loading'
import EmptyStateNotFound from '../../EmptyState/notFound'
import EmptyStateError from '../../EmptyState/error'
import ToolbarEntity from './toolbar'
import PaneDeploy from './paneDeploy'
import PaneStack from './paneStack'
import PaneDetails from './paneDetails'
import PaneSourceCode from './paneSourceCode'
import PaneBuild from './paneBuild'
import PaneSingleSignOn from './paneSingleSignOn'
import PaneUsers from './paneUsers'

import { useReadByUidQuery as useReadCloudAppByUidQuery } from '../../Api/cloudApp'
import { useReadOpenApiDefinition } from '../../Api/openapi'

import InfoIcon from '@patternfly/react-icons/dist/js/icons/info-circle-icon'
import SignInIcon from '@patternfly/react-icons/dist/js/icons/sign-in-alt-icon'
import CodeBranchIcon from '@patternfly/react-icons/dist/js/icons/code-branch-icon'
import CogsIcon from '@patternfly/react-icons/dist/js/icons/cogs-icon'
import CloudUploadIcon from '@patternfly/react-icons/dist/js/icons/cloud-upload-alt-icon'
import CubesIcon from '@patternfly/react-icons/dist/js/icons/cubes-icon'
import UsersIcon from '@patternfly/react-icons/dist/js/icons/users-icon'

const CloudAppEdit = () => {
    const match = useMatch('/cloud-app/:uid/update')

    const { data: cloudApp, isLoading: isLoadingCloudApp, isError, error } = useReadCloudAppByUidQuery(match.params.uid)
    const { isUninitialized: isUninitializedOpenApi, isLoading: isLoadingOpenApi } = useReadOpenApiDefinition({ cloudApp })

    return (
        <React.Fragment>
            {(isLoadingCloudApp || (cloudApp.endpoints != null && cloudApp.endpoints.openapi != '' && isUninitializedOpenApi) || isLoadingOpenApi)
                ? <EmptyStateLoading />
                : isError
                    ? (error.status == 404)
                        ? <EmptyStateNotFound />
                        : <EmptyStateError />
                    : <CloudAppEntity cloudApp={cloudApp} />}
        </React.Fragment>
    )
}

export default CloudAppEdit

const CloudAppEntity = ({ cloudApp }) => {
    const tabs = [{
        eventKey: 'details',
        icon: <InfoIcon />,
        devWorkflows: ['DevWorkflowNoRepo', 'DevWorkflowCentral', 'DevWorkflowFeatureBranch', 'DevWorkflowGitFlow'],
        component: PaneDetails
    }, {
        eventKey: 'sso',
        icon: <SignInIcon />,
        devWorkflows: ['DevWorkflowNoRepo', 'DevWorkflowCentral', 'DevWorkflowFeatureBranch', 'DevWorkflowGitFlow'],
        component: PaneSingleSignOn
    }, {
        eventKey: 'scm',
        icon: <CodeBranchIcon />,
        devWorkflows: ['DevWorkflowCentral', 'DevWorkflowFeatureBranch', 'DevWorkflowGitFlow'],
        component: PaneSourceCode
    }, {
        eventKey: 'build',
        icon: <CogsIcon />,
        devWorkflows: ['DevWorkflowCentral', 'DevWorkflowFeatureBranch', 'DevWorkflowGitFlow'],
        component: PaneBuild
    }, {
        eventKey: 'deploy',
        icon: <CloudUploadIcon />,
        devWorkflows: ['DevWorkflowNoRepo', 'DevWorkflowCentral', 'DevWorkflowFeatureBranch', 'DevWorkflowGitFlow'],
        component: PaneDeploy
    }, {
        eventKey: 'stack',
        icon: <CubesIcon />,
        devWorkflows: ['DevWorkflowNoRepo', 'DevWorkflowCentral', 'DevWorkflowFeatureBranch', 'DevWorkflowGitFlow'],
        component: PaneStack
    }]
    if (cloudApp.idp_client != null) {
        tabs.push({
            eventKey: 'users',
            icon: <UsersIcon />,
            devWorkflows: ['DevWorkflowNoRepo', 'DevWorkflowCentral', 'DevWorkflowFeatureBranch', 'DevWorkflowGitFlow'],
            component: PaneUsers
        })
    }

    const actualTabs = tabs.filter(x => x.devWorkflows.includes(cloudApp.dev_workflow._class))

    const [activeTabKey, setActiveTabKey] = useState(actualTabs[0].eventKey)
    const handleTabClick = (event, eventKey) => setActiveTabKey(eventKey)

    return (
        <React.Fragment>
            <PageSection variant={PageSectionVariants.light} padding={{ default: 'noPadding' }}>
                <ToolbarEntity
                    cloudApp={cloudApp}
                    activeTabKey={activeTabKey} />
            </PageSection>
            <PageSection variant={PageSectionVariants.light} padding={{ default: 'noPadding' }}>
                <Tabs
                    usePageInsets
                    activeKey={activeTabKey}
                    onSelect={handleTabClick}>
                    {actualTabs.map(({ eventKey, icon }) => (
                        <Tab
                            key={eventKey}
                            eventKey={eventKey}
                            title={<TabTitleWithIcon eventKey={eventKey} icon={icon} />} />))}
                </Tabs>
            </PageSection>
            <PageSection variant={PageSectionVariants.light}>
                {actualTabs.map(({ eventKey, component }) => (
                    <TabContent
                        key={eventKey}
                        eventKey={eventKey}
                        id={`tabContent-${eventKey}`}
                        hidden={eventKey !== activeTabKey}>
                        <TabContentBody>
                            {eventKey === activeTabKey && React.createElement(component, { cloudApp: cloudApp })}
                        </TabContentBody>
                    </TabContent>))}
            </PageSection>
        </React.Fragment>
    )
}

const TabTitleWithIcon = ({ eventKey, icon }) => (
    <React.Fragment>
        <TabTitleIcon>
            {icon}
        </TabTitleIcon>
        {' '}
        <TabTitleText>
            <FormattedMessage id={`tab.cloudApp.${eventKey}.title`} />
        </TabTitleText>
    </React.Fragment>
)