//@ts-check
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { Button, ButtonVariant, Dropdown, KebabToggle, DropdownToggle, NotificationBadge, DropdownGroup, DropdownItem, Divider, Toolbar, ToolbarContent, ToolbarGroup, ToolbarItem } from '@patternfly/react-core'
import Avatar from 'avataaars'

import CogIcon from '@patternfly/react-icons/dist/esm/icons/cog-icon'
import HelpIcon from '@patternfly/react-icons/dist/esm/icons/help-icon'
import QuestionCircleIcon from '@patternfly/react-icons/dist/esm/icons/question-circle-icon'
import BellIcon from '@patternfly/react-icons/dist/esm/icons/bell-icon'
import LightbulbIcon from '@patternfly/react-icons/dist/esm/icons/lightbulb-icon'
import UserEditIcon from '@patternfly/react-icons/dist/esm/icons/user-edit-icon'
import UsersIcon from '@patternfly/react-icons/dist/esm/icons/users-icon'
import SignOutIcon from '@patternfly/react-icons/dist/esm/icons/sign-out-alt-icon'

import { closeNotificationDrawer, openNotificationDrawer, selectIsNotificationDrawerOpen } from '../state/ui'
import { selectNotificationsUnread } from '../state/notification'
import { selectUser } from '../state/app'

import './style.css'
import useAboutModal from '../AboutModal'

const HeaderToolbar = ({ keycloakClient }) => {
    const dispatch = useDispatch()

    const isNotificationDrawerOpen = useSelector(selectIsNotificationDrawerOpen)
    const notificationsUnread = useSelector(selectNotificationsUnread)

    const onOpenNotificationDrawer = () => dispatch(openNotificationDrawer())
    const onCloseNotificationDrawer = () => dispatch(closeNotificationDrawer())

    const {
        modal: ModalAbout,
        openModal: openModalAbout
    } = useAboutModal()

    const menuItems = [{
        key: 'tips',
        icon: <LightbulbIcon />,
        label: 'tips',
        onClick: undefined,
        to: '',
        kebabOnTablet: false
    }, {
        key: 'notifications',
        component: (
            <NotificationBadge
                variant={notificationsUnread.length === 0
                    ? 'read'
                    : notificationsUnread.filter(x => x.variant == 'danger').length > 0
                        ? 'attention'
                        : 'unread'}
                onClick={isNotificationDrawerOpen ? onCloseNotificationDrawer : onOpenNotificationDrawer}
                count={notificationsUnread.length}>
                <BellIcon />
            </NotificationBadge>),
        onClick: undefined,
        to: '',
        kebabOnTablet: false
    }, {
        key: 'settings',
        icon: <CogIcon />,
        label: 'settings',
        onClick: undefined,
        to: '/settings',
        kebabOnTablet: true
    }, {
        key: 'documentation',
        icon: <QuestionCircleIcon />,
        label: 'documentation',
        onClick: openModalAbout,
        to: undefined,
        kebabOnTablet: true
    }]

    const userMenuItems = [{
        icon: <UserEditIcon />,
        label: 'myProfile',
        onClick: undefined,
        to: '/'
    }, {
        icon: <UsersIcon />,
        label: 'userManagement',
        onClick: undefined,
        to: '/'
    }, {
        icon: <SignOutIcon />,
        label: 'logout',
        onClick: undefined,
        to: keycloakClient.createLogoutUrl({ redirectUri: 'https://localhost:9000' })
    }]

    return (
        <Toolbar id="toolbar" isFullHeight isStatic>
            <ToolbarContent>
                <IconToolbarGroup
                    visibility={{ default: 'visible', md: 'hidden' }}
                    menuItemsIcon={menuItems.filter(menuItem => !menuItem.kebabOnTablet)}
                    menuItemsKebab={[{
                        isGroup: true,
                        menuItems: userMenuItems
                    },
                    ...menuItems.filter(menuItem => menuItem.kebabOnTablet)]} />
                <IconToolbarGroup
                    visibility={{ default: 'hidden', md: 'visible', lg: 'hidden' }}
                    menuItemsIcon={menuItems.filter(menuItem => !menuItem.kebabOnTablet)}
                    menuItemsKebab={menuItems.filter(menuItem => menuItem.kebabOnTablet)} />
                <IconToolbarGroup
                    visibility={{ default: 'hidden', lg: 'visible' }}
                    menuItemsIcon={menuItems}
                    menuItemsKebab={[]} />
                <ToolbarItem visibility={{ default: 'hidden', md: 'visible' }}>
                    <DropdownUserMenu dropdownItems={asMenuItems(userMenuItems)} />
                </ToolbarItem>
            </ToolbarContent>
            {ModalAbout}
        </Toolbar>
    )
}

export default HeaderToolbar

const IconToolbarGroup = ({ visibility, menuItemsIcon, menuItemsKebab }) => (
    <ToolbarGroup
        variant="icon-button-group"
        alignment={{ default: 'alignRight' }}
        spacer={{ default: 'spacerNone', md: 'spacerMd' }}
        visibility={visibility}>
        {menuItemsIcon.map((menuItem) => (
            <ToolbarItem key={menuItem.key}>
                {menuItem.component
                    ? menuItem.component
                    : menuItem.to
                        ? <Link to={menuItem.to}>
                            <Button variant={ButtonVariant.plain}>
                                {menuItem.icon}
                            </Button>
                        </Link>
                        : <Button
                            variant={ButtonVariant.plain}
                            onClick={menuItem.onClick}>
                            {menuItem.icon}
                        </Button>}
            </ToolbarItem>))}
        {menuItemsKebab.length > 0 &&
            <ToolbarItem>
                <DropdownMenu dropdownItems={asMenuItems(menuItemsKebab)} />
            </ToolbarItem>}
    </ToolbarGroup>
)

const asMenuItems = (menuItems) => menuItems.map((menuItem, index) => (
    menuItem.isGroup
        ? <DropdownGroup key={index}>
            {menuItem.menuItems.map((menuItem, index) => (
                <DropdownMenuItem
                    key={index}
                    menuItem={menuItem} />))}
            <Divider key="divider" />
        </DropdownGroup>
        : <DropdownMenuItem
            key={index}
            menuItem={menuItem} />
))

const DropdownMenuItem = ({ menuItem }) => (
    <DropdownItem
        icon={menuItem.icon}
        href={menuItem.to}>
        <FormattedMessage id={`headerToolbar.${menuItem.label}.label`} />
    </DropdownItem>
)

const useDropdownState = () => {
    const [isOpen, setOpen] = useState(false)
    const onToggle = (isDropdownOpen) => setOpen(isDropdownOpen)
    const onSelect = (event) => setOpen(!isOpen)

    return [isOpen, onToggle, onSelect]
}

const DropdownMenu = ({ dropdownItems }) => {
    const [isOpen, onToggle, onSelect] = useDropdownState()

    return (
        <Dropdown
            isPlain
            position="right"
            onSelect={onSelect}
            toggle={<KebabToggle onToggle={onToggle} />}
            isOpen={isOpen}
            dropdownItems={dropdownItems} />
    )
}

const DropdownUserMenu = ({ dropdownItems }) => {
    const [isOpen, onToggle, onSelect] = useDropdownState()
    const { firstname, lastname } = useSelector(selectUser)
    return (
        <Dropdown
            position="right"
            onSelect={onSelect}
            isOpen={isOpen}
            toggle={
                <DropdownToggle
                    icon={<Avatar
                        style={{ width: '3rem', height: '3rem' }}
                        avatarStyle='Circle'
                        topType='ShortHairShortRound'
                        accessoriesType='Prescription02'
                        hairColor='BrownDark'
                        facialHairType='Blank'
                        clotheType='CollarSweater'
                        clotheColor='PastelBlue'
                        eyeType='Default'
                        eyebrowType='Default'
                        mouthType='Smile'
                        skinColor='Light' />}
                    onToggle={onToggle}>
                    {firstname} {lastname}
                </DropdownToggle>}
            dropdownItems={dropdownItems} />
    )
}