//@ts-check
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl'

import { Dropdown, KebabToggle, DropdownItem, DropdownPosition } from '@patternfly/react-core'

import { clearAllNotifications, markAllNotificationRead, selectNotifications, selectNotificationsUnread } from '../state/notification'

import EyeIcon from '@patternfly/react-icons/dist/esm/icons/eye-icon'
import TrashIcon from '@patternfly/react-icons/dist/esm/icons/trash-icon'
import CogIcon from '@patternfly/react-icons/dist/esm/icons/cog-icon'

const DropdownHeaderMenu = () => {
    const dispatch = useDispatch()
    const [isOpen, setOpen] = useState(false)
    const notifications = useSelector(selectNotifications)
    const notificationsUnread = useSelector(selectNotificationsUnread)

    const markAllRead = () => dispatch(markAllNotificationRead())
    const clearAll = () => dispatch(clearAllNotifications())
    const onSelect = (event) => setOpen(false)

    const notificationDrawerActions = []
    if (notificationsUnread.length > 0) {
        notificationDrawerActions.push(
            <DropdownItem key="markAllRead"
                onClick={markAllRead}
                component="button"
                icon={<EyeIcon />}>
                <FormattedMessage id="notificationDrawer.dropdownHeaderMenu.itemMarkAllRead" />
            </DropdownItem>)
    }
    if (notifications.length > 0) {
        notificationDrawerActions.push(
            <DropdownItem key="clearAll"
                onClick={clearAll}
                component="button"
                icon={<TrashIcon />}>
                <FormattedMessage id="notificationDrawer.dropdownHeaderMenu.itemClear" />
            </DropdownItem>
        )
    }
    notificationDrawerActions.push(
        <DropdownItem key="settings"
            component="button"
            icon={<CogIcon />}>
            <FormattedMessage id="notificationDrawer.dropdownHeaderMenu.itemSettings" />
        </DropdownItem>
    )

    return (
        <Dropdown
            onSelect={onSelect}
            toggle={<KebabToggle onToggle={setOpen} />}
            isOpen={isOpen}
            isPlain
            dropdownItems={notificationDrawerActions}
            position={DropdownPosition.right}
        />
    )
}

export default DropdownHeaderMenu
