//@ts-check
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Drawer, DrawerContentBody, PageSection, PageSectionVariants } from '@patternfly/react-core'

import EmptyStateLoading from '../../EmptyState/loading'
import EmptyStateNoData from '../../EmptyState/noData'
import ListToolbar from './toolbar'
import EntityListTable from '../../EntityListTable'

import useModalConfirmDeleteEntity from '../../Modal/confirmDeleteEntity'
import { endpoints, useDeleteByUidMutation, useReadAllQuery } from '../../Api/cloudUsers'

import EditIcon from '@patternfly/react-icons/dist/esm/icons/pencil-alt-icon'
import KeyIcon from '@patternfly/react-icons/dist/esm/icons/key-icon'
import UserShieldIcon from '@patternfly/react-icons/dist/esm/icons/user-shield-icon'
import UserLockIcon from '@patternfly/react-icons/dist/esm/icons/user-lock-icon'
import TrashIcon from '@patternfly/react-icons/dist/esm/icons/trash-alt-icon'
import CheckIcon from '@patternfly/react-icons/dist/esm/icons/check-circle-icon'
import TimesIcon from '@patternfly/react-icons/dist/esm/icons/times-circle-icon'
import { useResponseNotificationBuilder } from '../../Notification/hooks'

const CloudUserList = () => {
    const { data: cloudUsers, isLoading } = useReadAllQuery()
    const [selectedItems, setSelectedItems] = useState([])

    return (
        <PageSection
            variant={PageSectionVariants.light}
            padding={{ default: 'noPadding' }}
            isFilled>
            <Drawer isInline>
                <DrawerContentBody>
                    <ListToolbar
                        isLoading={isLoading}
                        selectedItems={selectedItems} />
                    {isLoading
                        ? <EmptyStateLoading />
                        : (cloudUsers.length == 0)
                            ? <EmptyStateNoData createRoute="/cloud-user/create" />
                            : <CloudUsers
                                selectedItems={selectedItems}
                                setSelectedItems={setSelectedItems} />}
                </DrawerContentBody>
            </Drawer>
        </PageSection>
    )
}

export default CloudUserList

const columns = {
    firstname: {
        formatter: (value) => value
    },
    lastname: {
        formatter: (value) => value
    },
    username: {
        formatter: (value) => value
    },
    email: {
        formatter: (value) => value
    },
    isVerified: {
        formatter: (value) => value ? <CheckIcon color="green" /> : <TimesIcon color="red" />
    }
}

const CloudUsers = ({ selectedItems, setSelectedItems }) => {
    const navigate = useNavigate()

    const { data: cloudUsers } = endpoints.readAll.useQueryState()
    const [deleteCloudUser, { isLoading: isPendingCloudUser }] = useDeleteByUidMutation()

    const responseNotificationBuilder = useResponseNotificationBuilder({
        messageIdOnSuccess: 'cloudUserDeleted',
        messageIdOnFailure: 'cloudUserDeleteForbidden',
        navigateTo: '/cloud-user'
    })

    const onConfirmDeleteCloudUser = (entity) => deleteCloudUser(entity.uid)
        .then(responseNotificationBuilder(entity))

    const {
        modal: ModalDeleteCloudUser,
        openModal: openModalDeleteCloudUser
    } = useModalConfirmDeleteEntity({ messageId: 'cloudUser', onConfirm: onConfirmDeleteCloudUser, isPending: isPendingCloudUser })

    const entityActions = (entity) => [{
        title: 'Edit',
        icon: <EditIcon />,
        onClick: () => navigate(`/cloud-user/${entity.uid}/update`)
    }, {
        isSeparator: true
    }, {
        title: 'Reset password',
        icon: <KeyIcon />,
        isDisabled: true
    }, {
        title: 'Configure OTP',
        icon: <UserShieldIcon />,
        isDisabled: true
    }, {
        title: 'Update Password',
        icon: <UserLockIcon />,
        isDisabled: true
    }, {
        isSeparator: true
    }, {
        title: 'Delete',
        icon: <TrashIcon />,
        onClick: () => openModalDeleteCloudUser(entity)
    }]

    return (
        <React.Fragment>
            <EntityListTable
                messageId="cloudUser"
                entities={cloudUsers}
                columns={columns}
                entityActions={entityActions}
                selectedEntities={selectedItems}
                setSelectedEntities={setSelectedItems} />
            {ModalDeleteCloudUser}
        </React.Fragment>
    )
}
