//@ts-check
import React from 'react'
import { FormattedMessage } from 'react-intl'

import { Stack, StackItem, Text, TextContent } from "@patternfly/react-core"

const TitledPane = ({ messageId, children }) => (
    <Stack hasGutter>
        <StackItem>
            <TextContent>
                <Text component='h2'>
                    <FormattedMessage id={`wizard.${messageId}.panelTitle`} />
                </Text>
                <Text component='p'>
                    <FormattedMessage id={`wizard.${messageId}.panelDescription`} />
                </Text>
            </TextContent>
        </StackItem>
        <StackItem>
            {children}
        </StackItem>
    </Stack>
)

export default TitledPane
