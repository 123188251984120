//@ts-check
import React, { useState } from 'react'

import { DataList, Drawer, DrawerContentBody, PageSection, PageSectionVariants } from '@patternfly/react-core'

import EmptyStateLoading from '../../EmptyState/loading'
import EmptyStateNoData from '../../EmptyState/noData'
import ListToolbar from './toolbar'
import CloudAppItem from './dataListItem'

import { endpoints, useReadAllQuery } from '../../Api/cloudApp'

const CloudAppList = () => {
    const { data: cloudApps, isLoading } = useReadAllQuery()
    const [selectedItems, setSelectedItems] = useState([])

    const toggleItemSelection = (itemUid) => {
        const index = selectedItems.indexOf(itemUid)
        if (index != -1) {
            const nextState = selectedItems.slice(0)
            nextState.splice(index, 1)
            setSelectedItems(nextState)
        } else {
            setSelectedItems(selectedItems.concat(itemUid))
        }
    }

    const toggleAllItemSelection = () => {
        if (selectedItems.length > 0) {
            setSelectedItems(new Array(0))
        } else {
            setSelectedItems(cloudApps.map(x => x.uid))
        }
    }

    return (
        <PageSection
            variant={PageSectionVariants.light}
            padding={{ default: 'noPadding' }}
            isFilled>
            <Drawer isInline>
                <DrawerContentBody>
                    <ListToolbar
                        isLoading={isLoading}
                        selectedItems={selectedItems}
                        toggleItemSelection={toggleItemSelection}
                        toggleAllItemSelection={toggleAllItemSelection} />
                    {isLoading
                        ? <EmptyStateLoading />
                        : (cloudApps.length == 0)
                            ? <EmptyStateNoData createRoute="/cloud-app-stage/create" />
                            : <CloudApps
                                selectedItems={selectedItems}
                                toggleItemSelection={toggleItemSelection} />}
                </DrawerContentBody>
            </Drawer>
        </PageSection>
    )
}

export default CloudAppList

const CloudApps = ({ selectedItems, toggleItemSelection }) => {
    const { data: cloudApps } = endpoints.readAll.useQueryState()

    return (
        <DataList aria-label="">
            {cloudApps.map((cloudApp) => (
                <CloudAppItem
                    key={cloudApp.uid}
                    isSelected={selectedItems.indexOf(cloudApp.uid) != -1}
                    toggleItemSelection={toggleItemSelection}
                    cloudApp={cloudApp} />))}
        </DataList>
    )
}

