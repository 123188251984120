//@ts-check
import React from 'react'
import { FormattedList } from 'react-intl'

import { Title, EmptyState, EmptyStateIcon, EmptyStateBody, TextContent, Text } from '@patternfly/react-core'

import LockIcon from '@patternfly/react-icons/dist/esm/icons/lock-icon'

const EmptyStateForbidden = ({ allowedRoles = undefined }) => (
    <EmptyState>
        <EmptyStateIcon icon={LockIcon} />
        <Title size="lg" headingLevel="h4">
            Forbidden
        </Title>
        <EmptyStateBody>
            <TextContent>
                <Text>You are not allowed to access this resource.</Text>
                {allowedRoles &&
                    <Text>You require any of these roles <FormattedList value={allowedRoles.map(x => <u>{x}</u>)} type="disjunction" /></Text>}
            </TextContent>
        </EmptyStateBody>
    </EmptyState>
)

export default EmptyStateForbidden