//@ts-check
import React, { useEffect, useState } from 'react'
import { Provider, useDispatch, useSelector } from 'react-redux'
import { IntlProvider, useIntl } from 'react-intl'
import { BrowserRouter } from "react-router-dom"

import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/dist/query'

import appReducer, { selectIsAuthenticated, setAppAccessTokenExpired, setAppAccessTokenRefreshed, setAppUser } from '../state/app'
import notificationReducer from '../state/notification'
import uiReducer from '../state/ui'

import { configApi, useReadQuery } from '../Api/config'
import { i18nApi, useLazyReadMessagesQuery } from '../Api/i18n'
import { servicesApi } from '../Api/services'
import { cloudUsersApi } from '../Api/cloudUsers'
import { cloudAppApi } from '../Api/cloudApp'
import { cloudAppStageApi } from '../Api/cloudAppStage'
import { cloudAppStageNodeApi } from '../Api/cloudAppStageNode'
import { cloudProviderApi } from '../Api/cloudProvider'
import { connectorApi } from '../Api/connector'
import { openapiApi } from '../Api/openapi'
import { versionApi } from '../Api/version'

import './app.css'

import messages_de from "../translations/de.json"
import messages_en from "../translations/en.json"

import PageComponent from '../Page'
import Toasts from '../Toasts'
import EmptyStateLoading from '../EmptyState/loading'
import EmptyStateError from '../EmptyState/error'
import KeycloakClient from './keycloakClient'
import useInactivityBlur from './inactivityBlur'

const localMessages = {
  'de': messages_de,
  'en': messages_en
}

const richTextElements = {
  b: (...chunks) => <b>{chunks}</b>,
  p: (...chunks) => <p>{chunks}</p>,
  u: (...chunks) => <u>{chunks}</u>,
}

const App = () => {
  const language = navigator.language.split(/[-_]/)[0]

  const store = configureStore({
    reducer: {
      app: appReducer,
      notification: notificationReducer,
      ui: uiReducer,
      [configApi.reducerPath]: configApi.reducer,
      [i18nApi.reducerPath]: i18nApi.reducer,
      [servicesApi.reducerPath]: servicesApi.reducer,
      [cloudUsersApi.reducerPath]: cloudUsersApi.reducer,
      [cloudAppApi.reducerPath]: cloudAppApi.reducer,
      [cloudAppStageApi.reducerPath]: cloudAppStageApi.reducer,
      [cloudAppStageNodeApi.reducerPath]: cloudAppStageNodeApi.reducer,
      [cloudProviderApi.reducerPath]: cloudProviderApi.reducer,
      [connectorApi.reducerPath]: connectorApi.reducer,
      [openapiApi.reducerPath]: openapiApi.reducer,
      [versionApi.reducerPath]: versionApi.reducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware()
      .concat(configApi.middleware)
      .concat(i18nApi.middleware)
      .concat(servicesApi.middleware)
      .concat(cloudUsersApi.middleware)
      .concat(cloudAppApi.middleware)
      .concat(cloudAppStageApi.middleware)
      .concat(cloudAppStageNodeApi.middleware)
      .concat(cloudProviderApi.middleware)
      .concat(connectorApi.middleware)
      .concat(openapiApi.middleware)
      .concat(versionApi.middleware),
    devTools: process.env.NODE_ENV !== 'production'
  })

  setupListeners(store.dispatch)

  return (
    <IntlProvider
      locale={language}
      messages={localMessages[language]}
      defaultRichTextElements={richTextElements}>
      <BrowserRouter>
        <Provider store={store}>
          <AppInitializer language={language} />
        </Provider>
      </BrowserRouter>
    </IntlProvider>
  )
}

export default App

const AppInitializer = ({ language }) => {
  const dispatch = useDispatch()
  const { messages } = useIntl()
  const [initStatus, setInitStatus] = useState('loadingConfig')
  const { data: config, isLoading: isLoadingConfig, isError: isErrorConfig } = useReadQuery()
  const isAuthenticated = useSelector(selectIsAuthenticated)

  const [readMessages, { data: appMessages, isLoading: isLoadingMessages, isError: isErrorMessages }] = useLazyReadMessagesQuery()

  useEffect(() => {
    if (config != null) {
      setInitStatus('authenticate')
      KeycloakClient.init(config.identityProvider)
        .then(({ userInfo, keycloakClient }) => {
          if (keycloakClient.authenticated) {
            dispatch(setAppUser({
              username: userInfo.preferred_username,
              firstname: userInfo.given_name,
              lastname: userInfo.family_name,
              email: userInfo.email,
              jwt: keycloakClient.token,
              roles: keycloakClient.resourceAccess['itbox365-orchestrator']
                ? keycloakClient.resourceAccess['itbox365-orchestrator'].roles
                : []
            }))
            keycloakClient.onTokenExpired = () => {
              dispatch(setAppAccessTokenExpired())
            }
            keycloakClient.onAuthRefreshSuccess = () => {
              dispatch(setAppAccessTokenRefreshed())
            }
          }
        })
        .catch(() => {
          setInitStatus('authFailed')
        })
    }
  }, [config])

  useEffect(() => {
    if (isAuthenticated) {
      readMessages(language)
    }
  }, [isAuthenticated])

  useInactivityBlur()

  if (isErrorConfig || initStatus == 'authFailed' || isErrorMessages) {
    return <EmptyStateError
      messageIdTitle={`emptyState.${initStatus}.title`}
      messageIdBody={`emptyState.${initStatus}.body`} />
  }

  let messageIdBody = undefined
  if (isLoadingConfig) {
    messageIdBody = "emptyState.appInit.bodyLoadingConfig"
  } else if (initStatus == 'authenticate' && !isAuthenticated) {
    messageIdBody = "emptyState.appInit.bodyAuthenticate"
  } else if (isLoadingMessages) {
    messageIdBody = "emptyState.appInit.bodyLoadingMessages"
  }

  return (config == null || isLoadingConfig || !isAuthenticated || appMessages == null || isLoadingMessages)
    ? <EmptyStateLoading
      messageIdTitle="emptyState.appInit.title"
      messageIdBody={messageIdBody} />
    : <AppComponent
      keycloakClient={KeycloakClient.keycloakClient}
      language={language}
      messages={{ ...messages, ...appMessages }} />
}

const AppComponent = ({ keycloakClient, language, messages }) => {

  const onError = (error) => {
    console.debug(error)
  }

  return (
    <IntlProvider
      locale={language}
      messages={messages}
      defaultRichTextElements={richTextElements}
      onError={onError}>
      <Toasts />
      <PageComponent keycloakClient={keycloakClient} />
    </IntlProvider>
  )
}
