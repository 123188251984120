//@ts-check
import React from 'react'
import { FormattedMessage } from 'react-intl'

import { DescriptionList, DescriptionListDescription, DescriptionListGroup, DescriptionListTerm } from '@patternfly/react-core'

const DescriptionListEntityFields = ({ fields, entity, messageId, children }) => (
    <DescriptionListEntity>
        {fields.map(({ fieldName, component }) => (
            <DescriptionListGroup key={fieldName}>
                <DescriptionListTerm>
                    <FormattedMessage id={`field.${messageId}.${fieldName}.label`} />
                </DescriptionListTerm>
                <DescriptionListDescription>
                    {children({ component, uid: entity.uid, fieldName, value: entity[fieldName] })}
                </DescriptionListDescription>
            </DescriptionListGroup>))}
    </DescriptionListEntity>
)

export default DescriptionListEntityFields

export const DescriptionListEntity = ({ children }) => (
    <DescriptionList
        isHorizontal
        horizontalTermWidthModifier={{
            sm: '100%',
            lg: '15vh',
        }}
        orientation={{
            sm: 'vertical',
            md: 'vertical',
            lg: 'horizontal',
            xl: 'horizontal',
            '2xl': 'horizontal'
        }}>
        {children}
    </DescriptionList>
)
