//@ts-check
import { createApi } from '@reduxjs/toolkit/query/react'
import { dynamicBaseQuery } from "./common"

export const versionApi = createApi({
    reducerPath: 'versionApi',
    baseQuery: dynamicBaseQuery('versionApi'),
    endpoints: (builder) => ({        
        readVersion: builder.query({
            query: () => ({
                url: `/version`
            })
        })
    })
})

export const {
    useReadVersionQuery,
    endpoints
} = versionApi
