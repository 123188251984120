//@ts-check
import React from 'react'
import { FormattedMessage } from 'react-intl'

import { Title, EmptyState, EmptyStateIcon, EmptyStateBody, TextContent, Text } from '@patternfly/react-core'

import EmojiFrownIcon from '@patternfly/react-icons/dist/esm/icons/sad-tear-icon'

const EmptyStateError = ({
    messageIdTitle = 'emptyState.error.title',
    messageIdBody = 'emptyState.error.body'
}) => (
    <EmptyState>
        <EmptyStateIcon icon={EmojiFrownIcon} />
        <Title size="lg" headingLevel="h4">
            <FormattedMessage id={messageIdTitle} />
        </Title>
        <EmptyStateBody>
            <TextContent>
            <FormattedMessage id={messageIdBody} />
            </TextContent>
        </EmptyStateBody>
    </EmptyState>
)

export default EmptyStateError
