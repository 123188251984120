//@ts-check
import React from 'react'
import { FormattedMessage } from 'react-intl'

import { Title, EmptyState, EmptyStateIcon, Spinner, EmptyStateBody } from '@patternfly/react-core'

/**
 * 
 * @param {Object} props
 * @param {String} [props.messageIdTitle]
 * @param {String|undefined} [props.messageIdBody]
 * @returns {React.ReactElement}
 */
const EmptyStateLoading = ({ messageIdTitle = "emptyState.appLoading", messageIdBody = undefined }) => (
    <EmptyState>
        <EmptyStateIcon variant="container" component={Spinner} />
        <Title size="lg" headingLevel="h4">
            <FormattedMessage id={messageIdTitle} />
        </Title>
        {(messageIdBody != null) &&
            <EmptyStateBody>
                <FormattedMessage id={messageIdBody} />
            </EmptyStateBody>}
    </EmptyState>
)

export default EmptyStateLoading
