//@ts-check
import React from 'react'
import { useSelector } from 'react-redux'
import { Link, Route, Routes } from 'react-router-dom'

import { Page, PageSidebar, Masthead, MastheadToggle, PageToggleButton, MastheadMain, MastheadBrand, MastheadContent, Brand, EmptyState, EmptyStateIcon, Title, EmptyStateBody } from '@patternfly/react-core'

import AppNotificationDrawer from '../NotificationDrawer'
import PageNav from '../PageNavigation'
import PageBreadcrumb, { useRoute } from '../PageBreadcrumb'
import HeaderToolbar from '../HeaderToolbar'
import PageSectionHeader from './pageSectionHeader'
import EmptyStateForbidden from '../EmptyState/forbidden'
import TertiaryNav from './tertiaryNav'

import { selectIsNotificationDrawerOpen } from '../state/ui'

import BarsIcon from '@patternfly/react-icons/dist/js/icons/bars-icon'
import ErrorIcon from '@patternfly/react-icons/dist/js/icons/error-circle-o-icon'

import './page.css'

import { routes } from '..'
import { selectUserRoles } from '../state/app'

const PageComponent = ({ keycloakClient }) => {
    const [currentRoute] = useRoute()
    const isNotificationDrawerOpen = useSelector(selectIsNotificationDrawerOpen)

    const drawerRef = React.createRef()
    const focusDrawer = () => {
        const firstTabbableItem = drawerRef.current.querySelector('a, button')
        firstTabbableItem.focus()
    }

    const roles = useSelector(selectUserRoles)

    const Header = (
        <Masthead>
            <MastheadToggle>
                <PageToggleButton variant="plain">
                    <BarsIcon />
                </PageToggleButton>
            </MastheadToggle>
            <MastheadMain>
                <MastheadBrand component="div">
                    <Link to="/">
                        <Brand src="/itbox365-brand.svg" alt="ITBox365 Logo" />
                    </Link>
                </MastheadBrand>
            </MastheadMain>
            <MastheadContent>
                <HeaderToolbar keycloakClient={keycloakClient} />
            </MastheadContent>
        </Masthead>
    )

    const pageId = 'main-content'

    return (
        <Page
            mainContainerId={pageId}
            header={Header}
            notificationDrawer={<AppNotificationDrawer drawerRef={drawerRef} />}
            onNotificationDrawerExpand={focusDrawer}
            isNotificationDrawerExpanded={isNotificationDrawerOpen}
            sidebar={<PageSidebar nav={<PageNav />} />}
            isManagedSidebar
            tertiaryNav={Array.isArray(currentRoute.tertiaryNav)
                ? <TertiaryNav navItems={currentRoute.tertiaryNav} />
                : currentRoute.tertiaryNav}
            isTertiaryNavGrouped
            breadcrumb={<PageBreadcrumb />}
            isBreadcrumbGrouped
            additionalGroupedContent={<PageSectionHeader />}
            groupProps={{ sticky: 'top' }}>
            <ErrorBoundary>
                <Routes>
                    {routes.map(({ path, element, requiresRole }) => {
                        if (requiresRole && requiresRole.filter(x => roles.includes(x)).length == 0) {
                            element = <EmptyStateForbidden allowedRoles={requiresRole} />
                        }
                        return <Route key={path} path={path} element={element} />
                    })}
                </Routes>
            </ErrorBoundary>
        </Page>
    )
}

export default PageComponent

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props)
        this.state = { hasError: false }
    }

    static getDerivedStateFromError(error) {
        return { hasError: true }
    }

    componentDidCatch(error, errorInfo) {
        //logErrorToMyService(error, errorInfo)
    }

    render() {
        if (this.state.hasError) {
            return (
                <EmptyState>
                    <EmptyStateIcon icon={ErrorIcon} />
                    <Title headingLevel="h4" size="lg">
                        Application error
                    </Title>
                    <EmptyStateBody>
                        This represents an the empty state pattern in Patternfly 4. Hopefully it's simple enough to use but flexible
                        enough to meet a variety of needs.
                    </EmptyStateBody>
                </EmptyState>)
        }
        return this.props.children
    }
}