//@ts-check
import React from 'react'

import { Card, CardBody, CardFooter, Checkbox, Dropdown, EmptyState, EmptyStateIcon, Gallery, KebabToggle, Split, SplitItem, Stack, StackItem, Text, TextContent, Title } from '@patternfly/react-core'

import CpuIcon from '@patternfly/react-icons/dist/js/icons/microchip-icon'
import RamIcon from '@patternfly/react-icons/dist/js/icons/memory-icon'
import HddIcon from '@patternfly/react-icons/dist/js/icons/hdd-icon'
import CircleIcon from '@patternfly/react-icons/dist/js/icons/circle-icon'

import PlusCircleIcon from '@patternfly/react-icons/dist/js/icons/plus-circle-icon'

import EmptyStateLoading from '../../EmptyState/loading'
import EmptyStateError from '../../EmptyState/error'
import CardCloudAppStageNode from '../cardCloudAppStageNode'

import { useReadByUidQuery } from '../../Api/cloudAppStageNode'

import useModalAddCloudAppStageNode from '../modalAddCloudAppStageNode'

const FieldNodes = ({ cloudProviderUid, uid, fieldName, value: nodes }) => (
    <Gallery hasGutter>
        {nodes.map((nodeUid) => (
            <CloudAppStageNode
                key={nodeUid}
                cloudAppStageUid={uid}
                nodeUid={nodeUid} />))}
        <AddCloudAppStageNode cloudProviderUid={cloudProviderUid} />
    </Gallery>
)

export default FieldNodes

const CloudAppStageNode = ({ cloudAppStageUid, nodeUid }) => {
    const { data: node, isLoading, isError } = useReadByUidQuery({ cloudAppStageUid, uid: nodeUid })

    return isLoading
        ? <EmptyStateLoading />
        : isError
            ? <Card
                isSelectableRaised
                isDisabledRaised>
                <CardBody>
                    <EmptyStateError />
                </CardBody>
            </Card>
            : <CardCloudAppStageNode
                node={node}
                isSelected={false}
                cardActions={<React.Fragment>
                    <Dropdown
                        onSelect={() => { }}
                        toggle={<KebabToggle onToggle={() => { }} />}
                        isOpen={false}
                        isPlain
                        dropdownItems={[]}
                        position={'right'} />
                    <Checkbox
                        isChecked={false}
                        onChange={() => { }}
                        aria-label="card checkbox example"
                        id="check-1"
                        name="check1" />
                </React.Fragment>}
                onChange={null}>
                <CardBody>
                    <Stack hasGutter>
                        <StackItem>
                            <TextContent>
                                <Text>{node.description}</Text>
                            </TextContent>
                        </StackItem>
                        <StackItem>
                            <Split hasGutter>
                                <SplitItem><CpuIcon /> 2</SplitItem>
                                <SplitItem><RamIcon /> 2GB</SplitItem>
                                <SplitItem><HddIcon /> 20 GB</SplitItem>
                            </Split>
                        </StackItem>
                    </Stack>
                </CardBody>
                <CardFooter>
                    <Split hasGutter>
                        <SplitItem><CircleIcon color="green" /></SplitItem>
                        <SplitItem>online</SplitItem>
                    </Split>
                </CardFooter>
            </CardCloudAppStageNode>
}

const AddCloudAppStageNode = ({ cloudProviderUid }) => {
    const { modal, openModal } = useModalAddCloudAppStageNode(cloudProviderUid)

    return (
        <React.Fragment>
            <Card
                isSelectableRaised
                onClick={openModal}>
                <CardBody>
                    <EmptyState>
                        <EmptyStateIcon icon={PlusCircleIcon} />
                        <Title headingLevel="h4" size="lg">
                            Add node
                        </Title>
                    </EmptyState>
                </CardBody>
            </Card>
            {modal}
        </React.Fragment>

    )
}