//@ts-check
import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { DescriptionList, DescriptionListDescription, DescriptionListGroup, DescriptionListTerm } from '@patternfly/react-core'

const EntityUpdate = ({ messageId, entityFields, updateHook, entity }) => {
    return (
        <DescriptionList
            isHorizontal
            horizontalTermWidthModifier={{
                sm: '100%',
                lg: '15vh',
            }}
            orientation={{
                sm: 'vertical',
                md: 'vertical',
                lg: 'horizontal',
                xl: 'horizontal',
                '2xl': 'horizontal'
            }}>
            {entityFields.map(({ fieldName, component, ...args }) => (
                <DescriptionListGroup key={fieldName}>
                    <DescriptionListTerm>
                        <FormattedMessage id={`field.${messageId}.${fieldName}.label`} />
                    </DescriptionListTerm>
                    <DescriptionListDescription>
                        <FieldWrapper
                            updateHook={updateHook}
                            component={component}
                            uid={entity.uid}
                            fieldName={fieldName}
                            value={entity[fieldName]}
                            {...args} />
                    </DescriptionListDescription>
                </DescriptionListGroup>))}
        </DescriptionList>
    )
}

export default EntityUpdate

const FieldWrapper = ({ updateHook, component, uid, fieldName, value, ...args }) => {
    const [nextValue, setNextValue] = useState(value)
    const [updateField, { isLoading: isPending }] = updateHook()

    const onSave = (value = undefined) => updateField({
        uid, fieldName,
        value: (value != undefined)
            ? value
            : nextValue
    })

    return React.createElement(component, {
        uid,
        fieldName,
        value,
        nextValue,
        setNextValue,
        onSave,
        isPending,
        ...args
    })
}
