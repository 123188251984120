import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { selectIsInactive, setAppUserActive } from "../state/app"

const useInactivityBlur = () => {
    const dispatch = useDispatch()
    const isInactive = useSelector(selectIsInactive)

    useEffect(() => {
        const body = document.getRootNode().body
        let intervalHandle = null
        if (isInactive) {
            let blury = 0
            intervalHandle = setInterval(() => {
                body.style.filter = `blur(${blury}px)`
                blury = blury + 1
                if (blury >= 8) {
                    clearInterval(intervalHandle)
                    intervalHandle = null
                }
            }, 1000)
            body.onmousemove = () => {
                if (intervalHandle != null) {
                    clearInterval(intervalHandle)
                }
                dispatch(setAppUserActive())
            }
        } else {
            body.style.filter = null
            body.onmousemove = null
        }
    }, [isInactive])
}

export default useInactivityBlur